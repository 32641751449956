import { type SxProps } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { useEffect, useState, useRef } from 'react'

import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import { Search, X } from 'lucide-react'

import { search } from '@/api/elasticsearch'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import type { ElasticsearchResponse } from '@/types'
import InputAdornment from '@mui/material/InputAdornment'

export interface SearchBarProps<T> {
  placeholder: string
  searchUrl: string
  onSearchResults?: (response: ElasticsearchResponse<T> | undefined) => void
  debounceMs?: number
  minimumSearchLength?: number
  page?: number
  perPage?: number
  requiredFields?: string[]
  sx?: SxProps
}
export default function SearchBar<T>({
  placeholder,
  searchUrl,
  onSearchResults,
  debounceMs = 300,
  minimumSearchLength = 3,
  page = 1,
  perPage = 10,
  requiredFields,
  sx,
}: SearchBarProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null)
  const { selectedOrganization } = useOrganization()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, debounceMs)

  const { data: searchResponse, isLoading: isSearching } = useQuery({
    queryKey: ['search', searchUrl, debouncedSearchValue, page, perPage],
    queryFn: () => {
      return search<T>(searchUrl, debouncedSearchValue, page, perPage, requiredFields, selectedOrganization?.id)
    },
    enabled: debouncedSearchValue.length >= minimumSearchLength,
    // staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
  })

  useEffect(() => {
    if (inputRef.current && inputRef.current.value.length < minimumSearchLength) {
      onSearchResults?.(undefined)
    } else if (searchResponse) {
      onSearchResults?.(searchResponse)
    }
  }, [searchResponse, onSearchResults, inputRef])

  const handleClearSearch = () => {
    inputRef.current?.focus()
    setSearchValue('')
  }

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value)
      }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search className="mr-1" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <>
                {isSearching && <CircularProgress size={16} />}
                {searchValue && (
                  <X className="ml-1 h-4 w-4 cursor-pointer hover:opacity-70" onClick={handleClearSearch} />
                )}
              </>
            </InputAdornment>
          ),
        },
      }}
      variant="outlined"
      size="small"
      sx={{ ...sx }}
    />
  )
}
