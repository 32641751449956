import { useMutation, useQueryClient } from '@tanstack/react-query'
import { uploadFile } from '@/api/gcs'
import { USER_QUERY_KEYS } from '@/lib/query-keys'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'

export function useUpdateProfilePicture() {
  const toast = useToastNotifications()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async ({ url, file }: { url: string; file: File }) => {
      return await uploadFile({ file, contentType: file.type, putUrl: url })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: USER_QUERY_KEYS.profilePicture,
      })
    },
    onError: () => {
      toast.addToastNotification({ message: 'Profile picture update failed', severity: 'error' })
    },
  })

  return mutation
}
