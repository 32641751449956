import Box from '@mui/material/Box'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import Typography from '@mui/material/Typography'
import { PencilIcon } from 'lucide-react'
import Button from '@mui/material/Button'
import { Fragment, useState } from 'react'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import { isPlainArray } from '@tanstack/react-router'
import MyProfileForm from '@/components/account-preferences/my-profile-form.tsx'
import Divider from '@mui/material/Divider'
import theme from '@/theme'
import { useProfilePicture } from '@/hooks/user-profile/useProfilePicture'
import { countryFormattedPhoneNumber } from '@/lib/utils'

const MyProfileTab = () => {
  const { userInfo } = useUserInfo()
  const { data: userProfilePicture } = useProfilePicture(userInfo?.user)
  const [isEditingMyProfile, setIsEditingMyProfile] = useState(false)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 4,
        }}
      >
        <Typography variant="tabHeader" sx={{ fontWeight: 700 }}>
          My Profile
        </Typography>
        {!isEditingMyProfile && (
          <Button
            variant="contained"
            color="inherit"
            onClick={() => setIsEditingMyProfile(true)}
            sx={{
              minWidth: '40px',
              width: '40px',
              height: '40px',
              padding: 0,
              boxShadow: 0,
              border: 1,
              borderColor: '#E0E0E0',
              backgroundColor: 'white',
              ':hover': {
                boxShadow: 0,
              },
            }}
          >
            <PencilIcon size={20} />
          </Button>
        )}
      </Box>
      <Divider sx={{ mb: 4 }} />
      {isEditingMyProfile && userInfo?.user ? (
        <MyProfileForm
          user={userInfo.user}
          profilePictureUrl={userProfilePicture?.url}
          setIsEditing={setIsEditingMyProfile}
        />
      ) : (
        <Grid container spacing={2}>
          {/* Left side: User information */}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                mb: 4,
                gap: 1,
              }}
            >
              {userInfo?.name && (
                <Typography variant="tabSectionXl" component="h1" sx={{ fontWeight: 700 }}>
                  {userInfo.name}
                </Typography>
              )}
              {userInfo?.user?.title && (
                <Typography variant="body1" sx={{ fontStyle: 'italic' }} gutterBottom>
                  {userInfo.user.title}
                </Typography>
              )}
              {userInfo?.user?.biography && (
                <Typography variant="body1" paragraph>
                  {userInfo.user.biography}
                </Typography>
              )}
            </Box>
            {/* Contact Information */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                mb: 2,
              }}
            >
              {userInfo?.user?.phone.length && isPlainArray(userInfo?.user.phone)
                ? userInfo.user.phone.map((phone, index) => (
                    <Fragment key={`phone-${index}`}>
                      <Typography variant="tabSection">Phone</Typography>
                      <Typography key={`phone-${index}`} variant="body1" gutterBottom>
                        {countryFormattedPhoneNumber(phone.number)}
                      </Typography>
                    </Fragment>
                  ))
                : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                mb: 4,
              }}
            >
              <Typography variant="tabSection">Email</Typography>
              <Typography key={`email-primary`} variant="body1" gutterBottom>
                {userInfo?.user?.email}
              </Typography>
              {userInfo?.user?.other_email.length && isPlainArray(userInfo?.user?.other_email)
                ? userInfo?.user?.other_email.map((email, index) => (
                    <Typography key={`email-${index}`} variant="body1" gutterBottom>
                      {email.address}
                    </Typography>
                  ))
                : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              {userInfo?.user?.address?.length &&
              isPlainArray(userInfo?.user?.address) &&
              userInfo?.user?.address[0]?.address
                ? userInfo?.user?.address.map((address, index) => (
                    <Box key={`address-${index}`}>
                      <Typography variant="tabSection" sx={{ fontWeight: 700, pb: 1 }}>
                        Address
                      </Typography>
                      <Typography key={`address-line1-${index}`} variant="body1" gutterBottom>
                        {address.address.street || 'n/a'}
                      </Typography>
                      <Typography key={`address-line2-${index}`} variant="body1" gutterBottom>
                        {[address?.address?.city, address?.address?.state, address?.address?.zip]
                          .filter(Boolean)
                          .join(', ')}
                      </Typography>
                    </Box>
                  ))
                : null}
            </Box>
          </Grid>
          {/* Right side: Profile Picture and actions */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <Avatar
                alt="PB"
                src={userProfilePicture?.url || ''}
                sx={{
                  width: 120,
                  height: 120,
                  mb: 2,
                  border: 1,
                  backgroundColor: '#F0F0F0',
                  borderRadius: theme.borderRadius.md,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default MyProfileTab
