import { useState, useRef, DragEvent, ChangeEvent } from 'react'
import { Box, Typography, Button, Tooltip, Alert, Stack } from '@mui/material'
import { ScrollText, Trash2 } from 'lucide-react'
import { DarkRoundIconButton } from './buttons/icon-buttons'
import CropImageModal from '@/components/ui/modals/crop-image-modal'
import { ACCEPTED_FILE_TYPES, ACCEPTED_FILE_EXTENSIONS } from '@/constants.ts'
import theme from '@/theme.ts'
import { ZodIssue } from 'zod'

interface FileUploaderProps {
  files: File[]
  onFilesAdded: (files: File[]) => void
  onFileDeleted: (file: File) => void
  onCropComplete: (croppedFile: File, index: number) => void
  validationError: ZodIssue | null
}

export default function FileUploader({
  files,
  onFilesAdded,
  onFileDeleted,
  onCropComplete,
  validationError,
}: FileUploaderProps) {
  const [isDragging, setIsDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [cropModalOpen, setCropModalOpen] = useState(false)
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(-1)

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(true)
  }

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)

    const droppedFiles = Array.from(e.dataTransfer.files)
    const allowedFiles = droppedFiles.filter((file) => ACCEPTED_FILE_TYPES.includes(file.type))

    if (allowedFiles.length > 0) {
      onFilesAdded(allowedFiles)
    }
  }

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files)
      onFilesAdded(selectedFiles)
    }
  }

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  const handleCropComplete = (croppedFile: File) => {
    // onFilesChange(
    //   files.map((file, index) =>
    //     index === selectedFileIndex ? croppedFile : file
    //   )
    // )
    onCropComplete(croppedFile, selectedFileIndex)
  }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        accept={ACCEPTED_FILE_TYPES.join(',')}
        onChange={handleFileSelect}
      />
      {files.length === 0 ? (
        <>
          <Box
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: '2px solid',
              borderColor: isDragging
                ? 'primary.main'
                : validationError?.path.includes('files')
                  ? 'error.light'
                  : 'grey.400',
              borderRadius: 2,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              cursor: 'pointer',
              bgcolor: isDragging ? 'action.hover' : 'grey.200',
              transition: 'all 0.2s ease',
              color: validationError?.path.includes('files')
                ? 'error.light'
                : isDragging
                  ? 'primary.main'
                  : 'text.primary',
            }}
            onClick={triggerFileInput}
          >
            <ScrollText size={48} strokeWidth={1} />
            <Typography variant="strongLg" mt={2} component="p">
              Upload File(s)
            </Typography>
            <Typography variant="body2">{ACCEPTED_FILE_EXTENSIONS.join(', ')}</Typography>
          </Box>
          {validationError?.path.includes('files') && validationError?.path.includes('manualEntry') && (
            <Alert severity="error" sx={{ mb: 2.5 }}>
              {validationError.message}
            </Alert>
          )}
        </>
      ) : (
        <>
          <Stack spacing={2} sx={{ maxHeight: '305px', overflowY: 'auto', scrollbarWidth: 'none' }}>
            {files.map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 2.5,
                  border: '1px solid',
                  borderColor: 'grey.400',
                  borderRadius: theme.borderRadius.md,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box>
                      {file.type.startsWith('image/') ? (
                        <Box
                          component="img"
                          src={URL.createObjectURL(file)}
                          alt={`Preview of ${file.name}`}
                          sx={{
                            width: '90px',
                            height: '87px',
                            objectFit: 'cover',
                            border: `1px solid ${theme.palette.black.main}`,
                          }}
                        />
                      ) : (
                        <ScrollText size={40} strokeWidth={1.5} color="black" />
                      )}
                    </Box>
                    <Box>
                      <Typography variant="strong" fontSize="1.25rem" component="p">
                        {file.name}
                      </Typography>
                      <Typography variant="body1">
                        {file.size >= 1000 * 1000
                          ? `${(file.size / (1000 * 1000)).toFixed(1)} MB`
                          : `${Math.ceil(file.size / 1000)} KB`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Tooltip arrow title={!file.type.startsWith('image/') ? 'Only image files can be cropped' : ''}>
                      <span>
                        <Button
                          variant="text"
                          color="black"
                          onClick={() => {
                            setSelectedFileIndex(index)
                            setCropModalOpen(true)
                          }}
                          disabled={!file.type.startsWith('image/')}
                        >
                          <Typography variant="button" sx={{ textDecoration: 'underline' }}>
                            Crop Image
                          </Typography>
                        </Button>
                      </span>
                    </Tooltip>
                    <DarkRoundIconButton onClick={() => onFileDeleted(file)}>
                      <Trash2 size={22} strokeWidth={2.5} />
                    </DarkRoundIconButton>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <Button variant="text" color="black" onClick={triggerFileInput}>
              <Typography variant="button" sx={{ textDecoration: 'underline' }}>
                Upload another file
              </Typography>
            </Button>
          </Box>
        </>
      )}
      {cropModalOpen && selectedFileIndex !== -1 && (
        <CropImageModal
          open={cropModalOpen}
          onClose={() => setCropModalOpen(false)}
          imageFile={files[selectedFileIndex]}
          onCropComplete={handleCropComplete}
        />
      )}
    </>
  )
}
