import { ImageFile, UserProfile } from '@/types'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher'
import { GCSFile } from '@/types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { USER_QUERY_KEYS } from '@/lib/query-keys'

export function useProfilePicture(userProfile?: UserProfile) {
  const queryClient = useQueryClient()
  const query = useQuery({
    queryKey: USER_QUERY_KEYS.profilePicture,
    enabled: !!userProfile && !!userProfile.profile_picture,
    queryFn: async () => {
      if (!userProfile?.profile_picture) {
        return null
      }
      return (await fetchGCSFile(userProfile.profile_picture as GCSFile)) as ImageFile
    },
    initialData: () => queryClient.getQueryData(USER_QUERY_KEYS.profilePicture) as ImageFile | null,
  })

  return query
}
