import { Fragment } from 'react'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import RequestPricingHeader from '@/components/rfps/rfp-request-pricing-header'
import theme from '@/theme'
import type { PlantListEntry, User } from '@/types'

interface GroupedSentRfps {
  [key: string]: {
    plants: PlantListEntry[]
    vendors: any[]
  }
}
function groupSentRfpsByPlants(sentRfps: any[]): GroupedSentRfps {
  const grouped: GroupedSentRfps = {}

  sentRfps?.forEach((sentRfp) => {
    const plantIds = sentRfp.plants
      .map((p: any) => p.id)
      .sort()
      .join('-')

    if (!grouped[plantIds]) {
      grouped[plantIds] = {
        plants: sentRfp.plants,
        vendors: [],
      }
    }

    grouped[plantIds].vendors.push(sentRfp)
  })

  return grouped
}

function getUserName(user: User) {
  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`
  }
  return user.email
}

export default function VendorGroups({
  sentRfps,
  selectedVendorId,
  onVendorClick,
}: {
  sentRfps: any[]
  selectedVendorId?: string
  onVendorClick: (sentRfp: any) => void
}) {
  return (
    <>
      {Object.entries(groupSentRfpsByPlants(sentRfps || [])).map(([plantGroupKey, group]) => (
        <Box key={plantGroupKey}>
          <RequestPricingHeader
            plants={group.plants}
            variant="vendor-panel"
            sx={{ fontSize: '0.875rem', color: '#708391' }}
          />
          <Divider sx={{ my: 2 }} />

          {group.vendors.map((sentRfp: any) => (
            <Fragment key={sentRfp.id}>
              <Stack
                sx={{
                  pl: 2,
                  py: 1,
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  bgcolor: selectedVendorId === sentRfp.id ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
                  borderRadius: theme.borderRadius.md,
                  border:
                    selectedVendorId === sentRfp.id ? `1px solid ${alpha(theme.palette.primary.main, 0.08)}` : 'none',
                  '&:hover': {
                    bgcolor:
                      selectedVendorId === sentRfp.id
                        ? alpha(theme.palette.primary.main, 0.12)
                        : alpha(theme.palette.action.hover, 0.04),
                  },
                }}
                onClick={() => onVendorClick(sentRfp)}
              >
                <Stack direction="row" spacing={1}>
                  <Typography
                    variant="strong"
                    sx={{
                      color: selectedVendorId === sentRfp.id ? 'primary.main' : 'inherit',
                    }}
                  >
                    {sentRfp.directed_organization_contact.organization_name}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  {sentRfp.directed_organization_user_contacts.map((user: any, index: number) => (
                    <Typography
                      variant="body2"
                      key={user.id}
                      sx={{
                        color: selectedVendorId === sentRfp.id ? 'primary.main' : 'text.secondary',
                      }}
                    >
                      {getUserName(user)}
                      {index < sentRfp.directed_organization_user_contacts.length - 1 && ','}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
              {group.vendors[group.vendors.length - 1] !== sentRfp && <Divider sx={{ my: 2 }} />}
            </Fragment>
          ))}
          <Divider sx={{ my: 2 }} />
        </Box>
      ))}
    </>
  )
}
