import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import RangeDimensionInput from './range-dimension-input.tsx'
import MeasurableRangeDimensionInput from './measurable-range-dimension-input.tsx'

import {
  AIProcessingData,
  GCSFile,
  type MeasurableRangeDimension,
  type MultiTrunk,
  type PlantListEntry,
  type RangeDimension,
  type SingleTrunk,
  TrunkPlurality,
} from '@/types.ts'
import CircularProgress from '@mui/material/CircularProgress'

type PlantListEntryFormProps = {
  entry: PlantListEntry
  onUpdate: (entry: PlantListEntry) => void
  optionsEnums: Record<string, any> | undefined
  id: string
  fileProcessingData?: AIProcessingData[]
  files?: GCSFile[]
}
export default function PlantListEntryForm({
  entry,
  onUpdate,
  optionsEnums,
  id,
  fileProcessingData,
  files,
}: PlantListEntryFormProps) {
  const handleChange = (field: keyof PlantListEntry, value: any) => {
    onUpdate({ ...entry, [field]: value })
  }

  const handleTrunkPluralityChange = (value: string) => {
    if (value === TrunkPlurality.SingleTrunk) {
      onUpdate({
        ...entry,
        trunk_form: {
          plurality: TrunkPlurality.SingleTrunk,
          trunk_count: { min: 1, max: 1 },
          clear_trunk_size: { min: 1, max: 1, unit: null },
        } as SingleTrunk,
      })
    } else {
      onUpdate({
        ...entry,
        trunk_form: {
          plurality: TrunkPlurality.MultiTrunk,
          trunk_count: { min: 1, max: 1 },
          cane_caliper: { min: 1, max: 1, unit: null },
          clear_trunk_size: { min: 1, max: 1, unit: null },
        } as MultiTrunk,
      })
    }
  }

  const handleTrunkCountChange = (value: RangeDimension) => {
    if (!entry.trunk_form || entry.trunk_form.plurality === TrunkPlurality.SingleTrunk) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        trunk_count: value,
      },
    } as PlantListEntry)
  }

  const handleCaneCaliperChange = (value: MeasurableRangeDimension) => {
    if (!entry.trunk_form || entry.trunk_form.plurality === TrunkPlurality.SingleTrunk) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        cane_caliper: value,
      },
    } as PlantListEntry)
  }

  const handleClearTrunkSizeChange = (value: MeasurableRangeDimension) => {
    if (!entry.trunk_form) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        clear_trunk_size: value,
      },
    } as PlantListEntry)
  }

  return (
    <Box display="flex" flexDirection="column" gap={2} id={id}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Names</Typography>
        <Box display="flex" gap={4}>
          <TextField
            label="Common Name"
            value={entry.common_name || ''}
            onChange={(e) => handleChange('common_name', e.target.value)}
          />
          <TextField
            label="Scientific Name"
            value={entry.scientific_name || ''}
            onChange={(e) => handleChange('scientific_name', e.target.value)}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Quantity</Typography>
        <Box display="flex" gap={4}>
          <RangeDimensionInput
            dimension={entry.quantity_count}
            label="Count"
            onChange={(value) => handleChange('quantity_count', value)}
          />
          <MeasurableRangeDimensionInput
            dimension={entry.surface_area}
            label={'Surface Area'}
            unitOptions={optionsEnums?.imperialareameasurement || []}
            onChange={(value) => handleChange('surface_area', value)}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Size</Typography>
        <Box display="flex" gap={4}>
          <MeasurableRangeDimensionInput
            dimension={entry.container}
            label="Container"
            unitOptions={optionsEnums?.imperialvolumemeasurement || []}
            onChange={(value) => handleChange('container', value)}
          />
          <MeasurableRangeDimensionInput
            dimension={entry.caliper}
            label="Caliper"
            unitOptions={optionsEnums?.imperiallengthmeasurement || []}
            onChange={(value) => handleChange('caliper', value)}
          />
        </Box>

        <Box display="flex" gap={4}>
          <MeasurableRangeDimensionInput
            dimension={entry.height}
            label="Height"
            unitOptions={optionsEnums?.imperiallengthmeasurement || []}
            onChange={(value) => handleChange('height', value)}
          />
          <MeasurableRangeDimensionInput
            dimension={entry.width}
            label="Width"
            unitOptions={optionsEnums?.imperiallengthmeasurement || []}
            onChange={(value) => handleChange('width', value)}
          />
        </Box>
        <MeasurableRangeDimensionInput
          dimension={entry.dbh}
          label="DBH"
          unitOptions={optionsEnums?.imperiallengthmeasurement || []}
          onChange={(value) => handleChange('dbh', value)}
        />
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Trunk</Typography>
        <Box display="flex" gap={4}>
          <Box>
            <FormControl>
              <InputLabel id="trunk-form">Trunk Form</InputLabel>
              <Select
                labelId="trunk-form"
                label="Trunk Form"
                value={entry.trunk_form?.plurality || ''}
                onChange={(e) => handleTrunkPluralityChange(e.target.value as string)}
                sx={{ minWidth: 150 }}
              >
                {optionsEnums?.trunkplurality.map((trunkForm: string) => (
                  <MenuItem key={trunkForm} value={trunkForm}>
                    {trunkForm}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {entry.trunk_form?.plurality === TrunkPlurality.MultiTrunk && (
          <Box display="flex" gap={4}>
            <RangeDimensionInput
              dimension={entry.trunk_form.trunk_count}
              label="Trunk Count"
              onChange={handleTrunkCountChange}
            />
            <MeasurableRangeDimensionInput
              dimension={entry.trunk_form?.cane_caliper}
              label="Cane Caliper"
              unitOptions={optionsEnums?.imperiallengthmeasurement || []}
              onChange={handleCaneCaliperChange}
            />
          </Box>
        )}
        {(entry.trunk_form?.plurality === TrunkPlurality.SingleTrunk ||
          entry.trunk_form?.plurality === TrunkPlurality.MultiTrunk) && (
          <MeasurableRangeDimensionInput
            dimension={entry.trunk_form?.clear_trunk_size}
            label="Clear Trunk Size"
            unitOptions={optionsEnums?.imperiallengthmeasurement || []}
            onChange={handleClearTrunkSizeChange}
          />
        )}
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Tags</Typography>
        <Box display="flex" gap={4}>
          <FormControl fullWidth>
            <InputLabel id="plant-stage">Plant Stage</InputLabel>
            <Select
              labelId="plant-stage"
              label="Plant Stage"
              multiple
              value={entry.plant_stage}
              onChange={(e) => handleChange('plant_stage', e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {optionsEnums?.plantstage.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="palm-trunk">Palm Trunk</InputLabel>
            <Select
              labelId="palm-trunk"
              label="Palm Trunk"
              multiple
              value={entry.palm_trunk}
              onChange={(e) => handleChange('palm_trunk', e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {optionsEnums?.palmtrunk.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="characteristics">Characteristics</InputLabel>
            <Select
              labelId="characteristics"
              label="Characteristics"
              multiple
              value={entry.characteristics}
              onChange={(e) => handleChange('characteristics', e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {optionsEnums?.characteristics.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="shape">Shape</InputLabel>
            <Select
              labelId="shape"
              label="Shape"
              multiple
              value={entry.shape}
              onChange={(e) => handleChange('shape', e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {optionsEnums?.shape.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Packaging</Typography>
        <Box display="flex" gap={4} alignItems="end">
          <RangeDimensionInput
            dimension={entry.package_count}
            label="Package Count"
            onChange={(value) => handleChange('package_count', value)}
          />

          <FormControl>
            <InputLabel id="shipping-container">Shipping Container</InputLabel>
            <Select
              labelId="shipping-container"
              label="Shipping Container"
              value={entry.shipping_container || ''}
              onChange={(e) => handleChange('shipping_container', e.target.value)}
              sx={{ minWidth: 200 }}
            >
              {optionsEnums?.shippingcontainer.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="plant-container">Plant Container</InputLabel>
            <Select
              labelId="plant-container"
              label="Plant Container"
              value={entry.plant_container || ''}
              onChange={(e) => handleChange('plant_container', e.target.value)}
              sx={{ minWidth: 200 }}
            >
              {optionsEnums?.plantcontainer.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" gap={4}>
          <FormControl>
            <InputLabel id="root-packaging">Root Packaging</InputLabel>
            <Select
              labelId="root-packaging"
              label="Root Packaging"
              value={entry.root_packaging || ''}
              onChange={(e) => handleChange('root_packaging', e.target.value)}
              sx={{ minWidth: 310 }}
            >
              {optionsEnums?.rootpackaging.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6">Other</Typography>
        <FormControl fullWidth>
          <TextField
            label="Notes"
            multiline
            rows={4}
            value={entry.notes || ''}
            onChange={(e) => handleChange('notes', e.target.value)}
          />
        </FormControl>
        {files && fileProcessingData && (
          <FormControl fullWidth>
            <InputLabel id="file">File</InputLabel>
            <Select
              labelId="file"
              label="File"
              value={entry.file_id || 'unassigned'}
              onChange={(e) => handleChange('file_id', e.target.value || null)}
            >
              {files
                .filter((file) => !file.deleted_at)
                .map((file) => {
                  const processing = fileProcessingData.find((data) => data.file_id === file.id)!
                  return (
                    <MenuItem key={file.id} value={file.id} disabled={!processing.processing_completed}>
                      {!processing.processing_completed && (
                        <CircularProgress size={16} color="inherit" className="mr-2" />
                      )}
                      {file.user_file_name}
                    </MenuItem>
                  )
                })}
              <MenuItem key="unassigned" value="">
                Unassigned
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
    </Box>
  )
}
