import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const TextField = styled(MuiTextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.borderRadius.md,
  },
}))

export default TextField
