import {
  closestCenter,
  DndContext,
  DragOverEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { styled } from '@mui/material/styles'
import { useMutation, useQuery } from '@tanstack/react-query'
import { usePubNub } from '@/contexts/PubNubContext.tsx'
import { Fragment, useMemo, useState, useEffect, useRef, useCallback } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import PlantListDetailTableItem from './plant-list-table-item.tsx'
import PlaceholderDragDropRow from './plant-list-table-item-dummy.tsx'

import { getOptions, getPlantListById, updatePlantList } from '@/api/plant-list.ts'
import type { AIProcessingData, PlantList, PlantListEntry } from '@/types.ts'
import Typography from '@mui/material/Typography'
import { CircularProgress, Stack } from '@mui/material'
import { EyeIcon } from 'lucide-react'
import { getFileProcessingForPlantLists } from '@/api/ai-processed-plant-list-file.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import EditPlantListEntryModal from '@/components/ui/modals/edit-plant-list-entry-modal.tsx'

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  scrollbarWidth: 'none', // For Firefox
  '&::-webkit-scrollbar': {
    display: 'none', // For Chrome, Safari, and Edge
  },
  '& .MuiTableFooter-root': {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
  },
}))

interface FileDataType {
  [key: string]: {
    user_file_name: string
  }
}

interface PlantListProps {
  plantList: PlantList
  selectedFiles: string[]
  searchFilteredPlants: Set<string>
  selectedPlants: Set<string>
  setSelectedPlants: (selectedPlants: Set<string>) => void
  setTargetFileId: (fileId: string) => void
  onFileProcessed?: () => void
}
export default function PlantListDetailTable({
  plantList,
  selectedFiles,
  searchFilteredPlants,
  selectedPlants,
  setSelectedPlants,
  setTargetFileId,
  onFileProcessed,
}: PlantListProps) {
  const { selectedOrganization } = useOrganization()
  const { subscribeToChannels, unsubscribeFromChannels } = usePubNub()
  const [localPlants, setLocalPlants] = useState<PlantListEntry[]>(plantList.entries)
  const [fileData] = useState<FileDataType>(
    plantList.files.reduce((acc: FileDataType, file) => {
      acc[file.id] = file
      return acc
    }, {} as FileDataType)
  )
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editPlant, setEditPlant] = useState<PlantListEntry | null>(null)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const { data: fileProcessingData, refetch: refetchFileProcessingData } = useQuery({
    queryKey: ['file-processing-data', plantList.id],
    queryFn: () => {
      if (!selectedOrganization?.id || !plantList.id) {
        return []
      }
      return getFileProcessingForPlantLists(selectedOrganization.id, [plantList.id])
    },
  })

  const tableRef = useRef<HTMLTableElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { data: optionsEnums } = useQuery({
    queryKey: ['optionEnums'],
    queryFn: getOptions,
  })

  useEffect(() => {
    setLocalPlants(plantList.entries)
    setHasUnsavedChanges(false)
  }, [plantList])

  useEffect(() => {
    const channels = fileProcessingData?.map((data: AIProcessingData) => data.pubsub_channel)

    if (!channels || channels.length === 0) {
      return
    }

    const listener = async (messageEvent: any) => {
      const { channel_id, model } = messageEvent.message
      if (!fileProcessingData) {
        return
      }

      // find the file that matches the channel_id
      const fileIndex = fileProcessingData.findIndex((data) => data.pubsub_channel === channel_id)
      if (fileIndex === -1) {
        return
      }

      if (model.processing_completed) {
        onFileProcessed?.()
        const plantListData = await getPlantListById(plantList.id)
        if (!plantListData || !plantListData.entries.length) {
          return
        }

        // get any entries from current plant list that are new
        const newEntries = localPlants.filter((entry) => entry.is_new)

        if (newEntries.length > 0) {
          plantListData.entries[plantListData.entries.length - 1].parent_of_order = newEntries[0].id
          // update the order of the new entries
          newEntries.forEach((entry, index) => {
            entry.parent_of_order = index === newEntries.length - 1 ? null : newEntries[index + 1].id
          })
        }
        // add the new entries to the plant list
        const updatedEntries = [...plantListData.entries, ...newEntries]

        refetchFileProcessingData()
        setLocalPlants(updatedEntries)
      }
    }

    subscribeToChannels(channels, 'plantList', listener)

    return () => {
      unsubscribeFromChannels(channels, 'plantList')
    }
  }, [fileProcessingData, plantList.id, plantList.entries, localPlants])

  const deletedFileIds = useMemo(() => {
    return new Set(plantList.files.filter((file) => file.deleted_at).map((file) => file.id))
  }, [plantList.files])

  const [filteredPlantLength, setFilteredPlantLength] = useState(0)
  const filteredPlants = useMemo(() => {
    const updatedLocalPlants = localPlants.reduce(
      (acc: Record<string, PlantListEntry[]>, plant: PlantListEntry) => {
        const plantFileId = plant.file_id || 'unassociated'
        if (!acc[plantFileId] && Array.from(selectedFiles).includes(plantFileId)) {
          acc[plantFileId] = []
        }
        if (
          plant.deleted_at === null &&
          Array.from(selectedFiles).includes(plantFileId) &&
          (searchFilteredPlants.has(plant.id) || searchFilteredPlants.has('all'))
        ) {
          acc[plantFileId].push(plant)
        }
        return acc
      },
      {} as Record<string, PlantListEntry[]>
    )

    setFilteredPlantLength(Object.values(updatedLocalPlants).flat().length)

    // add missing files
    selectedFiles.forEach((fileId) => {
      if (!updatedLocalPlants[fileId]) {
        updatedLocalPlants[fileId] = []
      }
    })

    return updatedLocalPlants
  }, [deletedFileIds, localPlants, selectedFiles, searchFilteredPlants])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = (event: DragOverEvent) => {
    const { active, over } = event
    if (!over || active.id === over?.id) {
      return
    }

    const getFileId = (id: UniqueIdentifier) => {
      const stringId = String(id)
      const match = stringId.match(/^container-(.+)$/)
      return match ? match[1] : null
    }
    const activeFileId = getFileId(active.data.current?.sortable.containerId)
    const overFileId = getFileId(over.data.current?.sortable.containerId)

    setLocalPlants((plants) => {
      const activeIndex = plants.findIndex((plant) => plant.id === active.id)
      const overIndex = plants.findIndex((plant) => plant.id === over.id)

      if (overFileId && overFileId !== activeFileId) {
        plants[activeIndex].file_id = overFileId === 'unassociated' ? null : overFileId
      }
      const newPlants = arrayMove(plants, activeIndex, overIndex)

      // Update parent_of_order references
      return newPlants.map((plant, index) => {
        // Last item in the list
        if (index === newPlants.length - 1) {
          return { ...plant, parent_of_order: null }
        }
        // Link to the next item
        return { ...plant, parent_of_order: newPlants[index + 1].id }
      })
    })
    setHasUnsavedChanges(true)
  }

  const toggleSelect = (plantId: string) => {
    const newSelectedPlants = new Set(selectedPlants)
    if (newSelectedPlants.has(plantId)) {
      newSelectedPlants.delete(plantId)
    } else {
      newSelectedPlants.add(plantId)
    }
    setSelectedPlants(newSelectedPlants)
  }

  const handleEntryUpdate = (updatedPlant: PlantListEntry) => {
    setLocalPlants((plants) => plants.map((plant) => (plant.id === updatedPlant.id ? updatedPlant : plant)))
    setHasUnsavedChanges(true)
  }

  const updatePlantListMutation = useMutation({
    mutationFn: updatePlantList,
    onSuccess: async () => {
      setHasUnsavedChanges(false)
    },
  })

  const handleUpdatePlantListEntries = async (entries: PlantListEntry[]) => {
    if (!plantList || !entries) {
      return
    }
    await updatePlantListMutation.mutateAsync({
      ...plantList,
      entries,
    })
  }

  const debouncedSavePlantList = useCallback(
    async (plants: PlantListEntry[]) => {
      try {
        if (hasUnsavedChanges) {
          await handleUpdatePlantListEntries(plants)
        }
      } catch (error) {
        console.error('Error saving changes:', error)
      }
    },
    [hasUnsavedChanges, handleUpdatePlantListEntries]
  )

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      debouncedSavePlantList(localPlants)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [localPlants, debouncedSavePlantList])

  const getAllPlantIds = (data: Record<string, any>): Set<string> => {
    const plantIds = new Set<string>()

    // Iterate through the keys of the object
    Object.values(data).forEach((plants) => {
      plants.forEach((plant: PlantListEntry) => {
        if (plant.deleted_at === null && plant.id) {
          plantIds.add(plant.id)
        }
      })
    })

    return plantIds
  }

  const handleEditEntry = (entry: PlantListEntry) => {
    // console.log('Edit Entry:', entry)
    setEditPlant(entry)
    setEditModalOpen(true)
  }

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragOver={handleDragEnd}>
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            borderBottom: 1,
            borderColor: 'grey.300',
            borderRadius: 0,
            boxShadow: 'none',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <StyledTableContainer ref={tableContainerRef}>
            <Table stickyHeader ref={tableRef}>
              <TableHead
                sx={{
                  '& .MuiTableCell-head': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(4px)',
                    boxShadow: 'none !important',
                  },
                }}
              >
                <TableRow>
                  <TableCell padding="none" sx={{ boxShadow: 'none !important', width: '48px' }} />
                  <TableCell padding="checkbox" sx={{ boxShadow: 'none !important' }}>
                    <Checkbox
                      indeterminate={selectedPlants.size > 0 && selectedPlants.size < filteredPlantLength}
                      checked={selectedPlants.size === filteredPlantLength && filteredPlantLength > 0}
                      onChange={() => {
                        if (selectedPlants.size === filteredPlantLength) {
                          setSelectedPlants(new Set())
                        } else {
                          setSelectedPlants(new Set(getAllPlantIds(filteredPlants)))
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ boxShadow: 'none !important' }}>
                    PLANT NAME
                  </TableCell>
                  <TableCell align="center" sx={{ boxShadow: 'none !important' }}>
                    QTY
                  </TableCell>
                  <TableCell align="left" sx={{ boxShadow: 'none !important' }}>
                    SPECS
                  </TableCell>
                  <TableCell align="left" sx={{ boxShadow: 'none !important' }}></TableCell>
                  <TableCell padding="none" sx={{ boxShadow: 'none !important', width: '48px' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(filteredPlants).map(([file, plantEntries]: [string, PlantListEntry[]]) => (
                  <Fragment key={file}>
                    <TableRow data-key={file} data-id="entries-parent">
                      <TableCell colSpan={7}>
                        <Stack direction="row" justifyContent="space-between" sx={{ paddingTop: 4 }}>
                          <Stack direction="row" display="flex" gap={0.6}>
                            <Typography fontWeight="bold" variant="body1">
                              {`${plantEntries.length} plants`}
                            </Typography>
                            <Typography fontWeight="normal" variant="body1">
                              {file === 'unassociated'
                                ? ` not associated with a file`
                                : ` extracted from ${fileData[file].user_file_name}`}
                            </Typography>
                          </Stack>
                          {file !== 'unassociated' && (
                            <Stack direction="row" alignItems="center" gap={1}>
                              {fileProcessingData?.some(
                                (data) => data.file_id === file && !data.processing_completed
                              ) && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                  }}
                                >
                                  <CircularProgress size={12} />
                                  <Typography variant="body2" color="text.primary">
                                    Processing...
                                  </Typography>
                                </Box>
                              )}
                              <Button
                                variant="text"
                                size="small"
                                startIcon={<EyeIcon size={16} />}
                                onClick={() => setTargetFileId(file)}
                                sx={{
                                  '&:hover': { backgroundColor: 'grey.100' },
                                }}
                              >
                                View File
                              </Button>
                            </Stack>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                    <SortableContext
                      id={`container-${file}`}
                      items={Object.values(filteredPlants)
                        .flat()
                        .map((plant: PlantListEntry) => plant.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {plantEntries.length === 0 && <PlaceholderDragDropRow file={file} />}
                      {plantEntries.map((plant: PlantListEntry, index: number) => (
                        <Fragment key={plant.id}>
                          <PlantListDetailTableItem
                            plant={plant}
                            isSelected={selectedPlants.has(plant.id)}
                            onSelect={() => toggleSelect(plant.id)}
                            onUpdate={handleEntryUpdate}
                            onEditEntry={handleEditEntry}
                            optionsEnums={optionsEnums}
                            testId={`plant-row-${index}`}
                          />
                        </Fragment>
                      ))}
                    </SortableContext>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Paper>
      </DndContext>
      {editPlant && (
        <EditPlantListEntryModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          entry={editPlant}
          onUpdate={handleEntryUpdate}
          onSwitchToPlant={(entry) => {
            setEditPlant(entry)
          }}
          optionsEnums={optionsEnums}
          plantListEntries={Object.entries(filteredPlants)
            .map(([_, plantEntries]: [string, PlantListEntry[]]) => plantEntries)
            .flat()}
          modalPosition={{ top: '50%', left: '0', transformX: '10%', transformY: '-50%' }}
          hideBackdrop={true}
        />
      )}
    </>
  )
}
