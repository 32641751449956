import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'

import ContactsSidebarItem from '@/components/contacts/contacts-sidebar-item.tsx'

import { getContactCounts } from '@/api/contacts.ts'
import { ContactCategories, ContactTypes } from '@/constants.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'

type ContactsSidebarProps = {
  onCategoryChange: (category: ContactCategories) => void
  onContactTypeChange: (type: ContactTypes) => void
}
export default function ContactsSidebar({ onCategoryChange, onContactTypeChange }: ContactsSidebarProps) {
  const { selectedOrganization } = useOrganization()
  const [category, setCategory] = useState('all')
  const [contactType, setContactType] = useState<ContactTypes>(ContactTypes.USER)

  const { data: countsData, isLoading } = useQuery({
    queryKey: ['contacts', 'counts', { organizationId: selectedOrganization?.id }],
    queryFn: () => getContactCounts(selectedOrganization?.id),
    enabled: !!selectedOrganization?.id,
  })

  const handleCategoryChange = (category: ContactCategories) => {
    setCategory(category)
    setContactType(ContactTypes.USER)
    onCategoryChange(category)
    onContactTypeChange(ContactTypes.USER)
  }

  const handleContactTypeChange = (type: ContactTypes) => {
    setContactType(type)
    onContactTypeChange(type)
  }

  if (isLoading) {
    return (
      <List>
        <ListItem>
          <ListItemText
            primary="All Contacts"
            primaryTypographyProps={{
              sx: { fontWeight: 'light' },
            }}
          />
          <Skeleton width={15} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Favorites"
            primaryTypographyProps={{
              sx: { fontWeight: 'light' },
            }}
          />
          <Skeleton width={15} />
        </ListItem>
        {/*<Divider />*/}
        {/*<ListItem>*/}
        {/*  <ListItemText*/}
        {/*    primary="Customers"*/}
        {/*    primaryTypographyProps={{*/}
        {/*      sx: { fontWeight: 'light' },*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <Skeleton width={15} />*/}
        {/*</ListItem>*/}
        {/*<ListItem>*/}
        {/*  <ListItemText*/}
        {/*    primary="Vendors"*/}
        {/*    primaryTypographyProps={{*/}
        {/*      sx: { fontWeight: 'light' },*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <Skeleton width={15} />*/}
        {/*</ListItem>*/}
        {/*<ListItem>*/}
        {/*  <ListItemText*/}
        {/*    primary="My Team"*/}
        {/*    primaryTypographyProps={{*/}
        {/*      sx: { fontWeight: 'light' },*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <Skeleton width={15} />*/}
        {/*</ListItem>*/}
      </List>
    )
  }

  return (
    <List>
      <ContactsSidebarItem
        label="All Contacts"
        totalCount={countsData?.[0]?.all.total || 0}
        peopleCount={countsData?.[0]?.all.people || 0}
        organizationCount={countsData?.[0]?.all.organizations || 0}
        selected={category === 'all'}
        contactType={contactType}
        onChangeCategory={() => handleCategoryChange('all')}
        onChangeContactType={(type: ContactTypes) => handleContactTypeChange(type)}
      />
      <ContactsSidebarItem
        label="Favorites"
        totalCount={countsData?.[0]?.favorite.total || 0}
        peopleCount={countsData?.[0]?.favorite.people || 0}
        organizationCount={countsData?.[0]?.favorite.organizations || 0}
        selected={category === 'favorite'}
        contactType={contactType}
        onChangeCategory={() => handleCategoryChange('favorite')}
        onChangeContactType={(type: ContactTypes) => handleContactTypeChange(type)}
      />
      {/*<Divider sx={{ my: 2 }} />*/}
      {/*<ContactsSidebarItem*/}
      {/*  label="Customers"*/}
      {/*  totalCount={countsData?.[0]?.customers.total || 0}*/}
      {/*  peopleCount={countsData?.[0]?.customers.people || 0}*/}
      {/*  organizationCount={countsData?.[0]?.customers.organizations || 0}*/}
      {/*  selected={category === 'customers'}*/}
      {/*  contactType={contactType}*/}
      {/*  onChangeCategory={() => handleCategoryChange('customers')}*/}
      {/*  onChangeContactType={(type: ContactTypes) =>*/}
      {/*    handleContactTypeChange(type)*/}
      {/*  }*/}
      {/*/>*/}
      {/*<ContactsSidebarItem*/}
      {/*  label="Vendors"*/}
      {/*  totalCount={countsData?.[0]?.vendors.total || 0}*/}
      {/*  peopleCount={countsData?.[0]?.vendors.people || 0}*/}
      {/*  organizationCount={countsData?.[0]?.vendors.organizations || 0}*/}
      {/*  selected={category === 'vendors'}*/}
      {/*  contactType={contactType}*/}
      {/*  onChangeCategory={() => handleCategoryChange('vendors')}*/}
      {/*  onChangeContactType={(type: ContactTypes) =>*/}
      {/*    handleContactTypeChange(type)*/}
      {/*  }*/}
      {/*/>*/}
      {/*<ContactsSidebarItem*/}
      {/*  label="My Team"*/}
      {/*  totalCount={countsData?.[0]?.my_team.total || 0}*/}
      {/*  peopleCount={countsData?.[0]?.my_team.people || 0}*/}
      {/*  organizationCount={countsData?.[0]?.my_team.organizations || 0}*/}
      {/*  selected={category === 'my_team'}*/}
      {/*  contactType={contactType}*/}
      {/*  onChangeCategory={() => handleCategoryChange('my_team')}*/}
      {/*  onChangeContactType={(type: ContactTypes) =>*/}
      {/*    handleContactTypeChange(type)*/}
      {/*  }*/}
      {/*/>*/}
    </List>
  )
}
