import { PlantList } from '@/types.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { archivePlantList, unArchivePlantList } from '@/api/plant-list.ts'
import { Box } from '@mui/material'
import { TextPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import CircularProgress from '@mui/material/CircularProgress'
import { Archive } from 'lucide-react'

type PlantListDetailContentProps = { plantList: PlantList }
export default function ArchivePlantList({ plantList }: PlantListDetailContentProps) {
  const plantListId = plantList.id
  const queryClient = useQueryClient()
  const { addToastNotification } = useToastNotifications()

  const invalidatePlantListQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['plant-list', plantListId],
    })
  }

  const archivePlantListMutation = useMutation({
    mutationFn: archivePlantList,
    onSuccess: async () => {
      sendArchivePlantListSuccessNotification()
      await invalidatePlantListQuery()
    },
    onError: (error) => {
      sendArchivePlantListErrorNotification(error)
    },
  })

  const sendArchivePlantListSuccessNotification = () => {
    addToastNotification({
      severity: 'success',
      title: 'Success: Plant List Archived',
      message: 'Plant list has been successfully archived',
    })
  }

  const sendArchivePlantListErrorNotification = (error: any) => {
    addToastNotification({
      severity: 'error',
      title: 'Error: Archive Plant List',
      message: error?.message || 'An error occurred while archiving the plant list',
    })
  }

  const sendUnArchivePlantListSuccessNotification = () => {
    addToastNotification({
      severity: 'success',
      title: 'Success: Plant List Un-archived',
      message: 'Plant list has been successfully unarchived',
    })
  }

  const sendUnArchivePlantListErrorNotification = (error: any) => {
    addToastNotification({
      severity: 'error',
      title: 'Error: Un-archive Plant List',
      message: error?.message || 'An error occurred while un-archiving the plant list',
    })
  }

  const unArchivePlantListMutation = useMutation({
    mutationFn: unArchivePlantList,
    onSuccess: async () => {
      sendUnArchivePlantListSuccessNotification()
      await invalidatePlantListQuery()
    },
    onError: (error) => {
      sendUnArchivePlantListErrorNotification(error)
    },
  })

  const triggerArchivePlantList = async () => {
    plantList.deleted_at
      ? await unArchivePlantListMutation.mutateAsync(plantListId)
      : await archivePlantListMutation.mutateAsync(plantListId)
  }

  return (
    <Box display="flex">
      <TextPrimaryButton
        variant="outlined"
        color="primary"
        onClick={triggerArchivePlantList}
        startIcon={archivePlantListMutation.isPending ? <CircularProgress size={16} /> : <Archive size={16} />}
      >
        {plantList.deleted_at ? 'Un-archive List' : 'Archive List'}
      </TextPrimaryButton>
    </Box>
  )
}
