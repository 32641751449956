import { useState } from 'react'
import { z, ZodError, ZodIssue } from 'zod'
import { useMutation } from '@tanstack/react-query'
import { createPlantList, PlantListRequest } from '@/api/plants.ts'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { Checkbox, FormControlLabel } from '@mui/material'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons'
import theme from '@/theme.ts'
import Alert from '@mui/material/Alert'
import FileUploader from '@/components/ui/base/file-uploader'
import ModalHeader from '@/components/ui/base/modal-header.tsx'

const createPlantListFormSchema = z
  .object({
    name: z.string().min(1, { message: 'A plant list name is required' }),
    files: z.array(z.instanceof(File)),
    manualEntry: z.boolean(),
    fileRequired: z.boolean().optional(),
  })
  .superRefine((data, ctx) => {
    // When fileRequired is true, files must be present
    if (data.fileRequired && data.files.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please upload a valid file',
        path: ['files'],
      })
    }

    // When fileRequired is false, either files or manualEntry must be selected
    if (!data.fileRequired && data.files.length === 0 && !data.manualEntry) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Either upload files or select manual entry',
        path: ['files', 'manualEntry'],
      })
    }
  })

interface AddPlantListModalProps {
  open: boolean
  onClose: () => void
  onPlantListCreated: (newPlantListId: string) => void
  fileRequired?: boolean
}
export default function AddPlantListModal({
  open,
  onClose,
  onPlantListCreated,
  fileRequired = false,
}: AddPlantListModalProps) {
  const { selectedOrganization } = useOrganization()
  const [name, setName] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [manualEntry, setManualEntry] = useState(false)
  const [validationError, setValidationError] = useState<ZodIssue | null>(null)

  const handleFilesAdded = (files: File[]) => {
    name || setName(files[0].name)
    setFiles((prev) => [...prev, ...files])
    setManualEntry(false)
    setValidationError(null)
  }

  const handleFileDeleted = (file: File) => {
    setFiles((prev) => prev.filter((f) => f !== file))
  }

  const createPlantListMutation = useMutation({
    mutationFn: createPlantList,
    onSuccess: async (newPlantList) => {
      setName('')
      setFiles([])
      setValidationError(null)
      onClose()
      onPlantListCreated(newPlantList.id)
    },
  })

  const handleSubmit = async () => {
    if (!selectedOrganization) {
      return
    }

    try {
      const trimmedName = name.trim()
      const validatedData = createPlantListFormSchema.parse({
        name: trimmedName,
        files,
        manualEntry,
        fileRequired: fileRequired ?? false,
      })
      const plantListRequest: PlantListRequest = {
        name: validatedData.name,
        organization: selectedOrganization.id,
        files: validatedData.files,
      }
      await createPlantListMutation.mutateAsync(plantListRequest)
    } catch (error) {
      if (error instanceof ZodError) {
        setValidationError(error.errors[0])
      }
    }
  }

  const handleClose = () => {
    setName('')
    setFiles([])
    setManualEntry(false)
    setValidationError(null)
    onClose()
  }

  const handleCropComplete = (croppedFile: File, selectedFileIndex: number) => {
    setFiles((prev) => prev.map((file, index) => (index === selectedFileIndex ? croppedFile : file)))
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="add-plant-list-modal-title">
      <>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 850,
            bgcolor: 'white',
            boxShadow: 24,
            p: 5,
            borderRadius: theme.borderRadius.md,
          }}
        >
          <ModalHeader title="Add Plant List" sx={{ mb: 5 }} id="add-plant-list-modal-title" />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={!!validationError?.path.includes('name')}
              helperText={validationError?.path.includes('name') && validationError.message}
              fullWidth
              required
            />
            {fileRequired === false && (
              <Typography variant="body1">
                Upload a Plant List and then assign plants to Jobs, RFPs, Quotes, or Orders.
              </Typography>
            )}

            <FileUploader
              files={files}
              onFilesAdded={handleFilesAdded}
              onFileDeleted={handleFileDeleted}
              onCropComplete={handleCropComplete}
              validationError={validationError}
            />

            {fileRequired ? (
              <Box sx={{ pt: 1 }}>
                {validationError?.path.includes('files') && (
                  <Alert severity="error" sx={{ mb: 2.5 }}>
                    {validationError.message}
                  </Alert>
                )}
              </Box>
            ) : (
              files.length === 0 && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={manualEntry}
                        onChange={(e) => setManualEntry(e.target.checked)}
                        color={validationError?.path.includes('files') ? 'error' : 'default'}
                      />
                    }
                    label="I don't have files, I'll add plants manually"
                    sx={{
                      color: validationError?.path.includes('files') ? 'error.light' : 'text.primary',
                    }}
                  />
                  {validationError?.path.includes('files') && validationError?.path.includes('manualEntry') && (
                    <Alert severity="error" sx={{ mb: 2.5 }}>
                      {validationError.message}
                    </Alert>
                  )}
                </>
              )
            )}
          </Box>
          <Box display="flex" justifyContent="end" gap={1}>
            <PrimaryCancelButton onClick={handleClose}>Cancel &amp; Close</PrimaryCancelButton>
            <DarkPrimaryButton type="submit" onClick={handleSubmit} disabled={createPlantListMutation.isPending}>
              Continue
            </DarkPrimaryButton>
          </Box>
        </Box>
      </>
    </Modal>
  )
}
