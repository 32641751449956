import { useState, useMemo, ChangeEvent } from 'react'
import {
  Box,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  FormControl,
  Stack,
} from '@mui/material'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import {
  MinimalOrganizationMemberForNotificationSettings,
  OrganizationMember,
  OrganizationMemberNotificationSettings,
  SnackbarMessage,
} from '@/types.ts'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useMutation } from '@tanstack/react-query'
import { updateOrganizationMemberNotificationSettings } from '@/api/organization_member.ts'
import { AxiosError } from 'axios'
import { TimeDropdown } from '@/components/ui/base/dropdowns/time-dropdowns.tsx'
import {
  convert_12hr_format_to_timestamp,
  convert_timestamp_to_12hr_format,
  getConsolidatedDaysOfWeek,
} from '@/lib/utils.ts'
import { OrganizationMemberNotificationSettingsSchema } from '@/lib/validation-schemas.ts'
import { z } from 'zod'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { SelectChangeEvent } from '@mui/material/Select'
import { allDays, notificationCategories, weekDays } from '@/constants.ts'
import Checkbox from '@mui/material/Checkbox'

const NotificationsSettings = () => {
  const { userInfo } = useUserInfo()
  const [alertMessage, setAlertMessage] = useState<SnackbarMessage | null>(null)
  const organization_members = useMemo(() => {
    return userInfo?.user?.organization_members.map((member) => {
      return convertOrganizationMemberToMinimalOrganizationMember(member)
    })
  }, [userInfo])
  const [selectedOrganizationMember, setSelectedOrganizationMember] = useState(organization_members?.[0])
  const [notificationChannel, setNotificationChannel] = useState(
    determineOrgMemberNotificationChannel(
      selectedOrganizationMember as MinimalOrganizationMemberForNotificationSettings
    )
  )
  const [notificationActivityCategory, setNotificationActivityCategory] = useState(
    determineOrgMemberNotificationActivityCategory(
      selectedOrganizationMember as MinimalOrganizationMemberForNotificationSettings
    )
  )
  const [notificationSchedule, setNotificationSchedule] = useState(
    determineOrgMemberNotificationSchedule(
      selectedOrganizationMember as MinimalOrganizationMemberForNotificationSettings
    )
  )

  function convertOrganizationMemberToMinimalOrganizationMember(organization_member: OrganizationMember) {
    return {
      id: organization_member.id,
      roles: organization_member.roles.map((role) => role.id),
      organization: organization_member.organization.id,
      notification_settings: organization_member.notification_settings,
    } as MinimalOrganizationMemberForNotificationSettings
  }

  function determineOrgMemberNotificationChannel(
    selectedOrganizationMember: MinimalOrganizationMemberForNotificationSettings
  ) {
    const current_selection = selectedOrganizationMember?.notification_settings?.channels || []
    if (current_selection.includes('email') && current_selection.includes('text_message')) {
      return 'both'
    } else if (current_selection.includes('text_message')) {
      return 'text_message'
    } else {
      return 'email'
    }
  }

  function determineOrgMemberNotificationActivityCategory(
    selectedOrganizationMember: MinimalOrganizationMemberForNotificationSettings
  ) {
    return (selectedOrganizationMember?.notification_settings?.subscribed_categories as string[]) || []
  }

  function determineOrgMemberNotificationSchedule(
    selectedOrganizationMember: MinimalOrganizationMemberForNotificationSettings
  ) {
    const notification_settings = selectedOrganizationMember?.notification_settings
    return {
      start_time: convert_timestamp_to_12hr_format(notification_settings?.schedule?.start_time as string),
      end_time: convert_timestamp_to_12hr_format(notification_settings?.schedule?.end_time as string),
      days_of_week: getConsolidatedDaysOfWeek(notification_settings?.schedule?.days_of_week as string[]),
      default_reminder_time: convert_timestamp_to_12hr_format(notification_settings?.default_reminder_time as string),
    }
  }

  const resetAllNotificationData = (selectedMember: MinimalOrganizationMemberForNotificationSettings) => {
    setNotificationChannel(determineOrgMemberNotificationChannel(selectedMember))
    setNotificationActivityCategory(determineOrgMemberNotificationActivityCategory(selectedMember))
    setNotificationSchedule(determineOrgMemberNotificationSchedule(selectedMember))
  }

  const handleOrganizationMemberSelection = (id: string) => {
    if (organization_members) {
      const selectedMember = organization_members.find(
        (member) => member.id === id
      ) as MinimalOrganizationMemberForNotificationSettings

      setSelectedOrganizationMember(selectedMember)
      resetAllNotificationData(selectedMember)
    }
  }

  const updateNotificationSettingsMutation = useMutation({
    mutationFn: updateOrganizationMemberNotificationSettings,
    onSuccess: async (data) => {
      const minimal_org_member = convertOrganizationMemberToMinimalOrganizationMember(data)
      setSelectedOrganizationMember(minimal_org_member)
      resetAllNotificationData(minimal_org_member)
    },
    onError: async (error: AxiosError) => {
      setAlertMessage({
        severity: 'error',
        message:
          (error.response?.data as { detail?: string })?.detail ||
          error.message ||
          'Error removing organization member',
      } as SnackbarMessage)
    },
  })

  const validateForm = (form_data: MinimalOrganizationMemberForNotificationSettings) => {
    setAlertMessage(null)
    try {
      const schema = OrganizationMemberNotificationSettingsSchema
      schema.parse(form_data.notification_settings)
      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        setAlertMessage({
          severity: 'error',
          message: error.errors[0].message || 'Validation error in notification settings data',
        } as SnackbarMessage)
      } else {
        setAlertMessage({
          severity: 'error',
          message: 'Unknown error while validating notification settings data',
        } as SnackbarMessage)
      }
      return false
    }
  }

  const handleNotificationChannel = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const form_data = {
      ...selectedOrganizationMember,
      notification_settings: {
        ...selectedOrganizationMember?.notification_settings,
        channels: value == 'both' ? ['email', 'text_message'] : [value],
      } as OrganizationMemberNotificationSettings,
    } as MinimalOrganizationMemberForNotificationSettings

    if (validateForm(form_data)) {
      await updateNotificationSettingsMutation.mutateAsync(form_data)
    }
  }

  const handleNotificationActivityCategory = async (category: string) => {
    let subscribed_categories: string[] = []

    if (category === 'all') {
      if (notificationActivityCategory.includes('all')) {
        subscribed_categories = []
      } else {
        subscribed_categories = ['all']
      }
    } else {
      if (notificationActivityCategory.includes(category)) {
        subscribed_categories = notificationActivityCategory.filter((c: string) => c !== category && c !== 'all')
      } else {
        subscribed_categories = notificationActivityCategory.filter((c: string) => c !== 'all').concat(category)
      }
    }

    const form_data = {
      ...selectedOrganizationMember,
      notification_settings: {
        ...selectedOrganizationMember?.notification_settings,
        subscribed_categories: subscribed_categories as string[],
      } as OrganizationMemberNotificationSettings,
    } as MinimalOrganizationMemberForNotificationSettings

    if (validateForm(form_data)) {
      await updateNotificationSettingsMutation.mutateAsync(form_data)
    }
  }

  const handleNotificationSettingsSchedule = async (name: string, time: string) => {
    const updated_notification_settings =
      name === 'default_reminder_time'
        ? {
            ...selectedOrganizationMember?.notification_settings,
            default_reminder_time: convert_12hr_format_to_timestamp(time),
          }
        : {
            ...selectedOrganizationMember?.notification_settings,
            schedule: {
              ...selectedOrganizationMember?.notification_settings?.schedule,
              [name]: convert_12hr_format_to_timestamp(time),
            },
          }
    const form_data = {
      ...selectedOrganizationMember,
      notification_settings: updated_notification_settings as OrganizationMemberNotificationSettings,
    } as MinimalOrganizationMemberForNotificationSettings
    if (validateForm(form_data)) {
      await updateNotificationSettingsMutation.mutateAsync(form_data)
    }
  }

  const handleNotificationSettingsDaysOfWeek = async (e: SelectChangeEvent<string>) => {
    const value = e.target.value == 'everyday' ? allDays : weekDays
    const form_data = {
      ...selectedOrganizationMember,
      notification_settings: {
        ...selectedOrganizationMember?.notification_settings,
        schedule: {
          ...selectedOrganizationMember?.notification_settings?.schedule,
          days_of_week: value,
        },
      } as OrganizationMemberNotificationSettings,
    } as MinimalOrganizationMemberForNotificationSettings
    if (validateForm(form_data)) {
      await updateNotificationSettingsMutation.mutateAsync(form_data)
    }
  }

  return (
    <>
      <Typography variant="tabHeader" sx={{ mb: 5, pt: '4px' }}>
        Notifications
      </Typography>

      {/* Organization Select */}
      <TextField
        fullWidth
        select
        label="Organization"
        value={selectedOrganizationMember?.id || ''}
        onChange={(e) => handleOrganizationMemberSelection(e.target.value)}
        sx={{ maxWidth: 350 }}
      >
        {userInfo?.user?.organization_members.map((member) => (
          <MenuItem key={member.id} value={member.id}>
            {member?.organization?.name}
          </MenuItem>
        ))}
      </TextField>

      <Divider sx={{ my: 5 }} />

      {/* Notification Type */}
      <FormControl component="fieldset" margin="normal" sx={{ m: 0 }}>
        <Typography variant="tabSection">Send notifications via:</Typography>
        <RadioGroup value={notificationChannel} onChange={(e) => handleNotificationChannel(e)}>
          <FormControlLabel value="email" control={<Radio />} label="Email" />
          <FormControlLabel value="text_message" control={<Radio />} label="SMS (requires phone)" />
          <FormControlLabel value="both" control={<Radio />} label="Both Email & SMS" />
        </RadioGroup>
      </FormControl>

      <Divider sx={{ my: 5 }} />

      {/* Notification Schedule */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Typography variant="tabSection">Notification Schedule:</Typography>
          <Typography variant="body1">
            You&apos;ll only receive notification in the hours you choose. Out of those times, notifications will be
            paused.
            <Typography component="span" variant="body1" sx={{ paddingLeft: '5px' }}>
              Learn more
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="tabSection">Allow notifications:</Typography>
          <Stack direction="row" spacing={2}>
            <Select
              variant="outlined"
              fullWidth
              labelId="schedule-label"
              value={notificationSchedule.days_of_week as string}
              onChange={handleNotificationSettingsDaysOfWeek}
              sx={{ maxWidth: 200 }}
            >
              <MenuItem value="everyday">Every day</MenuItem>
              <MenuItem value="weekdays">Weekdays</MenuItem>
            </Select>
            <Stack direction="row" gap={1} alignItems="center">
              <TimeDropdown
                name="start_time"
                selectedTime={notificationSchedule.start_time}
                setSelectedTime={handleNotificationSettingsSchedule}
              />
              <Typography>to</Typography>
              <TimeDropdown
                name="end_time"
                selectedTime={notificationSchedule.end_time}
                setSelectedTime={handleNotificationSettingsSchedule}
              />
            </Stack>
          </Stack>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="tabSection">Set a default time for reminder notifications:</Typography>
          <TimeDropdown
            name="default_reminder_time"
            selectedTime={notificationSchedule.default_reminder_time}
            setSelectedTime={handleNotificationSettingsSchedule}
          />
        </Box>
      </Box>

      <Divider sx={{ my: 5 }} />

      {/* Notify me about section */}
      <Typography variant="tabSection">Notify me about:</Typography>
      <Stack>
        {notificationCategories.map(([key, label]) => {
          const isAllSelected = notificationActivityCategory.includes('all')
          const isChecked = isAllSelected || notificationActivityCategory.includes(key)
          const isDimmed = key !== 'all' && isAllSelected

          return (
            <Box sx={{ opacity: isDimmed ? 0.3 : 1 }} key={`category-${key}`}>
              <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={() => handleNotificationActivityCategory(key)} />}
                label={label}
              />
            </Box>
          )
        })}
      </Stack>

      <Snackbar
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={() => setAlertMessage(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ marginRight: '2em' }}
      >
        <Alert onClose={() => setAlertMessage(null)} severity={alertMessage?.severity || 'info'} sx={{ width: '100%' }}>
          {alertMessage?.message || ''}
        </Alert>
      </Snackbar>
    </>
  )
}

export default NotificationsSettings
