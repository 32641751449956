import { useState, ChangeEvent } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'

type DataSharingProps = {
  switchToOrganizationTab: (value: boolean) => void
}

export default function DataSharing({ switchToOrganizationTab }: DataSharingProps) {
  const [plantbidAccountId, setPlantbidAccountId] = useState('')

  const handleExport = () => {
    // TODO: implement data export
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlantbidAccountId(e.target.value)
  }

  return (
    <Box display="flex" flexDirection="column" gap={1} pt="4px">
      <Typography variant="tabHeader">Data Sharing</Typography>
      <Typography variant="strong">EXPORT to an existing Plantbid account&apos;s data</Typography>
      <Typography variant="body1">
        You must be an admin of <Typography variant="strong">BOTH</Typography> account to export an existing
        organization&apos;s data, otherwise you can request permission from other account&apos;s admin.
      </Typography>
      <Grid container spacing={2} marginY={1} alignItems="center">
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="account_id"
            label="Plantbid Account ID"
            placeholder="Type ID"
            fullWidth
            value={plantbidAccountId}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <DarkPrimaryButton onClick={() => handleExport()} disabled={!plantbidAccountId}>
            Continue to Export
          </DarkPrimaryButton>
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        className="cursor-pointer text-xs underline"
        onClick={() => switchToOrganizationTab(true)}
      >
        Where do I find my Plantbid account ID ?
      </Typography>
    </Box>
  )
}
