import { useAuth0 } from '@auth0/auth0-react'
import { useTheme } from '@mui/material'
import { grey, red } from '@mui/material/colors'
import React, { ReactNode, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import { Link } from '@tanstack/react-router'

import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Building2, ClipboardList, Download, Files, Import, Leaf, LogIn, MountainSnow } from 'lucide-react'

import UserMenu from './user-menu.tsx'
import OrganizationMenu from './organization-menu.tsx'
import router from '@/router.ts'

import logo from '@/assets/logo-white.svg'
import logoSquare from '@/assets/logo-square-white.svg'
import Typography from '@mui/material/Typography'

type LinkProps = React.ComponentProps<typeof Link<typeof router>>
type ToOptions = NonNullable<LinkProps['to']>
type ValidPaths = Exclude<ToOptions, './' | '../' | undefined>

type MenuItem = {
  text: string
  icon: ReactNode
  to: ValidPaths
  notificationCount?: number
  infoCount?: number
}

export default function SideNav() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const theme = useTheme()
  const [open, setOpen] = useState(() => {
    const sideNavOpen = localStorage.getItem('side_nav_open')
    return sideNavOpen ? JSON.parse(sideNavOpen) : true
  })

  const menuItems: MenuItem[] = [
    {
      text: 'IMPORTER',
      icon: <Import />,
      to: '/plant-lists',
    },
  ]
  const secondaryMenuItems: MenuItem[] = [
    {
      text: 'PLANTS',
      icon: <Leaf />,
      to: '/plants',
    },
    {
      text: 'JOBS',
      icon: <MountainSnow />,
      to: '/jobs',
    },
    {
      text: 'RFPS',
      icon: <Download />,
      to: '/rfps',
    },
    {
      text: 'QUOTES',
      icon: <Files />,
      to: '/quotes',
    },
    {
      text: 'ORDERS',
      icon: <ClipboardList />,
      to: '/orders',
    },
    {
      text: 'CONTACTS',
      icon: <Building2 />,
      to: '/contacts',
    },
  ]

  useEffect(() => {
    localStorage.setItem('side_nav_open', JSON.stringify(open))
  }, [open])

  const organizationMenu = (
    <List>
      <OrganizationMenu expanded={open} />
    </List>
  )

  const userMenu = isLoading ? null : isAuthenticated ? (
    <Box>
      <Divider variant="middle" sx={{ bgcolor: 'grey' }} />
      <List>
        <UserMenu expanded={open} />
      </List>
    </Box>
  ) : (
    <List>
      <ListItem
        component="button"
        onClick={() => loginWithRedirect()}
        sx={{
          justifyContent: 'initial',
          color: 'white',
          ':hover': {
            bgcolor: grey[700],
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 2 : 'auto',
            justifyContent: 'center',
            color: 'white',
          }}
        >
          <LogIn />
        </ListItemIcon>
        {open && <ListItemText primary="Login" />}
      </ListItem>
    </List>
  )

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  const plantbidLogo = (
    <>
      {open && (
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <Typography variant="subtitle1" color="white" fontSize="smaller">
            POWERED BY
          </Typography>
          <Box
            component="img"
            alt="Plantbid logo"
            src={logo}
            sx={{
              height: 24,
            }}
          />
        </Box>
      )}
      {!open && (
        <Tooltip title="Powered by Plantbid" placement="right" arrow>
          <Box display="flex" flexDirection="column" alignItems="center" p={2}>
            <Box
              component="img"
              alt="small Plantbid logo"
              src={logoSquare}
              sx={{
                height: 24,
              }}
            />
          </Box>
        </Tooltip>
      )}
    </>
  )

  const renderMenuItem = (item: MenuItem) => {
    const listItem = (
      <ListItem
        key={item.text}
        component={Link}
        to={item.to}
        sx={{
          justifyContent: open ? 'initial' : 'center',
          color: 'white',
          ':hover': {
            bgcolor: grey[700],
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 2 : 'auto',
            justifyContent: 'center',
            color: 'inherit',
          }}
        >
          {item.icon}
          {!open && item.notificationCount && item.notificationCount > 0 ? (
            <Box
              sx={{
                position: 'absolute',
                top: 6,
                right: 15,
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: red[500],
              }}
            />
          ) : null}
        </ListItemIcon>
        {open && (
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <ListItemText primary={item.text} sx={{ textAlign: 'left' }} />{' '}
            <Box
              sx={{
                bgcolor: red[500],
                px: 1,
                borderRadius: 1,
                fontWeight: 'bold',
              }}
            >
              {item.notificationCount && item.notificationCount > 99 ? '99+' : item.notificationCount || null}
            </Box>
            <Box sx={{ fontWeight: 'bold' }}>
              {item.infoCount && item.infoCount > 999 ? '999+' : item.infoCount || null}
            </Box>
          </Box>
        )}
      </ListItem>
    )

    return open ? (
      listItem
    ) : (
      <Tooltip key={`${item.text}-tooltip`} title={item.text} placement="right" arrow>
        {listItem}
      </Tooltip>
    )
  }

  const drawerContent = (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 4,
        paddingBottom: 2,
      }}
    >
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {isAuthenticated ? (
          <>
            {organizationMenu}
            <Divider variant="middle" sx={{ bgcolor: 'grey' }} />
            <List>{menuItems.map((item) => renderMenuItem(item))}</List>
            {secondaryMenuItems.length > 0 && (
              <Box>
                <Divider variant="middle" sx={{ bgcolor: 'grey' }} />
                <List>{secondaryMenuItems.map((item) => renderMenuItem(item))}</List>
              </Box>
            )}
            <Divider variant="middle" sx={{ bgcolor: 'grey' }} />
            {plantbidLogo}
          </>
        ) : (
          plantbidLogo
        )}
      </Box>

      {userMenu}
    </Box>
  )
  return (
    <Box sx={{ position: 'relative' }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: open ? 260 : 60,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            bgcolor: '#333333',
            width: open ? 260 : 60,
            overflowX: 'hidden',
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Box
        sx={{
          position: 'absolute',
          top: '30px',
          right: open ? -15 : -17,
          transform: 'translateY(-50%)',
          zIndex: 1200,
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            backgroundColor: grey[800],
            boxShadow: theme.shadows[2],
            color: 'white',
            width: 30,
            height: 30,
            '&:hover': {
              backgroundColor: grey[600],
            },
          }}
        >
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Box>
    </Box>
  )
}
