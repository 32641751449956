import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'

import { RangeDimension } from '../../types.ts'

type RangeDimensionInputProps = {
  dimension: RangeDimension
  label: string
  onChange: (dimension: RangeDimension) => void
}

export default function RangeDimensionInput({ dimension, label, onChange }: RangeDimensionInputProps) {
  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <Box display="flex" gap={2} marginTop={1}>
        <TextField
          label="Min"
          type="number"
          value={dimension.min ?? ''}
          onChange={(e) =>
            onChange({
              ...dimension,
              min: e.target.value ? Number(e.target.value) : null,
            })
          }
          sx={{ maxWidth: 85 }}
        />
        <TextField
          label="Max"
          type="number"
          value={dimension.max ?? ''}
          onChange={(e) =>
            onChange({
              ...dimension,
              max: e.target.value ? Number(e.target.value) : null,
            })
          }
          sx={{ maxWidth: 85 }}
        />
      </Box>
    </Box>
  )
}
