import React, { createContext, useContext, useState, useEffect } from 'react'
import PubNub from 'pubnub'
import api from '@/api/api'
import { API_BASE_URL } from '@/constants'
import { useUserInfo } from '@/contexts/hooks/useUserInfo'

type PubNubInstance = {
  pubnub: PubNub
  channels: string[]
  subscriptionSet: any
}

type PubNubContextType = {
  subscribeToChannels: (
    channels: string[],
    type: 'user' | 'plantList',
    messageHandler: (messageEvent: any) => void
  ) => Promise<void>
  unsubscribeFromChannels: (channels: string[], type: 'user' | 'plantList') => void
}

const PubNubContext = createContext<PubNubContextType | null>(null)

export const usePubNub = () => {
  const context = useContext(PubNubContext)
  if (!context) {
    throw new Error('usePubNub must be used within a PubNubProvider')
  }
  return context
}

export const PubNubProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userPubNub, setUserPubNub] = useState<PubNubInstance | null>(null)
  const [plantListPubNub, setPlantListPubNub] = useState<PubNubInstance | null>(null)
  const { userInfo } = useUserInfo()

  const initializePubNub = (authKey: string, channels: string[], messageHandler: any): PubNubInstance => {
    const pubnub = new PubNub({
      publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
      subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
      authKey: authKey,
      userId: userInfo?.user.id || 'MY_UNIQUE_USER_ID',
    })

    const subscriptionSet = pubnub.subscriptionSet({ channels })
    subscriptionSet.addListener({
      message: messageHandler,
    })
    subscriptionSet.subscribe()
    return { pubnub, channels, subscriptionSet }
  }

  const fetchAuthKey = async (channels: string[], endpoint: string, type: 'user' | 'plantList') => {
    const response = await api.post(
      `${API_BASE_URL}${endpoint}`,
      { authorized_channels: channels },
      { headers: { 'Content-Type': 'application/json' } }
    )
    const authKey = response.data.auth_key

    // Update existing PubNub instance with new auth key if it exists
    const existingInstance = type === 'user' ? userPubNub : plantListPubNub
    if (existingInstance) {
      existingInstance.pubnub.setAuthKey(authKey)
    }

    return authKey
  }

  const subscribeToChannels = async (channels: string[], type: 'user' | 'plantList', messageHandler: any) => {
    const endpoint =
      type === 'user' ? '/v1/users/pubsub_user_auth/' : '/v1/mdb/ai_processed_plant_list_file/pubsub_user_auth/'

    const authKey = await fetchAuthKey(channels, endpoint, type)

    let pubNubInstance = type === 'user' ? userPubNub : plantListPubNub

    if (!pubNubInstance) {
      pubNubInstance = initializePubNub(authKey, channels, messageHandler)
      if (type === 'user') {
        setUserPubNub(pubNubInstance)
      } else {
        setPlantListPubNub(pubNubInstance)
      }
    } else {
      // unsubscribe from old channels
      pubNubInstance.pubnub.unsubscribeAll()

      pubNubInstance.pubnub.setAuthKey(authKey)
      const subscriptionSet = pubNubInstance.pubnub.subscriptionSet({
        channels,
      })

      subscriptionSet.addListener({
        message: messageHandler,
      })

      subscriptionSet.subscribe()
      pubNubInstance.subscriptionSet = subscriptionSet
      pubNubInstance.channels = channels
    }
  }

  const unsubscribeFromChannels = (channels: string[], type: 'user' | 'plantList') => {
    const instance = type === 'user' ? userPubNub : plantListPubNub
    if (instance) {
      instance.subscriptionSet.unsubscribe()
      const updatedChannels = instance.channels.filter((ch) => !channels.includes(ch))
      if (type === 'user') {
        setUserPubNub({ ...instance, channels: updatedChannels })
      } else {
        setPlantListPubNub({ ...instance, channels: updatedChannels })
      }
    }
  }

  useEffect(() => {
    return () => {
      userPubNub?.pubnub.unsubscribeAll()
      plantListPubNub?.pubnub.unsubscribeAll()
    }
  }, [])

  const value = {
    subscribeToChannels,
    unsubscribeFromChannels,
  }

  return <PubNubContext.Provider value={value}>{children}</PubNubContext.Provider>
}
