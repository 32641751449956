import { useEffect, useState } from 'react'
import type { GCSFile, ImageFile } from '@/types.ts'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import Avatar from '@mui/material/Avatar'
import { Building2Icon } from 'lucide-react'
import { Typography } from '@mui/material'

export const OrganizationLogo = ({
  organizationInitial = '',
  logoFile,
  size,
}: {
  organizationInitial?: string
  logoFile: GCSFile | null
  size: number
}) => {
  const [logoImage, setLogoImage] = useState<ImageFile | null>(null)

  useEffect(() => {
    const fetchLogo = async () => {
      if (logoFile) {
        const image = await fetchGCSFile(logoFile)
        setLogoImage(image as ImageFile)
      } else {
        setLogoImage(null)
      }
    }

    fetchLogo()
  }, [logoFile])

  return logoImage ? (
    <Avatar
      src={logoImage.url}
      alt="organization-logo"
      variant="rounded"
      sx={{
        height: size,
        width: size,
      }}
    />
  ) : (
    <Avatar
      alt="organization-logo"
      variant="rounded"
      sx={{
        height: size,
        width: size,
      }}
    >
      {organizationInitial ? (
        <Typography sx={{ fontSize: size / 2 }}>{organizationInitial}</Typography>
      ) : (
        <Building2Icon />
      )}
    </Avatar>
  )
}
