import api from './api.ts'
import type { ElasticsearchResponse } from '@/types.ts'

type SearchInput = {
  scientific_name?: string | null
  common_name?: string | null
  display_name?: string | null
}
export async function searchV1Plant({ scientific_name, common_name, display_name }: SearchInput) {
  // return empty results when no input is provided
  if (!scientific_name && !common_name && !display_name) {
    return { results: [] }
  }

  const url = '/v1/elasticsearch/plants/?'
  const scientific_name_param = scientific_name ? `scientific_name=${encodeURIComponent(scientific_name)}` : ''
  const common_name_param = common_name ? `common_names=${encodeURIComponent(common_name)}` : ''
  const display_name_param = display_name ? `display_name=${encodeURIComponent(display_name)}` : ''

  const queryParams = [scientific_name_param, common_name_param, display_name_param].filter((param) => param).join('&')

  const response = await api.get(`${url}${queryParams}`)
  return response.data
}

export async function basicSearchV1Plant(query: string) {
  const response = await api.get(`/v1/elasticsearch/plants/basic_search/?query=${encodeURIComponent(query)}`)
  return response.data
}

export async function search<T>(
  url: string,
  query: string,
  page: number,
  per_page: number,
  required_fields?: string[],
  organization_id?: string
) {
  const options = {
    page,
    per_page,
    organization_id,
    optional_fields: {
      name: query,
      'files.user_file_name': query,
    },
    required_fields: {
      ...(required_fields || []).reduce(
        (acc, field) => {
          acc[field] = query
          return acc
        },
        {} as Record<string, string>
      ),
    },
  }
  const response = await api.post(url, options, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as ElasticsearchResponse<T>
}
