import { createContext, useState, useCallback, ReactNode } from 'react'
import type { ToastNotificationMessage } from '@/types'
import ToastNotifications from '@/components/ui/notifications.tsx'

type ToastNotificationsContextType = {
  addToastNotification: (message: ToastNotificationMessage) => void
  removeToastNotification: (id: string) => void
}

export const ToastNotificationsContext = createContext<ToastNotificationsContextType | null>(null)

export function ToastNotificationsProvider({ children }: { children: ReactNode | undefined }) {
  const [notifications, setNotifications] = useState<ToastNotificationMessage[]>([])

  const removeToastNotification = useCallback((id: string) => {
    setNotifications((prev) => {
      const newState = prev.filter((notification) => notification.id !== id)
      return newState
    })
  }, [])

  const addToastNotification = useCallback(
    (message: ToastNotificationMessage) => {
      const id = crypto.randomUUID()
      setNotifications((prev) => {
        const newState = [...prev, { ...message, id }]
        return newState
      })

      // Auto-remove after 5 seconds
      setTimeout(() => {
        removeToastNotification(id)
      }, 5000)
    },
    [removeToastNotification]
  )

  return (
    <ToastNotificationsContext.Provider
      value={{
        addToastNotification: addToastNotification,
        removeToastNotification: removeToastNotification,
      }}
    >
      {children}
      <ToastNotifications notifications={notifications} />
    </ToastNotificationsContext.Provider>
  )
}
