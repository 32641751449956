import { useMemo, type HTMLAttributes } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { Public as GlobeIcon } from '@mui/icons-material'
import { getMajorTimezones, getCurrentTime, TimezoneInfo } from '@/lib/timezone.ts'

interface TimezonePickerProps {
  value: string | null
  onChange: (timezoneInfo: TimezoneInfo | null) => void
}

export default function TimezonePicker({ value, onChange }: TimezonePickerProps) {
  // Get and memoize the timezone list
  const timezones = useMemo(() => getMajorTimezones(), [])

  // Find the selected timezone option
  const selectedOption = useMemo(() => timezones.find((tz) => tz.value === value), [timezones, value])

  return (
    <Autocomplete
      value={selectedOption ?? null}
      onChange={(_event, newValue) => {
        onChange?.(newValue)
      }}
      options={timezones}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, { inputValue }) => {
        const searchText = inputValue.toLowerCase()
        return options.filter(
          (option) =>
            option.label.toLowerCase().includes(searchText) || option.searchTerms.toLowerCase().includes(searchText)
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Timezone"
          placeholder="Search by city, timezone, or region..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <GlobeIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props: HTMLAttributes<HTMLLIElement> & { key: string }, option: TimezoneInfo) => {
        const { key, ...otherProps } = props
        return (
          <Box
            component="li"
            key={key}
            {...otherProps}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              py: 1,
            }}
          >
            <Typography>{option.label}</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
              {getCurrentTime(option.value)}
            </Typography>
          </Box>
        )
      }}
      sx={{
        width: '100%',
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  )
}
