import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormLabel from '@mui/material/FormLabel'
import Input from '@mui/material/Input'
import TextField from '@mui/material/TextField'

import { processFileWithAI } from '@/api/ai-processed-plant-list-file.ts'
import { uploadFile } from '@/api/gcs.ts'
import { createPlantList } from '@/api/plant-list.ts'
import { ACCEPTED_FILE_EXTENSIONS, ACCEPTED_FILE_TYPES } from '@/constants.ts'
import { CreatePlantListFormSchema, type PlantListForm } from '@/lib/validation-schemas.ts'
import type { PlantList } from '@/types.ts'

type CreatePlantListModalProps = {
  onPlantListCreated: (plantList: PlantList) => Promise<void>
  onClose: () => void
}
export default function CreatePlantListModal({ onPlantListCreated, onClose }: CreatePlantListModalProps) {
  const [error, setError] = useState(false)

  const form = useForm<PlantListForm>({
    resolver: zodResolver(CreatePlantListFormSchema),
    defaultValues: {
      name: '',
    },
  })
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = form
  const createPlantListMutation = useMutation({
    mutationFn: createPlantList,
  })
  const uploadFileMutation = useMutation({
    mutationFn: uploadFile,
  })
  const processFileMutation = useMutation({
    mutationFn: processFileWithAI,
  })

  const onSubmit = async (data: PlantListForm) => {
    const formData = new FormData()
    formData.append('name', data.name)
    for (const file of data.files) {
      formData.append('files', file)
    }

    try {
      // create plant list
      const createdPlantList = await createPlantListMutation.mutateAsync(formData)
      // upload plant list files to cloud storage
      for (const file of createdPlantList.files) {
        const formFiles = formData.getAll('files') as File[]
        const putUrl = file.put_url
        const contentType = file.file_type
        const fileToUpload = formFiles.find((f) => f.name === file.user_file_name)
        if (fileToUpload) {
          await uploadFileMutation.mutateAsync({
            file: fileToUpload,
            contentType,
            putUrl,
          })
        }
      }
      // process each image with AI
      for (const file of createdPlantList.files) {
        await processFileMutation.mutateAsync({
          organizationId: createdPlantList.organization.id,
          fileId: file.id,
          plantListId: createdPlantList.id,
        })
      }
      await onPlantListCreated(createdPlantList)
    } catch (error) {
      console.error(error)
      setError(true)
    }
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center overflow-y-auto bg-gray-600/50 backdrop-blur-sm">
        <div className="relative w-1/3 rounded-md border bg-white p-8 shadow-lg">
          <h2 className="text-lg">New Plant List</h2>
          <div className="mt-2 px-6 py-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField {...field} label="Name" fullWidth error={!!errors.name} />
                      {errors.name && (
                        <Alert severity="error" className="my-2">
                          {errors.name.message}
                        </Alert>
                      )}
                    </>
                  )}
                />
              </div>
              <FormLabel error={!!errors.files}>Files</FormLabel>
              <div>
                <Alert severity="info" className="my-2">
                  <p>Supported file types are: {ACCEPTED_FILE_EXTENSIONS.join(', ')}</p>
                </Alert>
                <Controller
                  name="files"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        fullWidth
                        type="file"
                        inputProps={{
                          multiple: true,
                          accept: ACCEPTED_FILE_TYPES.join(','),
                        }}
                        {...register('files')}
                        onChange={(e) => {
                          field.onChange((e.target as HTMLInputElement).files)
                        }}
                        error={!!errors.files}
                      />
                      {errors.files && (
                        <Alert severity="error" className="my-2">
                          {String(errors.files.message)}
                        </Alert>
                      )}
                    </>
                  )}
                />
              </div>
              {error && (
                <Alert severity="error" className="mt-2">
                  An error occurred while creating the plant list.
                </Alert>
              )}
              <div className="mt-4 flex justify-end space-x-2">
                <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <CircularProgress size={16} color="inherit" className="mr-2" />} Create
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
