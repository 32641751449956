import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import theme, { secondaryFontFamily } from '@/theme.ts'
import Box from '@mui/material/Box'

// Need to make colors and styles consistent across the app
// abstract away statically typed colors into constants ??

const disabledStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  color: 'rgba(0, 0, 0, 0.26)',
  cursor: 'not-allowed',
  pointerEvents: 'all !important',
}

export const DarkPrimaryButton = styled(Button)({
  backgroundColor: 'black',
  padding: '8px 32px',
  color: 'white',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'black',
  },
  '&:disabled': disabledStyle,
})

export const PrimaryCancelButton = styled(Button)({
  backgroundColor: '#F5F5F5',
  padding: '8px 32px',
  color: 'black',
  borderColor: '#CCCCCC',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
})

export const TextPrimaryButton = styled(Button)({
  borderColor: 'grey.200',
  paddingX: 1,
  paddingY: 0.5,
  color: 'text.primary.blue',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': {
    borderColor: 'grey.300',
    backgroundColor: 'grey.100',
  },
})

export const PrimaryDeleteButton = styled(Button)({
  cursor: 'pointer',
  color: 'white',
  padding: '0.5em',
  backgroundColor: 'red',
  transition: 'all 0.2s ease',
  border: '1px solid red',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette.error.light,
  },
})

export const SecondaryDeleteButton = styled(Box)({
  cursor: 'pointer',
  color: 'red',
  padding: '0.5em',
  transition: 'all 0.2s ease',
  border: '1px solid',
  borderColor: 'transparent',
  borderRadius: '8px',
  '&:hover': {
    borderColor: theme.palette.error.light,
  },
})

export const InfoButton = styled(Button)({
  backgroundColor: theme.palette.grey[100],
  fontFamily: secondaryFontFamily,
  fontSize: '15px',
  lineHeight: '22px',
  textAlign: 'center',
  fontWeight: 400,
  borderRadius: theme.borderRadius.sm,
  border: `1px solid ${theme.palette.grey[400]}`,
  color: 'black',
  padding: '10px 20px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
})
