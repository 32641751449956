import Divider from '@mui/material/Divider'
import theme from '@/theme.ts'

interface CustomDividerProps {
  size?: string
  color?: string
}
export const CustomDivider = ({ size, color }: CustomDividerProps) => {
  const borderSize = size ? size : '2px'
  const borderColor = color ? color : theme.palette.mediumGrey3.main
  return <Divider sx={{ border: `${borderSize} solid`, borderColor: { borderColor } }} />
}
