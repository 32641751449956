import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import VendorGroups from '@/components/rfps/draft/steps/vendor-groups.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useState } from 'react'
import Button from '@mui/material/Button'
import RfpSentSuccessModal from '@/components/ui/modals/rfp-sent-success-modal.tsx'
import { RFPPageContent } from '@/components/rfps/draft/steps/build-rfp-step-content.tsx'
import Box from '@mui/material/Box'

export default function ReviewAndSendStep({ rfpData }: { rfpData: any }) {
  const { selectedOrganization } = useOrganization()
  const [selectedVendorId, setSelectedVendorId] = useState<string>()
  const [selectedSentRfp, setSelectedSentRfp] = useState<any | null>(null)
  const [rfpSentSuccessModalOpen, setRfpSentSuccessModalOpen] = useState(false)

  // const { data: sentRfps } = useQuery({
  //   queryKey: ['sent-rfps', selectedOrganization?.id],
  //   queryFn: async () => {
  //     if (!rfpData.id || !selectedOrganization?.id) return []
  //     return getSentRfpsByRfpId(rfpData.id, selectedOrganization?.id)
  //   },
  //   enabled: !!selectedOrganization && !!rfpData?.id,
  // })
  console.log('selectedOrganization', selectedOrganization)
  const sentRfps = [
    {
      id: '1',
      directed_organization_contact: {
        organization_name: 'Vendor 1',
      },
      directed_organization_user_contacts: [
        {
          id: '1',
          first_name: 'John',
          last_name: 'Doe',
          email: 'jdoe@vendor1.com',
        },
      ],
      plants: [
        {
          caliper: {
            min: null,
            max: null,
            unit: null,
          },
          dbh: {
            min: null,
            max: null,
            unit: null,
          },
          container: {
            min: null,
            max: null,
            unit: null,
          },
          height: {
            min: null,
            max: 3,
            unit: 'feet',
          },
          width: {
            min: null,
            max: null,
            unit: null,
          },
          surface_area: {
            min: null,
            max: null,
            unit: null,
          },
          package_count: {
            min: null,
            max: null,
          },
          quantity_count: {
            min: 26,
            max: 26,
          },
          shipping_container: null,
          plant_container: null,
          root_packaging: null,
          trunk_form: null,
          scientific_name: 'Callistemon Little John',
          common_name: 'Little John Bottle Brush',
          notes: 'Drought Resistant',
          shape: [],
          palm_trunk: [],
          plant_stage: [],
          characteristics: [],
          id: '678e4ec933bcdadb1ceb5bcf',
          file_id: '678e4eb61d9a199768ee8111',
          rfp_id: [],
          parent_of_order: '678e4ec933bcdadb1ceb5bd0',
          deleted_at: null,
          plant_id: null,
          plantlist_id: null,
          job_id: null,
        },
        {
          caliper: {
            min: null,
            max: null,
            unit: null,
          },
          dbh: {
            min: null,
            max: null,
            unit: null,
          },
          container: {
            min: null,
            max: null,
            unit: null,
          },
          height: {
            min: 24,
            max: 24,
            unit: 'inch',
          },
          width: {
            min: 15,
            max: 15,
            unit: 'inch',
          },
          surface_area: {
            min: null,
            max: null,
            unit: null,
          },
          package_count: {
            min: null,
            max: null,
          },
          quantity_count: {
            min: 27,
            max: 27,
          },
          shipping_container: null,
          plant_container: null,
          root_packaging: null,
          trunk_form: null,
          scientific_name: "Abelia x Grandiflora 'Little Richard'",
          common_name: 'Little Richard Glossy Abelia',
          notes: 'Low Water, Heat Tolerant',
          shape: [],
          palm_trunk: [],
          plant_stage: [],
          characteristics: [],
          id: '678e4ec933bcdadb1ceb5bd0',
          file_id: '678e4eb61d9a199768ee8111',
          rfp_id: [],
          parent_of_order: '678e4ec933bcdadb1ceb5bd1',
          deleted_at: null,
          plant_id: null,
          plantlist_id: null,
          job_id: null,
        },
        {
          caliper: {
            min: null,
            max: null,
            unit: null,
          },
          dbh: {
            min: null,
            max: null,
            unit: null,
          },
          container: {
            min: null,
            max: null,
            unit: null,
          },
          height: {
            min: 3,
            max: 4,
            unit: 'feet',
          },
          width: {
            min: null,
            max: null,
            unit: null,
          },
          surface_area: {
            min: null,
            max: null,
            unit: null,
          },
          package_count: {
            min: null,
            max: null,
          },
          quantity_count: {
            min: 23,
            max: null,
          },
          shipping_container: null,
          plant_container: null,
          root_packaging: null,
          trunk_form: null,
          scientific_name: "Ilex Cornuta 'Rotunda'",
          common_name: 'Dwarf Chinese Holly',
          notes: null,
          shape: [],
          palm_trunk: [],
          plant_stage: [],
          characteristics: [],
          id: '678e4ec933bcdadb1ceb5bd1',
          file_id: '678e4eb61d9a199768ee8111',
          rfp_id: [],
          parent_of_order: null,
          deleted_at: null,
          plant_id: null,
          plantlist_id: null,
          job_id: null,
        },
      ],
    },
    {
      id: '2',
      directed_organization_contact: {
        organization_name: 'Vendor 2',
      },
      directed_organization_user_contacts: [
        {
          id: '2',
          first_name: 'Jane',
          last_name: 'Doe',
          email: 'jane_doe@vendor2.com',
        },
      ],
      plants: [],
    },
  ]

  const handleVendorClick = (sentRfp: any) => {
    setSelectedVendorId(sentRfp.id)
    setSelectedSentRfp(sentRfp)
  }

  const handleUpdateRfpData = (_updates: Partial<any>) => {
    console.log('should not need to update at this stage')
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
      <Stack sx={{ width: 350, backgroundColor: 'white', p: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: 400 }}>
          Review RFP for
        </Typography>
        <VendorGroups sentRfps={sentRfps} selectedVendorId={selectedVendorId} onVendorClick={handleVendorClick} />
      </Stack>
      <Stack spacing={1} sx={{ flexGrow: 1 }}>
        <Stack
          spacing={3}
          sx={{ p: 5, flexGrow: 1, overflow: 'auto', scrollbarWidth: 'none', height: 0, position: 'relative' }}
        >
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            Selected RFP: {selectedSentRfp?.id || 'None Selected'}
          </Typography>
          <Button variant="contained" onClick={() => setRfpSentSuccessModalOpen(true)} sx={{ maxWidth: '300px' }}>
            Test Sent Success Modal
          </Button>
          <RFPPageContent
            rfpData={rfpData}
            onUpdateRfpData={handleUpdateRfpData}
            editMode={false}
            sentRfp={selectedSentRfp}
            displayEditButton={true}
          />
        </Stack>
      </Stack>

      <RfpSentSuccessModal
        open={rfpSentSuccessModalOpen}
        onClose={() => setRfpSentSuccessModalOpen(false)}
        rfpId={rfpData.id}
        sentRfps={sentRfps}
      />
    </Box>
  )
}
