import axios, { AxiosError, AxiosResponse } from 'axios'
import { API_BASE_URL, AUTH0_AUDIENCE } from '@/constants.ts'
import { logError } from '@/api/utils/logger.ts'

export interface PlantBidAPIValidationError extends AxiosError {
  response: AxiosResponse<{
    detail?: {
      input: string
      msg: string
      type: string
      loc: string[]
    }[]
  }>
}

let getAccessTokenSilently: (options?: any) => Promise<string>
let loginWithRedirect: () => void

const isTokenExpired = (token: string): boolean => {
  try {
    const [, payload] = token.split('.')
    const decodedPayload = JSON.parse(atob(payload))
    // Check if the token expires in less than 5 minutes
    const expirationDate = new Date(decodedPayload.exp * 1000)
    const now = new Date()
    return expirationDate.getTime() - now.getTime() < 5 * 60 * 1000
  } catch (error) {
    console.error('Error decoding token:', error)
    // If there's an error decoding the token, consider it expired
    return true
  }
}

export const initializeApi = (getToken: () => Promise<string>) => {
  getAccessTokenSilently = getToken
}

export const refreshLogin = (login: () => void) => {
  loginWithRedirect = login
}

export const handleApiError = (error: any) => {
  console.log('error:', error)

  if (error.response && error.response.status === 401) {
    loginWithRedirect()
  } else {
    logError(error)
  }
  return Promise.reject(error)
}

const api = axios.create({
  baseURL: API_BASE_URL,
})

api.interceptors.request.use(
  async (config) => {
    if (!getAccessTokenSilently) {
      console.error('Auth0 not initialized. Call initializeApi first.')
      return config
    }

    try {
      // First, try to get the token normally (from the cache)
      let token = await getAccessTokenSilently()

      // If the token is expired or about to expire, refresh it
      if (isTokenExpired(token)) {
        token = await getAccessTokenSilently({
          cacheMode: 'off',
          authorizationParams: {
            audience: AUTH0_AUDIENCE,
            scope: 'read:current_user read:current_user_identities',
          },
        })
      }
      config.headers['Authorization'] = `Bearer ${token}`
    } catch (error: any) {
      // If the token refresh fails, redirect to login
      if (error.error === 'invalid_grant' || error.error === 'expired_token' || error.error === 'login_required') {
        loginWithRedirect()
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use((response) => response, handleApiError)

export default api
