import { useState, useRef, useEffect, type ChangeEvent, type MouseEvent } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Slider from '@mui/material/Slider'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import DeleteIcon from '@mui/icons-material/Delete'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { ZoomInIcon, ZoomOutIcon } from 'lucide-react'

import { processFileWithAI } from '@/api/ai-processed-plant-list-file.ts'
import { createFile, deleteFile } from '@/api/files.ts'
import { uploadFile } from '@/api/gcs.ts'
import { addFileToPlantList } from '@/api/plant-list.ts'
import {
  ACCEPTED_FILE_EXTENSIONS,
  ACCEPTED_FILE_TYPES,
  IMAGE_FILE_TYPES,
  MAX_IMAGE_DIMENSION,
  SPREADSHEET_FILE_TYPES,
} from '@/constants.ts'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import { checkImageDimensions, isImageFile } from '@/lib/utils.ts'
import type { GCSFile, ImageFile, SpreadsheetFile } from '@/types.ts'

type FileViewerProps = {
  files: GCSFile[]
  onFileDelete: () => void
  onFileUpload: () => void
  plantListId: string
  organizationId: string
}

export default function FileViewer({
  files,
  onFileDelete,
  onFileUpload,
  plantListId,
  organizationId,
}: FileViewerProps) {
  const [error, setError] = useState('')
  const [selectedFileId, setSelectedFileId] = useState<string>('')
  const [isZoomed, setIsZoomed] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(1)
  const [savedZoomLevel, setSavedZoomLevel] = useState(2)
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [clickStartTime, setClickStartTime] = useState(0)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    const availableFiles = files.filter((file) => !file.deleted_at)
    if (availableFiles.length > 0 && (!selectedFileId || !availableFiles.some((file) => file.id === selectedFileId))) {
      setSelectedFileId(availableFiles[0].id)
    } else if (availableFiles.length === 0) {
      setSelectedFileId('')
    }
  }, [files, selectedFileId])

  const { data: selectedFile } = useQuery({
    queryKey: ['file', selectedFileId],
    queryFn: async () => {
      const file = files.find((file) => file.id === selectedFileId)!
      return await fetchGCSFile(file)
    },
    enabled: !!selectedFileId,
  })

  const deleteMutation = useMutation({
    mutationFn: deleteFile,
    onSuccess: (_, deletedFileId) => {
      const remainingFiles = files.filter((file) => file.id !== deletedFileId && !file.deleted_at)
      if (remainingFiles.length > 0) {
        setSelectedFileId(remainingFiles[0].id)
      } else {
        setSelectedFileId('')
      }
      onFileDelete()
    },
  })

  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const newFile: Partial<GCSFile> = {
        user_file_name: file.name,
        file_type: file.type,
        encoding: file.type,
        domain: 'plant_list',
      }
      const createdFile = await createFile(newFile)
      await uploadFile({
        file,
        contentType: file.type,
        putUrl: createdFile.put_url,
      })
      await addFileToPlantList(plantListId, createdFile)
      await processFileWithAI({
        organizationId,
        fileId: createdFile.id,
        plantListId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['plantList', plantListId],
      })
      onFileUpload()
    },
  })

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFileId(event.target.value)
  }

  const handleFileDelete = async () => {
    if (selectedFileId) {
      await deleteMutation.mutateAsync(selectedFileId)
    }
  }

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setError('Only files of the following format are allowed: .jpeg, .jpg, .png, .gif, .webp, .csv, .xlsx, and .pdf.')
      return
    }

    if (isImageFile(file)) {
      const isValidDimension = await checkImageDimensions(file)
      if (!isValidDimension) {
        setError(`Image files must not exceed ${MAX_IMAGE_DIMENSION} pixels in width or height.`)
        return
      }
    }

    // ensure error is cleared
    setError('')
    uploadMutation.mutate(file)
  }

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  const handleZoomToggle = (event: MouseEvent<HTMLDivElement>) => {
    if (!isZoomed) {
      if (imageContainerRef.current) {
        const rect = imageContainerRef.current.getBoundingClientRect()
        const x = ((event.clientX - rect.left) / rect.width) * 100
        const y = ((event.clientY - rect.top) / rect.height) * 100
        setPanPosition({ x, y })
      }
      setZoomLevel(savedZoomLevel)
    } else {
      setPanPosition({ x: 0, y: 0 })
      setZoomLevel(1)
    }

    setIsZoomed(!isZoomed)
  }

  const handleZoomChange = (_event: Event, newValue: number | number[]) => {
    const newZoom = newValue as number
    setZoomLevel(newZoom)
    setIsZoomed(newZoom > 1)
    if (newZoom <= 1) {
      setSavedZoomLevel(2)
      setPanPosition({ x: 0, y: 0 })
    } else {
      setSavedZoomLevel(newZoom)
    }
  }

  const handleZoomIn = () => {
    setZoomLevel((prev) => {
      const newZoom = Math.min(prev + 0.5, 5)
      setSavedZoomLevel(newZoom)
      return newZoom
    })
    setIsZoomed(true)
  }

  const handleZoomOut = () => {
    setZoomLevel((prev) => {
      const newZoom = Math.max(prev - 0.5, 1)
      setIsZoomed(newZoom > 1)
      if (newZoom <= 1) {
        setSavedZoomLevel(2)
        setPanPosition({ x: 0, y: 0 })
      } else {
        setSavedZoomLevel(newZoom)
      }
      return newZoom
    })
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setClickStartTime(Date.now())
    if (isZoomed) {
      setIsDragging(true)
      setDragStart({ x: event.clientX, y: event.clientY })
    }
  }

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    const clickDuration = Date.now() - clickStartTime
    if (clickDuration < 200) {
      handleZoomToggle(event)
    }
    setIsDragging(false)
  }

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (isZoomed && isDragging) {
      const dx = event.clientX - dragStart.x
      const dy = event.clientY - dragStart.y
      setDragStart({ x: event.clientX, y: event.clientY })
      setPanPosition((prev) => ({
        x: prev.x + dx / zoomLevel,
        y: prev.y + dy / zoomLevel,
      }))
    }
  }

  const selectedFileView = (
    <>
      {selectedFile && SPREADSHEET_FILE_TYPES.includes(selectedFile.file_type) && (
        <Box className="max-h-[70vh] overflow-y-scroll">
          {(selectedFile as SpreadsheetFile)?.tables?.map((table, index) => (
            <Box key={index} dangerouslySetInnerHTML={{ __html: table }} id="file-view-container"></Box>
          ))}
        </Box>
      )}
      {selectedFile && IMAGE_FILE_TYPES.includes(selectedFile.file_type) && (
        <>
          <Box
            ref={imageContainerRef}
            sx={{
              position: 'relative',
              overflow: 'hidden',
              height: '75vh',
              width: '100%',
              borderRadius: '0.5rem',
              backgroundColor: '#18181b',
              paddingX: '2',
              cursor: isZoomed ? 'move' : 'zoom-in',
            }}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
          >
            <img
              ref={imageRef}
              src={(selectedFile as ImageFile).url}
              alt={(selectedFile as ImageFile).alt}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                transition: isZoomed ? 'none' : 'transform 0.1s ease-in-out',
                transform: `scale(${zoomLevel}) translate(${-panPosition.x / zoomLevel}%, ${-panPosition.y / zoomLevel}%)`,
                transformOrigin: '0 0',
                pointerEvents: 'none',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button onClick={handleZoomOut} disabled={zoomLevel <= 1}>
              <ZoomOutIcon />
            </Button>
            <Slider
              value={zoomLevel}
              onChange={handleZoomChange}
              min={1}
              max={5}
              step={0.1}
              aria-labelledby="zoom-slider"
              sx={{ flexGrow: 1 }}
            />
            <Button onClick={handleZoomIn} disabled={zoomLevel >= 5}>
              <ZoomInIcon />
            </Button>
            <Typography variant="body2">{`${(zoomLevel * 100).toFixed(0)}%`}</Typography>
          </Box>
        </>
      )}
    </>
  )

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {error && <Alert severity="error">{error}</Alert>}
      <Box display="flex" gap={2}>
        <FormControl>
          <InputLabel id="file-select-label">Select a file</InputLabel>
          <Select
            labelId="file-select-label"
            value={selectedFileId}
            onChange={handleChange}
            label="Select a file"
            sx={{ width: 300 }}
          >
            {files
              .filter((file) => !file.deleted_at)
              .map((file) => (
                <MenuItem key={file.id} value={file.id}>
                  {file.user_file_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="error"
          onClick={handleFileDelete}
          disabled={!selectedFileId || deleteMutation.isPending}
          startIcon={deleteMutation.isPending ? <CircularProgress size={16} /> : <DeleteIcon />}
        >
          Delete File
        </Button>
        <Divider orientation="vertical" flexItem>
          <span className="text-zinc-400">OR</span>
        </Divider>
        <Tooltip title={`Supported file types are: ${ACCEPTED_FILE_EXTENSIONS.join(', ')}`} arrow placement="top">
          <Button
            variant="contained"
            color="primary"
            onClick={triggerFileInput}
            disabled={uploadMutation.isPending}
            startIcon={uploadMutation.isPending ? <CircularProgress size={16} /> : <CloudUploadIcon />}
          >
            Upload File
          </Button>
        </Tooltip>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileUpload}
          accept={ACCEPTED_FILE_TYPES.join(',')}
        />
      </Box>
      {selectedFileView}
    </Box>
  )
}
