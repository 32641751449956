import Button from '@mui/material/Button'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/sentry-test')({
  component: SentryTest,
})

function SentryTest() {
  return (
    <div>
      <h1>Sentry Test</h1>
      <p>This page is used to test Sentry error tracking. Click the button below to send an error to Sentry.</p>
      <p>
        We can hide text through the use of the <code>&apos;.sensitive-data&apos;</code> CSS class.
      </p>
      <p className="sensitive-data">In the replay, this text will be hidden.</p>
      <Button
        variant="contained"
        onClick={() => {
          throw new Error('Sentry Test Error')
        }}
      >
        Throw Error
      </Button>
    </div>
  )
}
