import axios from 'axios'
import { useState } from 'react'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/constants'

type AccountUsernamePasswordResetProps = {
  username: string
}

export default function AccountUsernamePasswordReset({ username }: AccountUsernamePasswordResetProps) {
  const [passwordResetError, setPasswordResetError] = useState<string | null>(null)
  const [passwordResetSuccess, setPasswordResetSuccess] = useState<boolean>(false)
  const handlePasswordReset = async () => {
    try {
      await axios.post(`https://${AUTH0_DOMAIN}/dbconnections/change_password`, {
        client_id: AUTH0_CLIENT_ID,
        email: username,
        connection: 'Username-Password-Authentication',
      })
      setPasswordResetSuccess(true)
    } catch (error) {
      setPasswordResetError('Failed to send password reset email. Please try again later.')
      console.error('error', error)
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="tabSection" sx={{ fontWeight: 500 }}>
            Username
          </Typography>
          <Typography variant="body1">(Not Editable)</Typography>
        </Box>
        <Typography variant="body1">{username}</Typography>
      </Box>
      <Box>
        <Typography variant="tabSection" sx={{ fontWeight: 500 }}>
          Password
        </Typography>
        <Link component="button" variant="body1" onClick={handlePasswordReset}>
          Reset password via email
        </Link>
      </Box>
      {passwordResetError && (
        <Alert severity="error" onClose={() => setPasswordResetError(null)}>
          {passwordResetError}
        </Alert>
      )}
      {passwordResetSuccess && (
        <Alert severity="success" onClose={() => setPasswordResetSuccess(false)}>
          Password reset email sent! Please check your inbox.
        </Alert>
      )}
    </Box>
  )
}
