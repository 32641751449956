import { type ReactNode } from 'react'

import Box from '@mui/material/Box'

interface TabLabelProps {
  label: string
  variant?: 'tabLabel1' | 'tabLabel2'
  icon?: ReactNode
}
export default function TabLabel({ label, icon }: TabLabelProps) {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      {icon}
      {label}
    </Box>
  )
}
