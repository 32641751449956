import { Box, Divider, Typography } from '@mui/material'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { useState, MouseEvent, useEffect } from 'react'
import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import { CirclePlus } from 'lucide-react'
import Grid from '@mui/material/Grid'
import theme from '@/theme.ts'
import { Building2, EllipsisVertical, LogOut, Settings } from 'lucide-react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useMutation } from '@tanstack/react-query'
import { getOrganizations, removeMember } from '@/api/organization_management.ts'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { GCSFile, ImageFile, Organization, OrganizationMember, SnackbarMessage } from '@/types.ts'
import NewOrganizationModal from '@/components/ui/modals/new-organization-modal.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { hasManageOrganizationPermission } from '@/lib/utils.ts'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { useNavigate } from '@tanstack/react-router'
import { DEFAULT_ROUTE } from '@/constants.ts'

interface AxiosError extends Error {
  response?: {
    data?: {
      detail?: string
    }
  }
}

type AccountOrganizationsTabProps = {
  closeModal: () => void
}

const AccountOrganizationsTab = ({ closeModal }: AccountOrganizationsTabProps) => {
  const navigate = useNavigate()
  // const queryClient = useQueryClient()
  const { userInfo, refetchUserInfo } = useUserInfo()
  const [newOrgOpen, setNewOrgOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState<SnackbarMessage | null>(null)
  const { toggleOrganizationModal, setSelectedOrganizationId } = useOrganization()
  const [organizationMembership, setOrganizationMembership] = useState<OrganizationMember[]>([])
  const [managePremission, setManagePermission] = useState<{
    [key: string]: boolean
  }>({})

  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement | null
  }>({})
  const openDropdown = (memberId: string) => Boolean(anchorEl[memberId])

  const [expandedOrganizationDetails, setExpandedOrganizationDetails] = useState<Organization[]>([])

  const { addToastNotification } = useToastNotifications()

  useEffect(() => {
    setOrganizationMembership(userInfo?.user?.organization_members || [])
  }, [userInfo])

  useEffect(() => {
    const fetchOrganizations = async () => {
      const organizations = await getOrganizations()
      const organizationsWithLogo = await Promise.all(
        organizations.map(async (org) => {
          if (org.small_logo) {
            const image = (await fetchGCSFile(org.small_logo as GCSFile)) as ImageFile
            return { ...org, small_logo: image }
          }
          return org
        })
      )
      setExpandedOrganizationDetails(organizationsWithLogo || [])
    }

    fetchOrganizations()
  }, [organizationMembership])

  const OrganizationAvatar = ({ organization_id }: { organization_id: string }) => {
    const organization = expandedOrganizationDetails.find((org) => org.id === (organization_id as string))
    return organization?.small_logo ? (
      <Avatar
        sx={{ width: 30, height: 30 }}
        src={(organization?.small_logo as ImageFile).url as string}
        alt="organization-logo"
      />
    ) : (
      <Avatar sx={{ width: 30, height: 30 }} alt="organization-logo">
        <Building2 />
      </Avatar>
    )
  }

  const handleTooltipOpen = (orgMember: OrganizationMember) => {
    if (!managePremission[orgMember.id]) {
      setTooltipOpen(true)
    }
  }

  const handleDropdownClick = (event: MouseEvent<HTMLElement>, orgMember: OrganizationMember) => {
    setAnchorEl((prev) => ({
      ...prev,
      [orgMember.id]: event.currentTarget,
    }))
    setManagePermission((prev) => ({
      ...prev,
      [orgMember.id]: hasManageOrganizationPermission(orgMember),
    }))
  }

  const handleDropdownClose = (memberId: string) => {
    setAnchorEl((prev) => ({
      ...prev,
      [memberId]: null,
    }))
    setManagePermission((prev) => ({
      ...prev,
      [memberId]: false,
    }))
  }

  const navigateToRoute = async ({ route }: { route: string; delay?: number }) => {
    if (!route) {
      console.error('Route is required for navigation')
      return null
    }
    await navigate({ to: route })
  }

  const switchOrganization = async (organization: Organization) => {
    addToastNotification({
      message: `You have switched organization to - ${organization.name}`,
      title: 'Success',
      severity: 'success',
    })
    closeModal()
    setSelectedOrganizationId(organization.id)
    // queryClient.invalidateQueries({ queryKey: ['selected-organization'] })
    navigateToRoute({ route: DEFAULT_ROUTE })
  }

  const navigateToOrganization = (orgMember: OrganizationMember) => {
    if (!orgMember?.organization) {
      console.error('Organization data missing for navigation')
      return null
    }
    switchOrganization(orgMember.organization)
  }

  const handleOrganizationSettings = (orgMember: OrganizationMember) => {
    setAnchorEl({})
    if (orgMember?.organization && hasManageOrganizationPermission(orgMember)) {
      switchOrganization(orgMember.organization)
      toggleOrganizationModal(true)
    } else {
      addToastNotification({
        severity: 'error',
        message: 'Insufficient permission to access organization settings',
      })
    }
  }

  const removeOrganizationMemberMutation = useMutation({
    mutationFn: async ({ member_id }: { member_id: string; organization: string }) => {
      return await removeMember(member_id)
    },
    onSuccess: async (_, variables) => {
      addToastNotification({
        severity: 'success',
        title: 'Success',
        message: `You have left the organization - ${variables.organization}`,
      })
    },
    onError: async (error: AxiosError, variables) => {
      addToastNotification({
        severity: 'error',
        title: `Error leaving organization - ${variables.organization}`,
        message:
          error.response?.data?.detail || error.message || `Error leaving organization - ${variables.organization}`,
      })
    },
  })

  const handleOrganizationCreated = async () => {
    await refetchUserInfo()
  }

  const handleLeaveOrganization = async (orgMember: OrganizationMember) => {
    setAnchorEl({})
    await removeOrganizationMemberMutation.mutateAsync({
      member_id: orgMember.id,
      organization: orgMember.organization.name,
    })
  }

  return (
    <>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography variant="tabHeader">Organizations</Typography>
        <Box>
          <DarkPrimaryButton onClick={() => setNewOrgOpen(true)}>
            <Box display="flex" alignItems="center" gap={1}>
              <CirclePlus size={20} />
              <Typography variant="button">Create New Org</Typography>
            </Box>
          </DarkPrimaryButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 5,
        }}
      >
        <Typography variant="body1">You own or have been added to the organizations listed below.</Typography>
        <Typography variant="body1">
          To join an <Typography variant="strong">existing organization</Typography>, contact their admin and request an
          invitation.
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {/* header */}
        <Grid item xs={12} container sx={{ borderBottom: '1px solid #ccc' }}>
          <Grid item xs={8}>
            <Typography
              variant="tableColumnHeader"
              sx={{
                color: theme.palette.grey[500],
              }}
            >
              ORG NAME
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="tableColumnHeader"
              sx={{
                color: theme.palette.grey[500],
              }}
            >
              ROLE
            </Typography>
          </Grid>
        </Grid>

        {/* org list */}
        {organizationMembership?.map((org_member: any, index: any) => (
          <Grid
            key={`account-org-member-${index}`}
            item
            xs={12}
            container
            sx={{
              borderBottom: '1px solid #ccc',
              paddingBottom: '1em',
              '&:hover': { backgroundColor: '#e0e0e0' },
            }}
          >
            <Grid item xs={8} sx={{ cursor: 'pointer' }} onClick={() => navigateToOrganization(org_member)}>
              <Box sx={{ display: 'flex', gap: '1em' }}>
                <OrganizationAvatar key={`org-logo-${org_member.id}`} organization_id={org_member?.organization?.id} />
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    alignSelf: 'center',
                    '&:hover': {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {org_member?.organization?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              alignContent="center"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigateToOrganization(org_member)}
            >
              {org_member?.roles?.map((role: any, index: any) => (
                <Typography key={`member-${org_member.id}-${index}`} variant="body1">
                  {role?.name}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={1}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  sx={{
                    marginRight: '1em',
                    padding: '4px 2px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                  }}
                  onClick={(e) => handleDropdownClick(e, org_member)}
                >
                  <EllipsisVertical />
                </IconButton>
              </Box>
              <Menu
                key={`account-pref-org-tab-${org_member.id}`}
                anchorEl={anchorEl[org_member.id]}
                open={openDropdown(org_member.id)}
                onClose={() => handleDropdownClose(org_member.id)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                slotProps={{
                  paper: {
                    sx: {
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)', // Lighter shadow
                    },
                  },
                }}
              >
                <Tooltip
                  title="You don't have permission to view this organization's settings."
                  open={tooltipOpen}
                  onOpen={() => handleTooltipOpen(org_member)}
                  onClose={() => setTooltipOpen(false)}
                  placement="left"
                  arrow
                >
                  <MenuItem
                    sx={{
                      cursor: 'pointer',
                      margin: '0.5em',
                      borderRadius: '5px',
                    }}
                    key={`account-pref-org-tab-item-${org_member.id}`}
                    disabled={!managePremission[org_member.id]}
                    onClick={() => handleOrganizationSettings(org_member)}
                  >
                    <ListItemIcon>
                      <Settings size={20} />
                    </ListItemIcon>
                    <Typography variant="body1">Organization Settings</Typography>
                  </MenuItem>
                </Tooltip>

                <Divider />

                <MenuItem
                  sx={{
                    cursor: 'pointer',
                    margin: '0.5em',
                    borderRadius: '5px',
                  }}
                  onClick={() => handleLeaveOrganization(org_member)}
                >
                  <ListItemIcon>
                    <LogOut size={20} />
                  </ListItemIcon>
                  <Typography variant="body1">Leave Organization</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <NewOrganizationModal
        open={newOrgOpen}
        onClose={() => setNewOrgOpen(false)}
        onOrganizationCreated={handleOrganizationCreated}
      />

      <Snackbar
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={() => setAlertMessage(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ marginRight: '2em' }}
      >
        <Alert onClose={() => setAlertMessage(null)} severity={alertMessage?.severity || 'info'} sx={{ width: '100%' }}>
          {alertMessage?.message || ''}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AccountOrganizationsTab
