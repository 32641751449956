import { Alert, AlertTitle, Box, Divider, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import { ZodError } from 'zod'

// TODO: don't display in production
/**
 * Displays a ZodError object in a styled box
 * @param error - The ZodError object to display
 * @returns A component that displays the ZodError object
 */
export const ZodErrorMessage = ({ error, title = 'data' }: { error: ZodError; title?: string }) => {
  if (!error || !error.errors || error.errors.length === 0) {
    return null
  }

  return (
    <Paper
      elevation={0}
      sx={{
        border: 1,
        borderColor: 'error.light',
        bgcolor: 'error.lighter',
        m: 4,
        height: '80vh',
        overflow: 'scroll',
      }}
    >
      <Alert severity="error">
        <AlertTitle>Validation Errors for {title}</AlertTitle>
        <List sx={{ pt: 0, pb: 0 }}>
          {error.errors.map((err, index) => (
            <Box key={index}>
              <ListItem sx={{ px: 0 }}>
                <ListItemText
                  primary={
                    <Typography component="div" variant="body2" sx={{ fontWeight: 'medium' }}>
                      {err.path.length > 0 ? err.path.join(' → ') : 'Value'}
                    </Typography>
                  }
                  secondary={
                    <Typography component="div" variant="body2" color="error" sx={{ mt: 0.5 }}>
                      {err.message}
                    </Typography>
                  }
                />
              </ListItem>
              {index < error.errors.length - 1 && <Divider sx={{ opacity: 0.5 }} />}
            </Box>
          ))}
        </List>
      </Alert>
    </Paper>
  )
}
