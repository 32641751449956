import Typography from '@mui/material/Typography'

export const DetailsSectionText = ({ text, bold }: { text: string | undefined; bold?: boolean }) => {
  const fontWeight = bold ? 700 : 400
  return (
    <Typography variant="body2" fontWeight={fontWeight}>
      {text}
    </Typography>
  )
}
