import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ClearTextIconButtonGroup } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { ReactNode } from 'react'
import theme from '@/theme.ts'

export type RFPPageTitleAction = {
  text: string
  onClick: () => void
  startIcon?: ReactNode
}

interface RFPPageTitleProps {
  title: string
  actionItems: RFPPageTitleAction[]
}

export const RfpPageTitle = ({ title, actionItems }: RFPPageTitleProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        p: 3.75,
        pb: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" fontWeight={400}>
        {title}
      </Typography>
      <Stack
        direction="row"
        sx={{
          backgroundColor: 'white',
          borderRadius: theme.borderRadius.md,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        }}
      >
        <ClearTextIconButtonGroup items={actionItems} />
      </Stack>
    </Stack>
  )
}
