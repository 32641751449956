import { excludeDeletedItems } from '@/lib/utils.ts'
import Typography from '@mui/material/Typography'
import { PlantListEntry } from '@/types.ts'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/material'

const defaultTemplates = {
  'rfp-plants': (plantCount: number, itemCount: number) =>
    `Request Pricing for ${plantCount} Plants (${itemCount} items)`,
  'vendor-panel': (plantCount: number, itemCount: number) => `Requesting ${plantCount} Plants (${itemCount} items)`,
}

interface RequestPricingHeaderProps {
  /**
   * The list of plants to show request header for
   */
  plants: PlantListEntry[]
  /**
   * The default title to display if no plants are provided or if all plants are deleted
   * @default "Request Pricing for Plants"
   */
  defaultTitle?: string
  /**
   * Optional custom template function to format the title text
   * @param plantCount - The number of plants
   * @param itemCount - The total number of items
   * @returns The formatted title text to display in the header
   */
  template?: (plantCount: number, itemCount: number) => string
  /**
   * Pre-defined header variants for common use cases
   * - 'rfp-plants': "Request Pricing for X Plants (Y items)"
   * - 'vendor-panel': "Requesting X Plants (Y items)"
   * @default 'rfp-plants'
   */
  variant?: 'rfp-plants' | 'vendor-panel'
  /**
   * Optional custom styles to apply to the header
   */
  sx?: SxProps<Theme>
}

/**
 * A component that displays a header with the title of the request pricing for the given plants
 * @param plants - The list of plants to show request header for
 * @param template - Optional custom template function to format the title text
 * @param variant - Pre-defined header variants for common use cases
 * @param defaultTitle - The default title to display if no plants are provided or if all plants are deleted
 * @param sx - Optional custom styles to apply to the header
 * @returns The header component
 *
 * @example
 * // Basic usage with default variant
 * <RequestPricingHeader plants={plants} />
 *
 * @example
 * // Using the 'vendor-panel' variant (for vendor panel)
 * <RequestPricingHeader plants={plants} variant="vendor-panel" />
 *
 * @example
 * // Using a custom template function
 * <RequestPricingHeader plants={plants} template={(plantCount, itemCount) => `Custom template with ${plantCount} Plants and ${itemCount} items`} />
 */
export default function RequestPricingHeader({
  plants,
  template,
  variant = 'rfp-plants',
  defaultTitle = 'Request Pricing for Plants',
  sx,
}: RequestPricingHeaderProps) {
  const activePlants = excludeDeletedItems(plants)
  const plantCount = activePlants.length
  const itemCount = activePlants.reduce(
    (acc: number, plant: any) => acc + (plant.quantity_count?.min || plant.quantity_count?.max || 0),
    0
  )

  const templateFunction = template || defaultTemplates[variant]
  const title = plantCount > 0 ? templateFunction(plantCount, itemCount) : defaultTitle

  return (
    <Typography variant="body1" sx={{ ...sx }}>
      {title}
    </Typography>
  )
}
