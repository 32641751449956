import { useQuery } from '@tanstack/react-query'
import { Fragment, useState, useRef, type MouseEvent, Dispatch, SetStateAction, useEffect } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { CirclePlus, Leaf, Plus } from 'lucide-react'

import { getContacts } from '@/api/contacts.ts'
import SearchBar from '@/components/ui/base/search-bar.tsx'
import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import { ContactTypes } from '@/constants.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import type { ElasticsearchResponse, OrganizationContact } from '@/types.ts'

import rfpPlantListImage from '@/assets/rfp-plant-list.png'
import theme from '@/theme.ts'
import VendorGroups from './vendor-groups'

export default function VendorPanel({
  sentRfps,
  onContactSelect,
  setSentRfp,
  sentRfpId,
}: {
  sentRfps: any[]
  onContactSelect: (contact: OrganizationContact) => void
  setSentRfp: Dispatch<SetStateAction<any>>
  sentRfpId?: string
}) {
  const { selectedOrganization } = useOrganization()
  const { data } = useQuery({
    queryKey: ['vendors', selectedOrganization?.id],
    queryFn: async () => {
      return getContacts(ContactTypes.ORGANIZATION, selectedOrganization?.id)
    },
    enabled: !!selectedOrganization,
  })
  const [organizationContactSearchResults, setOrganizationContactSearchResults] =
    useState<ElasticsearchResponse<OrganizationContact> | null>(null)
  const [showPlantbidModal, setShowPlantbidModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [selectedSentRfpId, setSelectedSentRfpId] = useState<string>()

  useEffect(() => {
    setSelectedSentRfpId(sentRfpId)
  }, [sentRfpId])

  const handlePlantbidHover = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    clearTimeout(timeoutRef.current)
    setShowPlantbidModal(true)
  }

  const handlePlantbidLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowPlantbidModal(false)
    }, 300) // 300ms grace period to move to modal
  }

  const handleVendorClick = (sentRfp: any) => {
    setSelectedSentRfpId(sentRfp.id)
    setSentRfp(sentRfp)
  }

  return (
    <Stack spacing={2.5}>
      <Typography variant="h3" fontWeight={400}>
        Send RFP to
      </Typography>

      <VendorGroups sentRfps={sentRfps} selectedVendorId={selectedSentRfpId} onVendorClick={handleVendorClick} />

      <SearchBar
        placeholder="Search..."
        searchUrl={`/v1/core/contact/${ContactTypes.ORGANIZATION}/search/`}
        perPage={10}
        page={1}
        requiredFields={['organization_name']}
        onSearchResults={(response) => {
          setOrganizationContactSearchResults(response as ElasticsearchResponse<OrganizationContact>)
        }}
      />
      <Divider sx={{ borderBottomWidth: 3 }} />

      {/* Plantbid Special Contact */}
      <Box
        onMouseEnter={handlePlantbidHover}
        onMouseLeave={handlePlantbidLeave}
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'start',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Box
          sx={{
            color: 'white',
            bgcolor: '#708391',
            borderRadius: theme.borderRadius.circle,
            p: 0.25,
          }}
        >
          <Plus strokeWidth={3} size={18} />
        </Box>
        <Box>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography variant="body1" fontWeight={600}>
              Plantbid
            </Typography>
            <Leaf size={20} strokeWidth={2.5} />
          </Stack>
          <Typography variant="caption" color="text.primary">
            <Typography variant="strong" sx={{ fontSize: 'inherit' }}>
              FREE!
            </Typography>{' '}
            Get competitive pricing from the team at Plantbid at no cost to you.
          </Typography>
        </Box>
      </Box>
      <Divider />

      {/* Plantbid Modal */}
      <Popper
        open={showPlantbidModal}
        anchorEl={anchorEl}
        placement="right"
        transition
        onMouseEnter={() => clearTimeout(timeoutRef.current)}
        onMouseLeave={() => setShowPlantbidModal(false)}
        sx={{ zIndex: 9999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box
              sx={{
                bgcolor: 'background.paper',
                p: 3,
                borderRadius: 1,
                boxShadow: 3,
                maxWidth: 600,
                ml: 2,
              }}
            >
              <Box
                component="img"
                src={rfpPlantListImage}
                alt="add_vendor_to_rfp_modal_illustration"
                sx={{
                  width: '100%',
                }}
              />
              <Typography variant="h3" gutterBottom>
                Quotes from Plantbid
              </Typography>
              <Typography variant="body2" gutterBottom>
                <Typography variant="strong">How it works:</Typography> Add us to your RFP and we'll provide you with a
                free quote.
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mt: 2, alignItems: 'center' }}>
                <DarkPrimaryButton>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <CirclePlus strokeWidth={2} />
                    <Typography variant="strong">Get a Quote from Plantbid</Typography>
                  </Stack>
                </DarkPrimaryButton>
                <Typography variant="strong" component="p">
                  Free!
                </Typography>
              </Stack>
            </Box>
          </Fade>
        )}
      </Popper>

      {(organizationContactSearchResults?.items || data)?.map((contact) => (
        <Fragment key={contact.id}>
          <Box
            onClick={() => onContactSelect(contact as OrganizationContact)}
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer',
                fontWeight: 700,
              },
            }}
          >
            <Box
              sx={{
                color: 'white',
                bgcolor: '#708391',
                borderRadius: theme.borderRadius.circle,
                p: 0.25,
              }}
            >
              <Plus strokeWidth={3} size={18} />
            </Box>
            <Typography variant="body1" sx={{ fontWeight: 'inherit' }}>
              {contact.organization_name}
            </Typography>
          </Box>
          <Divider />
        </Fragment>
      ))}
    </Stack>
  )
}
