import Button from '@mui/material/Button'
import { type ReactNode, useMemo } from 'react'
import { Divider, Stack } from '@mui/material'
import theme from '@/theme.ts'
import { SxProps, Theme } from '@mui/material/styles'

interface StyledButtonProps {
  onClick: (e: any) => void
  startIcon?: ReactNode
  endIcon?: ReactNode
  text: string
  variant?: 'outlined' | 'contained' | 'text'
  sx?: SxProps<Theme>
  color?: string
  bgColor?: string
  borderColor?: string
}

// use themes when refactoring
const BUTTON_COLORS = {
  text: '#181818',
  background: 'white',
  border: '#E0E0E0',
} as const

// shared styles for buttons
const BUTTON_STYLES = {
  base: {
    p: 1,
  },
  group: {
    default: {
      borderRadius: theme.borderRadius.md,
      px: 2,
    },
    middle: {
      borderRadius: 0,
      px: 2,
    },
    first: {
      borderRadius: 0,
      px: 2,
      borderTopLeftRadius: theme.borderRadius.md,
      borderBottomLeftRadius: theme.borderRadius.md,
    },
    last: {
      borderRadius: 0,
      px: 2,
      borderTopRightRadius: theme.borderRadius.md,
      borderBottomRightRadius: theme.borderRadius.md,
    },
  },
} as const

export function TextIconButton({
  onClick,
  startIcon,
  endIcon,
  text,
  variant = 'outlined',
  sx = {},
  color = BUTTON_COLORS.text,
  bgColor = BUTTON_COLORS.background,
  borderColor = BUTTON_COLORS.border,
}: StyledButtonProps) {
  const buttonStyles = useMemo(
    () => ({
      ...BUTTON_STYLES.base,
      backgroundColor: bgColor,
      borderColor,
      color,
      '&:hover': {
        backgroundColor: bgColor,
        borderColor,
      },
      ...sx,
    }),
    [bgColor, borderColor, color, sx]
  )

  return (
    <Button onClick={onClick} startIcon={startIcon} endIcon={endIcon} variant={variant} sx={buttonStyles}>
      {text}
    </Button>
  )
}

// single clear button with text and icon
export function ClearTextIconButton({ onClick, startIcon, text, sx = {}, variant = 'text' }: StyledButtonProps) {
  return (
    <TextIconButton
      onClick={onClick}
      startIcon={startIcon}
      text={text}
      variant={variant}
      bgColor="transparent"
      sx={sx}
    />
  )
}

// group of clear buttons with text and icon
// this type of button is only seen in rfp section so styling is generalized for rfp section
// as we see more variations of this button, we can add more styles to make it more flexible
export function ClearTextIconButtonGroup({ items }: { items: StyledButtonProps[] }) {
  if (items.length === 1) {
    return <ClearTextIconButton {...items[0]} sx={BUTTON_STYLES.group.default} variant="outlined" />
  }

  return (
    <Stack
      direction="row"
      sx={{
        borderRadius: theme.borderRadius.md,
        borderColor: BUTTON_COLORS.border,
        borderWidth: '1px',
      }}
      divider={<Divider sx={{ borderColor: BUTTON_COLORS.border }} orientation="vertical" />}
    >
      {items.map((item, index) => {
        const itemStyle =
          index === 0
            ? BUTTON_STYLES.group.first
            : index === items.length - 1
              ? BUTTON_STYLES.group.last
              : BUTTON_STYLES.group.middle

        return <ClearTextIconButton key={item.text || index} {...item} sx={itemStyle} />
      })}
    </Stack>
  )
}
