import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'

import { transferOwnership } from '@/api/organization_management.ts'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import type { OrganizationMember } from '@/types.ts'

type TransferOwnershipModalProps = {
  open: boolean
  onClose: () => void
  onOwnershipTransferred: () => void
  members: OrganizationMember[]
  organization_id: string
}
export default function TransferOwnershipModal({
  open,
  onClose,
  onOwnershipTransferred,
  members,
  organization_id,
}: TransferOwnershipModalProps) {
  const { refetchUserInfo } = useUserInfo()
  const [selectedMemberId, setSelectedMemberId] = useState<string>('')

  const transferOwnershipMutation = useMutation({
    mutationFn: transferOwnership,
    onSuccess: async () => {
      await refetchUserInfo()
      handleClose()
      onOwnershipTransferred()
    },
  })

  const handleTransferOwnership = async () => {
    await transferOwnershipMutation.mutateAsync({
      organization_id,
      user_id: selectedMemberId,
    })
  }

  const handleClose = () => {
    setSelectedMemberId('')
    onClose()
  }

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedMemberId(event.target.value)
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h3" gutterBottom>
          Transfer Ownership
        </Typography>
        <Alert severity="error">
          <Typography>
            This action will transfer ownership of this organization to another member. This action{' '}
            <Box component="span" fontWeight="bold">
              cannot
            </Box>{' '}
            be undone.
          </Typography>
        </Alert>
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="select-member-label">Select member</InputLabel>
          <Select
            labelId="select-member-label"
            id="select-member"
            value={selectedMemberId}
            label="Select member"
            onChange={handleChange}
          >
            {members
              .filter((m) => !m.roles.some((r) => r.name === 'Owner'))
              .map((member) => (
                <MenuItem key={member.id} value={member.user.id}>
                  {member.user.email}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box display="flex" justifyContent="end" gap={2}>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            <Typography variant="button" textTransform="capitalize">
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={!selectedMemberId || transferOwnershipMutation.isPending}
            onClick={handleTransferOwnership}
          >
            <Typography variant="button" textTransform="capitalize">
              Transfer Ownership
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
