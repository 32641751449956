import { Typography, Stack } from '@mui/material'
import Paper from '@mui/material/Paper'
import { OrganizationLogo } from '@/components/ui/organization-logo.tsx'
import { OrganizationDetailsProps } from '@/types.ts'
import { getOrganizationCardDetails } from '@/lib/utils.ts'
import { DomesticAddress } from '@/components/ui/base/domestic-address.tsx'

export const OrganizationDetails = ({ organization, displayLogo = true }: OrganizationDetailsProps) => {
  if (!organization) {
    return null
  }

  const orgDetails = getOrganizationCardDetails(organization)

  return (
    <Paper elevation={0} sx={{ display: 'flex', gap: '20px' }}>
      {displayLogo && (
        <Stack>
          <OrganizationLogo logoFile={orgDetails.small_logo} size={60} />
        </Stack>
      )}

      <Stack>
        <Typography variant="h2" gutterBottom sx={{ fontWeight: 500 }}>
          {orgDetails.name}
        </Typography>

        <DomesticAddress address={orgDetails.address} />

        <Typography color="text.secondary">Phone: {orgDetails.phone || 'n/a'}</Typography>

        <Typography color="text.secondary">Email: {orgDetails.email || 'n/a'}</Typography>
      </Stack>
    </Paper>
  )
}
