import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useState } from 'react'
import { Download } from 'lucide-react'
import ControlPanel from '@/components/ui/base/control-panel'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import type { ElasticsearchResponse } from '@/types'
import Typography from '@mui/material/Typography'
import { fallback, zodValidator } from '@tanstack/zod-adapter'

import { Container } from '@mui/material'
import { z, ZodError } from 'zod'
import { useRFPIndex } from '@/hooks/rfps/useRFPIndex'
import { EmptyStateMessage, LoadingSkeleton } from '@/components/rfps/index/component-states'
import { RFPTable } from '@/components/rfps/index/rfp-vendor-table'
import { ZodErrorMessage } from '@/components/util/zod-error'
import { RFPIndexEnum, RFPIndexTypes } from '@/api/types/rfps'

export const Route = createFileRoute('/_authenticated/rfps/')({
  component: RFPSIndex,
  validateSearch: zodValidator(
    z.object({
      type: fallback(RFPIndexEnum, 'sent').default('sent'),
    })
  ),
})

function RFPSIndex() {
  const { type } = Route.useSearch()
  const [_searchResults, setSearchResults] = useState<ElasticsearchResponse<any> | null>(null)
  const navigate = useNavigate({ from: Route.fullPath })
  const { data: rfpData, isPending, isError, error } = useRFPIndex({ type })

  if (isError) {
    if (error instanceof ZodError) {
      return (
        <Container>
          <ZodErrorMessage error={error} title="RFP Index" />
        </Container>
      )
    }
    return (
      <Container>
        <Typography variant="h2" color="error" fontWeight="bold">
          Error: {error.message}
        </Typography>
      </Container>
    )
  }

  return (
    <PageLayout title="RFPs" icon={<Download size={36} />} globalNewButton>
      <ControlPanel
        tabConfig={{
          tabs: [
            { label: 'Sent RFPs', value: 'sent' },
            { label: 'Received RFPs', value: 'received' },
          ],
          activeTab: type,
          onTabChange: (tab) => {
            navigate({
              search: {
                type: tab as RFPIndexTypes,
              },
            })
          },
        }}
        searchConfig={{
          placeholder: 'Search RFPs...',
          searchUrl: '/v1/core/rfps/search/',
          onSearchResults: (response) => setSearchResults(response as ElasticsearchResponse<any>),
        }}
      />

      {isPending ? (
        <LoadingSkeleton />
      ) : rfpData.length === 0 ? (
        <EmptyStateMessage type={type} />
      ) : (
        <RFPTable rfpData={rfpData} type={type} />
      )}
    </PageLayout>
  )
}
