import { Stack, Typography } from '@mui/material'
import { BaseAddress } from '@/types.ts'

export const DomesticAddress = ({ address }: { address: Partial<BaseAddress> }) => {
  const addressLine1 = address.street
  const addressLine2 = [address.city, address.state, address.zip].filter(Boolean).join(', ')

  return (
    (addressLine1 || addressLine2) && (
      <Stack>
        {addressLine1 && (
          <Typography color="text.secondary" variant="body1">
            {addressLine1}
          </Typography>
        )}
        {addressLine2 && (
          <Typography color="text.secondary" variant="body1">
            {addressLine2}
          </Typography>
        )}
      </Stack>
    )
  )
}
