import { Box, Collapse, IconButton, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { ChevronRight, DotIcon, MountainSnow } from 'lucide-react'
import { formatStringToShortMonthDayYear as formatDate, formatDateTime as formatDateTime } from '@/lib/utils'
import { Fragment, useState } from 'react'
import { Link } from '@tanstack/react-router'
import { RFPContactIndex, type RFP, type RFPIndexTypes } from '@/api/types/rfps'
import { Table } from '@mui/material'
import { TableContainer } from '@mui/material'
import theme from '@/theme'

export function RFPTable({ rfpData, type }: { rfpData: RFPContactIndex; type: RFPIndexTypes }) {
  return (
    <TableContainer>
      <Table>
        <TableBody
          sx={{
            borderTop: '1px solid #8B97A0',
          }}
        >
          {rfpData.map((vendor) => (
            <ToggleRow key={vendor._id} item={vendor} type={type} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function ToggleRow({ item, type }: { item: RFPContactIndex[number]; type: RFPIndexTypes }) {
  // local boolean flip, no need for global map
  const [open, setOpen] = useState(false)
  const title = () => {
    if (!item.vendor && !item.customer) {
      return 'Drafts'
    }
    if (item.vendor) {
      return item.vendor.contact_organization.name
    }
    return item.customer.organization_name
  }
  const count = item.jobs.filter((job) => job.rfps.length).length
  const tableHeader = [
    'Name',
    'Status',
    // 'Items Quoted', // TODO: add when available from backend
    'Sent',
    'Quote Due',
    'Job Date',
  ]

  const viewedCount = item.jobs.reduce(
    (acc, job) =>
      acc +
      job.rfps.filter((rfp) => {
        const key: keyof typeof rfp = type === 'sent' ? 'last_viewed_by_customer' : 'last_viewed_by_vendor'
        return rfp[key]
      }).length,
    0
  )
  const quotedCount = item.jobs.reduce((acc, job) => acc + job.rfps.filter((rfp) => rfp.status === 'quoted').length, 0)

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer',
          backgroundColor: title() === 'Drafts' ? '#FFFFEE' : undefined,
          '&:hover': { backgroundColor: '#FFFFEE' },
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell width={48} padding="none">
          <IconButton
            size="small"
            sx={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: `transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
            }}
          >
            <ChevronRight size={20} />
          </IconButton>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', width: '30%' }}>
          <Typography variant="indexAccordionHeader">
            {title()} ({count})
          </Typography>
        </TableCell>
        <TableCell sx={{ width: '70%', pl: 0, transition: 'text-align 0.3s ease' }}>
          <Typography variant="subtitle2">
            {title() === 'Drafts' ? (
              `${count} Drafts Saved`
            ) : (
              <div>
                <span>{viewedCount} Viewed</span>
                <span style={{ padding: '0 8px', display: 'inline-block' }}>•</span>
                <span>{quotedCount} Quoted</span>
              </div>
            )}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell padding="none" colSpan={3}>
          <Collapse in={open}>
            <Box>
              <Table
                size="small"
                sx={{
                  borderTop: 'none',
                  tableLayout: 'fixed',
                }}
              >
                <TableHead
                  sx={{
                    backgroundColor: '#F3F6F9',
                    borderBottom: '1px solid rgba(186, 210, 228, 0.698)',
                  }}
                >
                  <TableRow>
                    <TableCell width="30%">{tableHeader[0]}</TableCell>
                    <TableCell width="30%">{tableHeader[1]}</TableCell>
                    <TableCell width="13%">{tableHeader[2]}</TableCell>
                    <TableCell width="13%">{tableHeader[3]}</TableCell>
                    <TableCell width="14%">{tableHeader[4]}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.jobs.map((job) => {
                    if (!job.rfps.length) return null
                    return (
                      <Fragment key={job.job_id}>
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            sx={{
                              padding: '20px 10px 5px 15px',
                              borderBottom: '1px solid #8B97A0',
                            }}
                          >
                            <Typography variant="indexGroupHeader" display={'flex'} alignItems={'center'} gap={1}>
                              <MountainSnow size={16} strokeWidth={1} />
                              {job.job_name} - #{job.job_id} {/* TODO: job will have a customer_job_id */}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {job.rfps.map((rfp) => (
                          <RFPRow key={rfp.rfp_id} rfp={rfp} type={type} />
                        ))}
                      </Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export function RFPRow({ rfp, type }: { rfp: RFP; type: RFPIndexTypes }) {
  const sent = rfp.sent_at ? formatDate(rfp.sent_at) : ''
  const quoteDue = rfp.quote_needed_by ? formatDate(rfp.quote_needed_by) : ''
  const jobDate = rfp.delivery_date ? formatDate(rfp.delivery_date) : ''

  const isVendor = type === 'received'
  const dot = (color: 'success' | 'error' | 'info' = 'info') => (
    <DotIcon
      size={16}
      strokeWidth={8}
      color={color === 'success' ? '#26A482' : color === 'error' ? '#FF0000' : '#DB9D00'}
    />
  )
  const fallback = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {dot('info')}
      Updated - {formatDateTime(rfp.updated_at || (rfp.created_at as string))}
    </div>
  )

  const status = () => {
    switch (rfp.status) {
      case 'pending':
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {dot()}
            Pending
          </div>
        )
      case 'sent':
        if (isVendor) {
          if (rfp.last_viewed_by_customer) {
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {dot()}
                Viewed - {formatDateTime(rfp.last_viewed_by_customer)}
              </div>
            )
          }
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {dot()}
              Sent - {rfp.sent_at ? formatDateTime(rfp.sent_at) : ''}
            </div>
          )
        }
        // Customer
        if (rfp.last_viewed_by_vendor) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {dot()}
              Viewed - {formatDateTime(rfp.last_viewed_by_vendor)}
            </div>
          )
        }
        return fallback
      case 'quoted':
        if (isVendor) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {dot()}
              Quote Sent - {formatDateTime(rfp.last_status_change)}
            </div>
          )
        }
        // Customer
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {dot()}
            Quote Received - {formatDateTime(rfp.last_status_change)}
          </div>
        )
      default:
        return fallback
    }
  }

  return (
    <TableRow key={rfp.rfp_id}>
      <TableCell
        sx={{
          padding: '15px 10px 15px 42px',
        }}
      >
        <Typography variant="indexTableItem">
          {/* TODO: verify url is correct */}
          <Link
            to={`/rfps/draft/$rfpId`}
            params={{ rfpId: rfp.rfp_id }}
            search={{
              step: 'build-rfp',
            }}
          >
            {rfp.name} - #{rfp.customer_rfp_id}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>{status()}</TableCell>
      {/* <TableCell>x of y</TableCell> */}
      <TableCell>{sent}</TableCell>
      <TableCell>{quoteDue}</TableCell>
      <TableCell>{jobDate}</TableCell>
    </TableRow>
  )
}
