import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { getRfp, updateRfp } from '@/api/rfps.ts'
import StepLayout, { StepConfig } from '@/components/ui/layouts/step-layout'
import BuildRFPStep from '@/components/rfps/draft/steps/build-rfp-step.tsx'
import AddVendorsStep from '@/components/rfps/draft/steps/add-vendors-step.tsx'
import ReviewAndSendStep from '@/components/rfps/draft/steps/review-and-send-step.tsx'
import { createElement, useCallback, useState, useEffect } from 'react'
import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'
import { useDebouncedCallback } from 'use-debounce'
import * as Sentry from '@sentry/react'
import { RFPStepProps } from '@/components/rfps/types.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import { getOrganizationById } from '@/api/organization_management'

export const Route = createFileRoute('/_authenticated/rfps/draft/$rfpId')({
  component: DraftRFPPage,
  validateSearch: (search: Record<string, string>) => {
    return {
      step: search.step || 'build-rfp',
    }
  },
})

const RFP_STEPS: StepConfig<RFPStepProps>[] = [
  {
    key: 'build-rfp',
    label: 'Build RFP',
    component: BuildRFPStep,
    canProgress: () => true, // TODO: Implement
  },
  {
    key: 'add-vendors',
    label: 'Add Vendors',
    component: AddVendorsStep,
    canProgress: () => true, // TODO: Implement
  },
  {
    key: 'review-and-send',
    label: 'Review & Send',
    component: ReviewAndSendStep,
  },
]

function DraftRFPPage() {
  const { rfpId } = Route.useParams()
  const { step } = Route.useSearch()
  const navigate = useNavigate({ from: Route.fullPath })
  const queryClient = useQueryClient()
  const { selectedOrganization, setSelectedOrganizationId } = useOrganization()
  const { data: initialRfpData } = useQuery({
    queryKey: ['draft_rfp', rfpId],
    queryFn: async () => {
      const rfp = await getRfp(rfpId)
      // Selects the correct organization if it's not already selected
      if (rfp.organization.id !== selectedOrganization?.id) {
        const rfpOrgId = rfp.organization.id
        setSelectedOrganizationId(rfpOrgId)

        const org = await queryClient.fetchQuery({
          queryKey: ['rfp-selected-org', rfpOrgId],
          queryFn: () => getOrganizationById(rfpOrgId),
        })

        if (!org) {
          navigate({ to: '/rfps' })
          throw new Error('Organization not found')
        }
      }
      return rfp
    },
    enabled: !!selectedOrganization,
    refetchOnWindowFocus: false,
  })

  const [rfpData, setRfpData] = useState(initialRfpData)
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'error' | 'none'>('none')

  useEffect(() => {
    if (initialRfpData) {
      setRfpData(initialRfpData)
    }
  }, [initialRfpData])

  const updateDraftMutation = useMutation({
    mutationFn: ({ rfpId, data }: { rfpId: string; data: any }) => updateRfp(rfpId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['draft_rfp', rfpId] })
    },
  })

  const debouncedSave = useDebouncedCallback(async (data: any) => {
    setSaveStatus('saving')
    try {
      await updateDraftMutation.mutateAsync({
        rfpId,
        data,
      })
      setSaveStatus('saved')
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'auto_save_rfp',
          rfpId,
        },
      })
      setSaveStatus('error')
    }
  }, 1000)

  const handleUpdateRfpData = useCallback(
    (updates: Partial<typeof rfpData>) => {
      const newData = {
        ...rfpData,
        ...updates,
      }
      setRfpData(newData)
      debouncedSave(newData)
    },
    [rfpData, debouncedSave]
  )

  const handleSaveRfpDraft = async () => {
    if (!rfpData) return

    try {
      setSaveStatus('saving')
      await updateDraftMutation.mutateAsync({
        rfpId,
        data: rfpData,
      })
      setSaveStatus('saved')
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'manual_save_rfp',
          rfpId,
        },
      })
      setSaveStatus('error')
    }
  }

  const currentStepComponent = RFP_STEPS.find((s) => s.key === step)?.component

  return (
    <StepLayout
      title="New RFP"
      steps={RFP_STEPS}
      currentStep={step}
      saveStatus={saveStatus}
      actionButtons={
        <Button
          variant="contained"
          onClick={handleSaveRfpDraft}
          disabled={updateDraftMutation.isPending || saveStatus === 'saving'}
          sx={{
            fontWeight: 400,
            padding: '10px 20px',
            borderRadius: '8px',
            border: '1px solid #C6CFD6',
            backgroundColor: theme.palette.lightGrey3.main,
            boxShadow: 'none',
            ':hover': {
              backgroundColor: '#E3E9ED',
              boxShadow: 'none',
            },
            color: theme.palette.black.main,
          }}
        >
          <Typography variant="body1">{updateDraftMutation.isPending ? 'Saving...' : 'Save Draft'}</Typography>
        </Button>
      }
      onStepChange={(newStep) =>
        navigate({
          search: { step: newStep },
          replace: true,
        })
      }
    >
      {currentStepComponent &&
        createElement(currentStepComponent, {
          rfpData,
          onUpdateRfpData: handleUpdateRfpData,
        })}
    </StepLayout>
  )
}
