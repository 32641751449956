import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import { ClipboardList } from 'lucide-react'
import ControlPanel from '@/components/ui/base/control-panel'
import { ElasticsearchResponse } from '@/types'
export const Route = createFileRoute('/_authenticated/orders/')({
  component: OrdersIndex,
})

// Example Order type
type Order = {
  id: string
  name: string
}

function OrdersIndex() {
  const [activeTab, setActiveTab] = useState('by-customer')
  const [_searchResults, setSearchResults] = useState<ElasticsearchResponse<Order> | null>(null)

  return (
    <PageLayout title="Orders" icon={<ClipboardList size={36} />} globalNewButton>
      <ControlPanel
        tabConfig={{
          tabs: [
            { label: 'By Customer', value: 'by-customer' },
            { label: 'Plant List', value: 'by-plant-list' },
          ],
          activeTab: activeTab,
          onTabChange: setActiveTab,
        }}
        searchConfig={{
          placeholder: 'Search Orders...',
          searchUrl: '/v1/core/orders/search/',
          onSearchResults: (response) => setSearchResults(response as ElasticsearchResponse<any>),
        }}
      />
    </PageLayout>
  )
}
