import { Card, CardContent, Typography, Skeleton, Box, Stack } from '@mui/material'

export const PlaceholderCard = ({ text }: { text: string }) => {
  return (
    <Card sx={{ maxWidth: 500, padding: 2, boxShadow: 'none' }}>
      <CardContent>
        {/* Header Section */}
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <Skeleton variant="circular" width={40} height={40} />
          <Box>
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width={150} height={20} />
          </Box>
        </Box>

        {/* Message Section */}
        <Box mb={2}>
          <Skeleton variant="text" width="90%" height={20} />
          <Skeleton variant="text" width="85%" height={20} />
          <Skeleton variant="text" width="80%" height={20} />
        </Box>

        {/* Action Buttons */}
        <Box display="flex" gap={2}>
          <Skeleton variant="rectangular" width={120} height={40} />
          <Skeleton variant="rectangular" width={120} height={40} />
        </Box>
      </CardContent>

      {/* Previous Comments Section */}
      <CardContent sx={{ borderTop: '1px solid #ddd', mt: 2 }}>
        <Typography variant="subtitle1" color="text.secondary" mb={1}>
          Previous {text}
        </Typography>
        <Box display="flex" gap={2} mb={2}>
          <Skeleton variant="circular" width={40} height={40} />
          <Box>
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width="70%" height={20} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export const RFPComments = () => {
  return (
    <Stack>
      <PlaceholderCard text="Comments" />
    </Stack>
  )
}
