import { PlantListEntry } from '@/types.ts'
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import { useQuery } from '@tanstack/react-query'
import { getJobsById } from '@/api/jobs.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import { Alert, Checkbox, CircularProgress, keyframes, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import { Search } from 'lucide-react'
import { excludeDeletedItems, formatStringToShortMonthDayYear } from '@/lib/utils.ts'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import InputAdornment from '@mui/material/InputAdornment'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'

interface AddPlantsFromJobsModalProps {
  open: boolean
  onClose: () => void
  jobId: string
  onAddPlants: (plants: PlantListEntry[]) => Promise<void>
}

export default function AddPlantsFromJobsModal({ open, onClose, jobId, onAddPlants }: AddPlantsFromJobsModalProps) {
  const [selectedPlants, setSelectedPlants] = useState<PlantListEntry[]>([])
  const { addToastNotification } = useToastNotifications()

  const handleClose = () => {
    setSelectedPlants([])
    onClose()
  }

  const handlePlantsSelected = useCallback((plants: PlantListEntry[]) => {
    setSelectedPlants(plants)
  }, [])

  const handleAddPlants = async () => {
    await onAddPlants(selectedPlants)
    handleClose()
  }

  const handleAddPlantToModal = () => {
    handleClose()
    addToastNotification({
      severity: 'info',
      title: 'Feature not Implemented',
      message: 'Waiting to hear back from the design team',
    })
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition aria-labelledby="add-plants-from-jobs-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1000,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 5,
          borderRadius: theme.borderRadius.lg,
        }}
      >
        <ModalHeader
          title="Select Plants for RFP"
          sx={{ mb: 5, fontWeight: 400 }}
          id="add-plants-from-jobs-modal-title"
        />

        <SelectJobPlants jobId={jobId} onPlantsSelected={handlePlantsSelected} />

        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            mt: 5,
          }}
        >
          <Stack direction="row">
            <TextIconButton
              onClick={handleAddPlantToModal}
              text={'+ Add Plants to this Job'}
              sx={{
                px: 2,
                borderRadius: theme.borderRadius.md,
                color: theme.palette.darkGrey.main,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                fontWeight: 400,
              }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <PrimaryCancelButton onClick={handleClose}>Cancel &amp; Close</PrimaryCancelButton>
            <DarkPrimaryButton onClick={handleAddPlants} disabled={!selectedPlants.length}>
              {selectedPlants.length
                ? `Add ${pluralizedLabel(selectedPlants.length, 'Plant', 'Plants')}`
                : 'Add Plants'}
            </DarkPrimaryButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}

interface SelectPlantsProps {
  jobId: string
  onPlantsSelected: (
    plants: (PlantListEntry & {
      quantity_count: { min: number; max: number | null }
    })[]
  ) => void
}

function SelectJobPlants({ jobId, onPlantsSelected }: SelectPlantsProps) {
  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())
  const [quantities, setQuantities] = useState<Record<string, number>>({})
  const [searchTerm, setSearchTerm] = useState('')
  const { selectedOrganization } = useOrganization()

  // Query for job data
  const { data: jobData, isLoading } = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => getJobsById(selectedOrganization?.id, jobId),
    enabled: !!selectedOrganization && !!jobId,
  })

  // Memoize active plants
  const activePlants = useMemo(() => {
    if (!jobData?.plants) return []
    return excludeDeletedItems(jobData.plants)
  }, [jobData?.plants])

  // Memoize filtered plants
  const filteredPlants = useMemo(() => {
    if (!searchTerm) return activePlants

    const value = searchTerm.toLowerCase()
    return activePlants.filter(
      (entry: PlantListEntry) =>
        entry.common_name?.toLowerCase().includes(value) || entry.scientific_name?.toLowerCase().includes(value)
    )
  }, [activePlants, searchTerm])

  // Memoize selected plants data
  const selectedPlantsData = useMemo(() => {
    return activePlants
      .filter((e: PlantListEntry) => selectedPlants.has(e.id))
      .map((entry: PlantListEntry) => ({
        ...entry,
        job_id: jobId,
        quantity_count: {
          min: quantities[entry.id] || entry.quantity_count.min || 1,
          max: quantities[entry.id] || null,
        },
      }))
  }, [selectedPlants, quantities, activePlants, jobId])

  // Update parent component when selected plants change
  useEffect(() => {
    onPlantsSelected(selectedPlantsData)
  }, [selectedPlantsData, onPlantsSelected])

  // Memoize handlers
  const handleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newSelected = new Set<string>()
      const newQuantities = { ...quantities }

      if (event.target.checked) {
        filteredPlants.forEach((entry: PlantListEntry) => {
          newSelected.add(entry.id)
          if (!newQuantities[entry.id]) {
            newQuantities[entry.id] = entry.quantity_count.min || 1
          }
        })
      }

      setSelectedPlants(newSelected)
      setQuantities(newQuantities)
    },
    [filteredPlants, quantities]
  )

  const handleCheckboxChange = useCallback((entry: PlantListEntry) => {
    setSelectedPlants((prev) => {
      const newSelected = new Set(prev)
      if (newSelected.has(entry.id)) {
        newSelected.delete(entry.id)
      } else {
        newSelected.add(entry.id)
        setQuantities((prev) => ({
          ...prev,
          [entry.id]: entry.quantity_count.min || 1,
        }))
      }
      return newSelected
    })
  }, [])

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [])

  // Memoize components
  const JobTitle = useMemo(() => {
    if (!jobData) return null

    const title = `${pluralizedLabel(activePlants.length, 'Plant', 'Plants')} in Job #${jobData.id}`
    const expectedStartDate = `Expected Start Date: ${
      formatStringToShortMonthDayYear(jobData.job_date as string) || 'TBD'
    }`

    return (
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" sx={{ pl: 2, gap: 1, alignItems: 'center' }}>
          <Typography fontWeight={700} variant="body1">
            {title}
          </Typography>
          <Typography fontWeight={400} variant="body2">
            -
          </Typography>
          <Typography fontWeight={400} variant="body2">
            {jobData.name}
          </Typography>
        </Stack>
        <Typography fontWeight={400} variant="body2">
          {expectedStartDate}
        </Typography>
      </Stack>
    )
  }, [jobData, activePlants.length])

  const SearchPlantsFromJob = useMemo(
    () => (
      <TextField
        placeholder="Search Plants"
        onChange={handleSearchChange}
        value={searchTerm}
        variant="outlined"
        size="small"
        sx={{
          width: '40%',
          '& .MuiOutlinedInput-root': {
            '& fieldset': { border: 0 },
            '&:hover fieldset': { border: 0 },
            '&.Mui-focused fieldset': { border: 0 },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    ),
    [searchTerm, handleSearchChange]
  )

  if (isLoading) return <LoadingState />
  if (!jobData) return <Alert severity="error">Failed to fetch job data</Alert>
  if (jobData?.deleted_at !== null) return <Alert severity="error">Job has been deleted</Alert>

  return (
    <Stack sx={{ gap: 2 }}>
      {JobTitle}
      <CustomDivider />
      {SearchPlantsFromJob}
      <JobPlantsTable
        filteredPlants={filteredPlants}
        selectedPlants={selectedPlants}
        onCheckboxChange={handleCheckboxChange}
        handleSelectAll={handleSelectAll}
      />
    </Stack>
  )
}

// Extracted components
const LoadingState = () => {
  const pulse = keyframes`
      0% {
          opacity: 1;
      }
      50% {
          opacity: 0.5;
      }
      100% {
          opacity: 1;
      }
  `

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        height: 494,
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey[500],
      }}
    >
      <CircularProgress size={20} color="inherit" />
      <Typography
        variant="strongMd"
        sx={{
          animation: `${pulse} 1.5s ease-in-out infinite`,
        }}
      >
        Loading...
      </Typography>
    </Stack>
  )
}

interface PlantsListProps {
  filteredPlants: any[]
  selectedPlants: Set<string>
  onCheckboxChange: (entry: PlantListEntry) => void
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void
}
const JobPlantsTable = memo(
  ({ filteredPlants, selectedPlants, onCheckboxChange, handleSelectAll }: PlantsListProps) => {
    return (
      <Box sx={{ height: 360, overflowY: 'auto' }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={filteredPlants.length > 0 && selectedPlants.size === filteredPlants.length}
                    indeterminate={selectedPlants.size > 0 && selectedPlants.size < filteredPlants.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Plant Name</TableCell>
                <TableCell>Specs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPlants.map((entry: PlantListEntry) => {
                return (
                  <TableRow key={entry.id}>
                    <TableCell>
                      <Checkbox checked={selectedPlants.has(entry.id)} onChange={() => onCheckboxChange(entry)} />
                    </TableCell>
                    <TableCell>{entry.scientific_name || entry.common_name}</TableCell>
                    <TableCell>
                      <Chip label="Specification" size="small" />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
)
