import { keyframes } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { Fragment, useState, type ChangeEvent, type SyntheticEvent } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { ChevronRight } from 'lucide-react'

import { getPlantListById } from '@/api/plant-list.ts'
import { getPlantListsByOrganization } from '@/api/plants.ts'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import theme from '@/theme.ts'
import { PlantList, PlantListEntry } from '@/types.ts'
import SearchBar from '@/components/ui/base/search-bar.tsx'
import { ElasticsearchResponse } from '@/types.ts'

interface AddPlantsFromListModalProps {
  open: boolean
  onClose: () => void
  onAddPlants: (plants: PlantListEntry[]) => Promise<void>
}
export default function AddPlantsFromListModal({ open, onClose, onAddPlants }: AddPlantsFromListModalProps) {
  const [selectedPlantList, setSelectedPlantList] = useState<string | null>(null)
  const [selectedPlants, setSelectedPlants] = useState<PlantListEntry[]>([])

  const handleClose = () => {
    setSelectedPlants([])
    setSelectedPlantList(null)
    onClose()
  }

  const handlePlantListSelected = (plantListId: string) => {
    setSelectedPlantList(plantListId)
  }

  const handlePlantsSelected = (plants: PlantListEntry[]) => {
    setSelectedPlants(plants)
  }

  const handleAddPlants = async () => {
    await onAddPlants(selectedPlants)
    setSelectedPlantList(null)
    setSelectedPlants([])
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition aria-labelledby="add-plants-from-list-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 5,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <ModalHeader title="Add Plants" sx={{ mb: 5 }} id="add-plants-from-list-modal-title" />

        {!selectedPlantList && <SelectPlantList onPlantListSelected={handlePlantListSelected} />}

        {selectedPlantList && (
          <SelectPlants
            plantListId={selectedPlantList}
            onPlantsSelected={handlePlantsSelected}
            onReselectPlantList={() => setSelectedPlantList(null)}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            mt: 5,
          }}
        >
          <PrimaryCancelButton onClick={handleClose}>Cancel &amp; Close</PrimaryCancelButton>
          <DarkPrimaryButton onClick={handleAddPlants} disabled={!selectedPlants.length}>
            Add Plants
          </DarkPrimaryButton>
        </Box>
      </Box>
    </Modal>
  )
}

interface SelectPlantListProps {
  onPlantListSelected: (plantListId: string) => void
}
function SelectPlantList({ onPlantListSelected }: SelectPlantListProps) {
  const { selectedOrganization } = useOrganization()
  const [hideFullyAssignedLists, setHideFullyAssignedLists] = useState(true)
  const [plantListSearchResults, setPlantListSearchResults] = useState<ElasticsearchResponse<PlantList> | null>(null)

  const handleCheckboxChange = (_event: SyntheticEvent, checked: boolean) => {
    setHideFullyAssignedLists(checked)
  }

  const { data: plantLists } = useQuery({
    queryKey: ['plant-lists', selectedOrganization?.id],
    queryFn: () => {
      if (!selectedOrganization || !selectedOrganization.id) {
        throw new Error('No organization selected')
      }
      return getPlantListsByOrganization(0, 5, selectedOrganization.id)
    },
    enabled: !!selectedOrganization && !plantListSearchResults,
  })

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          width: '100%',
          mb: 5,
        }}
      >
        <Typography variant="strongMd">Choose Plant List</Typography>
        <Divider orientation="vertical" flexItem />
        <SearchBar
          placeholder="Search plant lists..."
          searchUrl="/v1/mdb/plant_list/search/"
          perPage={5}
          page={1}
          onSearchResults={(response) => {
            setPlantListSearchResults(response as ElasticsearchResponse<PlantList>)
          }}
          sx={{ width: 275 }}
        />
        <Box sx={{ ml: 'auto' }}>
          <FormControlLabel
            label="Hide fully assigned lists"
            control={<Checkbox checked={hideFullyAssignedLists} />}
            onChange={handleCheckboxChange}
          />
        </Box>
      </Box>
      <Box>
        {(plantListSearchResults?.items || plantLists)?.map((plantList, index) => (
          <Box
            key={plantList.id}
            sx={{
              cursor: 'pointer',
              '&:hover': { bgcolor: theme.palette.grey[100] },
            }}
          >
            {index === 0 && <Divider />}
            <Stack
              direction="row"
              onClick={() => onPlantListSelected(plantList.id)}
              sx={{
                justifyContent: 'space-between',
                py: 2,
                pl: 2,
                alignItems: 'center',
              }}
            >
              <Typography variant="strong" sx={{ minWidth: 300, maxWidth: 300 }}>
                {plantList.name}
              </Typography>
              <Typography variant="body1" sx={{ minWidth: 200, textAlign: 'left' }}>
                {format(plantList.created_at, 'MMMM d, yyyy')}
              </Typography>
              <Stack direction="row" spacing={2.5} sx={{ alignItems: 'center' }}>
                <Typography
                  variant="body1"
                  sx={{ minWidth: 75, textAlign: 'right' }}
                >{`${pluralizedLabel(plantList.entries_count || 0, 'Plant', 'Plants')}`}</Typography>
                {/** TODO: Assigned status */}
                <Chip label="Pending" sx={{ fontWeight: 700 }} />
                <ChevronRight />
              </Stack>
            </Stack>
            <Divider />
          </Box>
        ))}
      </Box>
    </>
  )
}

interface SelectPlantsProps {
  plantListId: string
  onPlantsSelected: (
    plants: (PlantListEntry & {
      quantity_count: { min: number; max: number | null }
    })[]
  ) => void
  onReselectPlantList: () => void
}
function SelectPlants({ plantListId, onPlantsSelected, onReselectPlantList }: SelectPlantsProps) {
  const [selectedEntries, setSelectedEntries] = useState<Set<string>>(new Set())
  const [quantities, setQuantities] = useState<Record<string, number>>({})

  const { data: plantList, isLoading: isLoadingPlantList } = useQuery({
    queryKey: ['plant-list', plantListId],
    queryFn: () => {
      return getPlantListById(plantListId)
    },
  })

  const pulse = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  `

  if (isLoadingPlantList || !plantList)
    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{
          height: 494,
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.palette.grey[500],
        }}
      >
        <CircularProgress size={20} color="inherit" />
        <Typography
          variant="strongMd"
          sx={{
            animation: `${pulse} 1.5s ease-in-out infinite`,
          }}
        >
          Loading...
        </Typography>
      </Stack>
    )

  const activeEntries = plantList.entries.filter((entry) => !entry.deleted_at)

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (!activeEntries) return

    const newSelected = new Set<string>()
    const newQuantities = { ...quantities }

    if (event.target.checked) {
      activeEntries.forEach((entry) => {
        newSelected.add(entry.id)
        if (!newQuantities[entry.id]) {
          newQuantities[entry.id] = entry.quantity_count.min || 1
        }
      })
    }

    setSelectedEntries(newSelected)
    setQuantities(newQuantities)
    updateSelectedPlants(newSelected, newQuantities)
  }

  const handleCheckboxChange = (entry: PlantListEntry) => {
    if (!plantList?.entries) return

    setSelectedEntries((prev) => {
      const newSelected = new Set(prev)
      if (newSelected.has(entry.id)) {
        newSelected.delete(entry.id)
      } else {
        newSelected.add(entry.id)
        setQuantities((prev) => ({
          ...prev,
          [entry.id]: entry.quantity_count.min || 1,
        }))
      }

      updateSelectedPlants(newSelected, quantities)
      return newSelected
    })
  }

  const handleQuantityChange = (entry: PlantListEntry, value: string) => {
    const quantity = parseInt(value) || 0
    if (quantity < 0) return

    setQuantities((prev) => {
      const newQuantities = { ...prev, [entry.id]: quantity }
      updateSelectedPlants(selectedEntries, newQuantities)
      return newQuantities
    })
  }

  const updateSelectedPlants = (selected: Set<string>, quantities: Record<string, number>) => {
    if (!plantList?.entries) return

    const selectedPlants = plantList.entries
      .filter((e) => selected.has(e.id))
      .map((entry) => ({
        ...entry,
        quantity_count: {
          min: quantities[entry.id] || entry.quantity_count.min || 1,
          max: quantities[entry.id] || null,
        },
      }))

    onPlantsSelected(selectedPlants)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          width: '100%',
          mb: 5,
        }}
      >
        <Typography variant="strongMd">Choose Plant List</Typography>
        <Button variant="outlined" onClick={onReselectPlantList}>
          {plantList?.name}
        </Button>
      </Box>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
          px: 2,
          py: 1,
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={activeEntries.length > 0 && selectedEntries.size === activeEntries.length}
              indeterminate={selectedEntries.size > 0 && selectedEntries.size < activeEntries.length}
              onChange={handleSelectAll}
            />
          }
          label="Select All"
        />
      </Stack>

      <Box sx={{ height: 360, overflowY: 'auto' }}>
        {activeEntries.map((entry, index) => (
          <Fragment key={entry.id}>
            {index === 0 && <Divider />}
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                py: 2,
                px: 2,
                alignItems: 'center',
              }}
            >
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                <Checkbox checked={selectedEntries.has(entry.id)} onChange={() => handleCheckboxChange(entry)} />
                <Typography
                  variant="body1"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {entry.common_name || entry.scientific_name || 'Unnamed Plant'}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2.5} sx={{ alignItems: 'center' }}>
                <TextField
                  type="number"
                  size="small"
                  value={
                    selectedEntries.has(entry.id)
                      ? quantities[entry.id] || entry.quantity_count.min || 1
                      : entry.quantity_count.min || 1
                  }
                  onChange={(e) => handleQuantityChange(entry, e.target.value)}
                  disabled={!selectedEntries.has(entry.id)}
                  inputProps={{ min: 0, style: { width: '60px' } }}
                />
                <Typography>TODO: Add Spec Chips</Typography>
              </Stack>
            </Stack>
            <Divider />
          </Fragment>
        ))}
      </Box>
    </>
  )
}
