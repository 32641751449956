import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format, parse } from 'date-fns'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getPlantListsByOrganization } from '@/api/plants.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { PlantList, ElasticsearchResponse, PlantListEntry } from '@/types.ts'
import SearchBar from '@/components/ui/base/search-bar.tsx'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import Button from '@mui/material/Button'
import { getPlantListById } from '@/api/plant-list.ts'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import { createJob } from '@/api/jobs.ts'

const dateFormat = 'yyyy-MM-dd'
const today = format(new Date(), dateFormat)
const referenceDate = new Date(1970, 0, 1, 0, 0, 0)

interface NewJobModalProps {
  open: boolean
  onClose: () => void
}
export default function NewJobModal({ open, onClose }: NewJobModalProps) {
  const [name, setName] = useState('')
  const [date, setDate] = useState<string>(today)
  const [dateFnsDate, setDateFnsDate] = useState<Date | null>(parse(date, dateFormat, referenceDate))
  const [selectedPlantList, setSelectedPlantList] = useState<PlantList | null>(null)
  const [plantListSearchResults, setPlantListSearchResults] = useState<ElasticsearchResponse<PlantList> | null>(null)
  const queryClient = useQueryClient()
  const { selectedOrganization } = useOrganization()

  const { data: plantLists } = useQuery({
    queryKey: ['plant-lists', selectedOrganization?.id],
    queryFn: () => {
      if (!selectedOrganization?.id) throw new Error('No organization selected')
      return getPlantListsByOrganization(0, 5, selectedOrganization.id)
    },
    enabled: !!selectedOrganization && !plantListSearchResults,
  })

  const { data: plantListData } = useQuery({
    queryKey: ['plant-list', selectedPlantList?.id],
    queryFn: () => {
      if (!selectedPlantList?.id) throw new Error('No plant list selected')
      return getPlantListById(selectedPlantList.id)
    },
    enabled: !!selectedPlantList,
  })

  const createJobMutation = useMutation({
    mutationFn: ({
      name,
      date,
      plants,
      organizationId,
    }: {
      name: string
      date: string
      plants?: PlantListEntry[]
      organizationId?: string
    }) => {
      return createJob(name, date, plants, organizationId)
    },
  })

  useEffect(() => {
    setDateFnsDate(parse(date, dateFormat, referenceDate))
  }, [date])

  const handleClose = () => {
    onClose()
  }

  const handleCreateJob = async () => {
    try {
      await createJobMutation.mutateAsync({
        name,
        date,
        plants: plantListData?.entries,
        organizationId: selectedOrganization?.id,
      })
      await queryClient.refetchQueries({ queryKey: ['jobs', selectedOrganization?.id] })
      handleClose()
    } catch (error) {
      console.error('Error creating job', error)
    }
  }

  const handleDateChange = (dateObject: Date | null) => {
    if (!dateObject) {
      setDate('')
      return
    }
    try {
      const formattedDate = format(dateObject, dateFormat)
      setDate(formattedDate)
    } catch (error) {
      console.error('Error parsing date', error)
      setDate('')
    }
  }

  const handlePlantListSelected = (plantList: PlantList) => {
    setSelectedPlantList(plantList)
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="new-job-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          bgcolor: 'white',
          boxShadow: 24,
          p: 5,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <ModalHeader title="New Job" sx={{ mb: 5 }} id="new-job-modal-title" />
        <Stack spacing={4}>
          <TextField
            id="name"
            name="name"
            label="Job Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
          <DatePicker label="Job Date" value={dateFnsDate} onChange={handleDateChange} />
          {!selectedPlantList && (
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <Typography variant="strongMd">Select Plant List</Typography>
                <Divider orientation="vertical" flexItem />
                <SearchBar
                  placeholder="Search plant lists..."
                  searchUrl="/v1/mdb/plant_list/search/"
                  perPage={5}
                  page={1}
                  onSearchResults={(response) => {
                    setPlantListSearchResults(response as ElasticsearchResponse<PlantList>)
                  }}
                  sx={{ width: 275 }}
                />
              </Box>

              <Box sx={{ height: 300, overflowY: 'auto' }}>
                {(plantListSearchResults?.items || plantLists)?.map((plantList, index) => (
                  <Box
                    key={plantList.id}
                    onClick={() => handlePlantListSelected(plantList)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { bgcolor: theme.palette.grey[100] },
                    }}
                  >
                    {index === 0 && <Divider />}
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'space-between',
                        py: 2,
                        pl: 2,
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="strong" sx={{ minWidth: 300, maxWidth: 300 }}>
                        {plantList.name}
                      </Typography>
                      <Typography variant="body1" sx={{ minWidth: 200, textAlign: 'left' }}>
                        {format(plantList.created_at, 'MMMM d, yyyy')}
                      </Typography>
                      <Stack direction="row" spacing={2.5} sx={{ alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ minWidth: 75, textAlign: 'right' }}>
                          {pluralizedLabel(plantList.entries_count || 0, 'Plant', 'Plants')}
                        </Typography>
                        <ChevronRight />
                      </Stack>
                    </Stack>
                    <Divider />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {selectedPlantList && (
            <Stack spacing={2}>
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                <Typography variant="strongMd">Plants to Add to Job</Typography>
                <Button variant="outlined" onClick={() => setSelectedPlantList(null)}>
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <ChevronLeft size={20} />
                    <Typography variant="button">{selectedPlantList.name}</Typography>
                  </Stack>
                </Button>
              </Stack>
              <Stack spacing={2} sx={{ height: 300, overflowY: 'auto' }}>
                {plantListData?.entries
                  .filter((entry) => !entry.deleted_at)
                  .map((plant) => (
                    <Typography key={plant.id} variant="body1">
                      {plant.common_name || plant.scientific_name}
                    </Typography>
                  ))}
              </Stack>
            </Stack>
          )}

          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <PrimaryCancelButton variant="outlined" onClick={handleClose}>
              Cancel &amp; Close
            </PrimaryCancelButton>
            <DarkPrimaryButton
              variant="contained"
              onClick={handleCreateJob}
              disabled={!selectedPlantList || !name || !date}
            >
              Create Job
            </DarkPrimaryButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
