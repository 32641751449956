import { styled } from '@mui/system'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import EditOutlined from '@mui/icons-material/EditOutlined'
import AddIcon from '@mui/icons-material/Add'
import { MinusCircleIcon } from 'lucide-react'

const StyledIconButton = styled(IconButton)(({ theme, color = 'inherit' }) => {
  const borderColor = color === 'inherit' ? 'inherit' : theme.palette[color].main
  return {
    border: `1px solid ${borderColor}`,
    borderRadius: '5px',
    padding: theme.spacing(1), // Optional, adds some padding
    '&:hover': {
      // borderColor: theme.palette.primary.main, // Optional, change border on hover
    },
  }
})

export const StyledAddIcon = ({ children, ...props }: IconButtonProps) => (
  <StyledIconButton {...props}>{children || <AddIcon />}</StyledIconButton>
)

export const StyledPencilIcon = ({ children, ...props }: IconButtonProps) => (
  <StyledIconButton {...props}>{children || <EditOutlined />}</StyledIconButton>
)

export const StyledRemoveIcon = ({ children, ...props }: IconButtonProps) => (
  <StyledIconButton {...props}>{children || <MinusCircleIcon />}</StyledIconButton>
)

export const DarkRoundIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '100%',
  padding: theme.spacing(1),
  backgroundColor: 'black',
  color: 'white',
  '&:hover': {
    backgroundColor: 'black',
  },
}))
