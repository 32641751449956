import { MessageSquareTextIcon, NotepadText, History } from 'lucide-react'
import { SideDrawer } from '@/components/ui/base/side-drawer.tsx'
import { RFPComments } from '@/components/rfps/drawer/rfp-drawer-comments.tsx'
import { RFPNotes } from '@/components/rfps/drawer/rfp-drawer-notes.tsx'
import { RFPHistory } from '@/components/rfps/drawer/rfp-drawer-history.tsx'

export const RfpSideDrawer = () => {
  const drawerItems = [
    {
      label: 'Comments',
      icon: <MessageSquareTextIcon />,
      content: <RFPComments />,
      count: 0,
    },
    {
      label: 'Notes',
      icon: <NotepadText />,
      content: <RFPNotes />,
      count: 0,
    },
    {
      label: 'History',
      icon: <History />,
      content: <RFPHistory />,
      count: 0,
    },
  ]

  return <SideDrawer tabs={drawerItems} variant="permanent" anchor="right" />
}
