import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import { SendHorizontal } from 'lucide-react'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import Button from '@mui/material/Button'

export default function RfpSentSuccessModal({
  open,
  onClose,
  rfpId,
  sentRfps,
}: {
  open: boolean
  onClose: () => void
  rfpId: string
  sentRfps: any[]
}) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: theme.borderRadius.xl,
        }}
      >
        <Stack spacing={4} sx={{ alignItems: 'center' }}>
          <Box
            sx={{
              borderRadius: theme.borderRadius.circle,
              bgcolor: '#26A482',
              width: '90px',
              height: '90px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SendHorizontal size={48} strokeWidth={2.5} color="#FFFFFF" />
          </Box>
          <Typography variant="h3">Your RFP has been successfully sent!</Typography>
          <Stack
            spacing={3}
            sx={{ border: '1px solid #C6CFD6', py: '40px', px: '110px', borderRadius: theme.borderRadius.md }}
          >
            <Typography variant="h4">RFP#{rfpId} shared with:</Typography>
            {sentRfps.map((sentRfp) => (
              <Stack key={sentRfp.id} sx={{ alignItems: 'center' }}>
                <Typography variant="strong">{sentRfp.directed_organization_contact.organization_name}</Typography>
                {sentRfp.directed_organization_user_contacts.map((contact: any) => (
                  <Stack
                    key={contact.id}
                    direction="row"
                    spacing={1}
                    sx={{ bgcolor: '#EEF2F5', borderRadius: theme.borderRadius.pill, py: '5px', px: '11px' }}
                  >
                    {contact.first_name && contact.last_name && (
                      <Typography variant="strong">{`${contact.first_name} ${contact.last_name}`}</Typography>
                    )}
                    <Typography color="textSecondary">({contact.email})</Typography>
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
          <Button variant={'contained'} onClick={onClose}>
            <Typography variant="button">Close Window</Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
