import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export type FilterOption = {
  label: string
  value: string
  options?: { label: string; value: string }[]
  multiple?: boolean
}

export type FilterValues = Record<string, string | string[]> | undefined

interface FilterPanelProps {
  filters?: FilterOption[]
  filterValues: FilterValues
  onFilterChange: (filter: string, value: string | string[]) => void
}
export default function FilterPanel({ filters = [], filterValues, onFilterChange }: FilterPanelProps) {
  if (!filters.length) {
    return null
  }

  return (
    <Box display="flex" gap={1}>
      {filters.map((filter) => (
        <Select
          key={filter.value}
          value={filterValues?.[filter.value] || ''}
          onChange={(e) => onFilterChange(filter.value, e.target.value)}
          multiple={filter.multiple}
          displayEmpty
          renderValue={(selected) => {
            if (!selected || (Array.isArray(selected) && selected.length === 0)) {
              return filter.label
            }

            const findLabel = (value: string) => filter.options?.find((opt) => opt.value === value)?.label || value

            return Array.isArray(selected) ? selected.map(findLabel).join(', ') : findLabel(selected)
          }}
        >
          {filter.options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ))}
    </Box>
  )
}
