import { z } from 'zod'

// NOTE: DateTime Validation is not working as expected
const FullTimeStampSchema = z.object({
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
})

const BasicTimeStampSchema = z.object({
  created_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
})

const BaseRFPSchema = z.object({
  ...FullTimeStampSchema.shape,
  rfp_id: z.string(),
  sent_rfp_id: z.string().nullable(),
  name: z.string(),
  quote_needed_by: z.string().nullable(),
  delivery_date: z.string().nullable(),
  customer_rfp_id: z.string().nullable(),
  last_viewed_by_customer: z.string().nullable(),
  last_viewed_customer_user: z.any().nullable(), // TODO: verify need for deep type
  last_viewed_vendor_user: z.any().nullable(), // TODO: verify need for deep type
  last_viewed_by_vendor: z.string().nullable(),
  sent_at: z.string().nullable(),
})

const PendingRFPSchema = BaseRFPSchema.extend({
  status: z.literal('pending'),
})

const SentRFPSchema = BaseRFPSchema.extend({
  status: z.literal('sent'),
  sent_rfp_id: z.string(),
  last_status_change: z.string(),
  sent_at: z.string().nullable(),
  // sent_at: z.string(), // TODO: remove nullable upon backend update
})

const QuotedRFPSchema = BaseRFPSchema.extend({
  status: z.literal('quoted'),
  last_status_change: z.string(),
  sent_at: z.string().nullable(),
  // sent_at: z.string(), // TODO: remove nullable upon backend update, re
})

const RFPSchema = z.discriminatedUnion('status', [PendingRFPSchema, SentRFPSchema, QuotedRFPSchema])

export type RFP = z.infer<typeof RFPSchema>
const RFPJobSchema = z.object({
  ...BasicTimeStampSchema.shape,
  job_id: z.string().nullable(),
  job_name: z.string().nullable(),
  job_date: z.coerce.date().nullable(),
  // customer_job_id: z.string().nullable(), // TODO: add when available from backend
  rfps: z.array(RFPSchema).default([]),
})

export const RFPContactIndexSchema = z.array(
  z.object({
    _id: z.string(),
    ...FullTimeStampSchema.shape,
    organization: z.any(), // TODO: verify need for deep type
    vendor: z.any(), // TODO: verify need for deep type
    customer: z.any(), // TODO: verify need for deep type
    jobs: z.array(RFPJobSchema).default([]),
  })
)

export type RFPContactIndex = z.infer<typeof RFPContactIndexSchema>
export const RFPIndexEnum = z.enum(['received', 'sent'])
export type RFPIndexTypes = z.infer<typeof RFPIndexEnum>
