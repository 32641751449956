import { Stack } from '@mui/material'
import { VisibilityOff } from '@mui/icons-material'
import Typography from '@mui/material/Typography'

type SizeType = 'inherit' | 'large' | 'medium' | 'small'

export const NotVisibleToVendors = ({ size }: { size: SizeType }) => {
  return (
    <Stack direction="row" sx={{ gap: 1, color: '#ccc', justifyContent: 'center' }}>
      <VisibilityOff fontSize={size} />
      <Typography variant="body2" fontSize={size}>
        Not visible to vendors
      </Typography>
    </Stack>
  )
}
