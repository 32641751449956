import { useQueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { getRfps } from '@/api/rfps'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import { RFPIndexTypes } from '@/api/types/rfps'

export function useRFPIndex({
  page = 0,
  type = 'sent',
  groupBy = 'vendor',
}: {
  page?: number
  groupBy?: 'vendor' | 'job'
  type?: RFPIndexTypes
} = {}) {
  const { selectedOrganizationId } = useOrganization()
  const queryClient = useQueryClient()
  const queryKey = [
    type === 'received' ? `rfps-received-by-${groupBy}` : `rfps-sent-by-${groupBy}`,
    selectedOrganizationId,
    page,
  ]

  const query = useQuery({
    queryKey,
    retry: false,
    queryFn: async () => {
      if (!selectedOrganizationId) {
        return []
      }
      let rfpData = await getRfps(selectedOrganizationId, type)

      if (type === 'sent') {
        return rfpData.sort((a, b) => {
          // put drafts first
          if (!a.customer && !a.vendor) {
            return -1
          }
          if (!b.customer && !b.vendor) {
            return 1
          }
          return 0
        })
      }

      return rfpData
    },
    enabled: !!selectedOrganizationId,
    initialData: () => {
      return queryClient.getQueryData(queryKey)
    },
  })

  return query
}
