import { useState, useRef, useEffect, type MouseEvent } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'

import { Download, Trash2, ZoomInIcon, ZoomOutIcon } from 'lucide-react'

import { deleteFile } from '@/api/files.ts'
import { IMAGE_FILE_TYPES, SPREADSHEET_FILE_TYPES } from '@/constants.ts'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import type { GCSFile, ImageFile, SpreadsheetFile } from '@/types.ts'
import { IconButton, Stack } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import Alert from '@mui/material/Alert'
import theme from '@/theme'

type FileViewerProps = {
  files: GCSFile[]
  onFileDelete: () => void
  fileId: string | null
  onFileIdChange: (fileId: string | null) => void
}

export default function FileViewer({ files, onFileDelete, fileId, onFileIdChange }: FileViewerProps) {
  const [fileViewerFileIndex, setFileViewerFileIndex] = useState(0)
  const [isZoomed, setIsZoomed] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(1)
  const [savedZoomLevel, setSavedZoomLevel] = useState(2)
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [clickStartTime, setClickStartTime] = useState(0)
  const imageRef = useRef<HTMLImageElement>(null)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [selectedFile, setSelectedFile] = useState<any | null>(null)

  const { data: allFiles, isLoading: allFilesLoading } = useQuery({
    queryKey: ['files', files.length],
    queryFn: async () => {
      return await Promise.all(files.map((file) => fetchGCSFile(file)))
    },
    enabled: files.length > 0,
  })

  useEffect(() => {
    if ((allFiles && Array.from(allFiles).length === 0) || !allFiles) {
      onFileIdChange(null)
      setFileViewerFileIndex(0)
      setSelectedFile(null)
    } else {
      const index = fileId ? allFiles.findIndex((file) => file.id === fileId) : 0
      const newIndex = index >= 0 ? index : 0
      setSelectedFile(allFiles[newIndex])
      onFileIdChange(allFiles[newIndex].id)
      setFileViewerFileIndex(newIndex)
    }
  }, [allFiles, fileId])

  const deleteMutation = useMutation({
    mutationFn: deleteFile,
    onSuccess: (_, deletedFileId) => {
      const remainingFiles = files.filter((file) => file.id !== deletedFileId && !file.deleted_at)
      if (remainingFiles.length > 0) {
        onFileIdChange(remainingFiles[0].id)
      } else {
        onFileIdChange('')
      }
      onFileDelete()
    },
  })

  const handleFileIndex = (direction: 'prev' | 'next') => {
    if (!allFiles || allFiles.length === 0) return
    const newIndex =
      direction === 'prev'
        ? (fileViewerFileIndex - 1 + allFiles.length) % allFiles.length
        : (fileViewerFileIndex + 1) % allFiles.length

    setFileViewerFileIndex(newIndex)
    onFileIdChange(allFiles[newIndex].id)
  }

  const handleFileClick = (index: number, file_id: string) => {
    if (!file_id) return
    onFileIdChange(file_id)
    setFileViewerFileIndex(index)
  }

  const handleFileDelete = async (deleteFileId: string) => {
    if (deleteFileId) {
      await deleteMutation.mutateAsync(deleteFileId)
    }
  }

  const handleZoomToggle = (event: MouseEvent<HTMLDivElement>) => {
    if (!isZoomed) {
      if (imageContainerRef.current) {
        const rect = imageContainerRef.current.getBoundingClientRect()
        const x = ((event.clientX - rect.left) / rect.width) * 100
        const y = ((event.clientY - rect.top) / rect.height) * 100
        setPanPosition({ x, y })
      }
      setZoomLevel(savedZoomLevel)
    } else {
      setPanPosition({ x: 0, y: 0 })
      setZoomLevel(1)
    }

    setIsZoomed(!isZoomed)
  }

  const handleZoomChange = (_event: Event, newValue: number | number[]) => {
    const newZoom = newValue as number
    setZoomLevel(newZoom)
    setIsZoomed(newZoom > 1)
    if (newZoom <= 1) {
      setSavedZoomLevel(2)
      setPanPosition({ x: 0, y: 0 })
    } else {
      setSavedZoomLevel(newZoom)
    }
  }

  const handleZoomIn = () => {
    setZoomLevel((prev) => {
      const newZoom = Math.min(prev + 0.5, 5)
      setSavedZoomLevel(newZoom)
      return newZoom
    })
    setIsZoomed(true)
  }

  const handleZoomOut = () => {
    setZoomLevel((prev) => {
      const newZoom = Math.max(prev - 0.5, 1)
      setIsZoomed(newZoom > 1)
      if (newZoom <= 1) {
        setSavedZoomLevel(2)
        setPanPosition({ x: 0, y: 0 })
      } else {
        setSavedZoomLevel(newZoom)
      }
      return newZoom
    })
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setClickStartTime(Date.now())
    if (isZoomed) {
      setIsDragging(true)
      setDragStart({ x: event.clientX, y: event.clientY })
    }
  }

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    const clickDuration = Date.now() - clickStartTime
    if (clickDuration < 200) {
      handleZoomToggle(event)
    }
    setIsDragging(false)
  }

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (isZoomed && isDragging) {
      const dx = event.clientX - dragStart.x
      const dy = event.clientY - dragStart.y
      setDragStart({ x: event.clientX, y: event.clientY })
      setPanPosition((prev) => ({
        x: prev.x - dx / zoomLevel,
        y: prev.y - dy / zoomLevel,
      }))
    }
  }

  const handleDownload = (file: typeof selectedFile) => {
    if (!file) return

    const blob = new Blob([file.rawData], { type: file.file_type })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = file.user_file_name
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  const selectedFileView = (
    <>
      {selectedFile && SPREADSHEET_FILE_TYPES.includes(selectedFile.file_type) && (
        <Box className="max-h-[70vh] overflow-y-scroll">
          {(selectedFile as SpreadsheetFile)?.tables?.map((table, index) => (
            <Box key={index} dangerouslySetInnerHTML={{ __html: table }} id="file-view-container"></Box>
          ))}
        </Box>
      )}
      {selectedFile && IMAGE_FILE_TYPES.includes(selectedFile.file_type) && (
        <>
          <Box
            ref={imageContainerRef}
            sx={{
              position: 'relative',
              overflow: 'auto',
              height: '55vh',
              width: '100%',
              border: '1px solid #333',
              borderRadius: '0.5rem',
              paddingX: '2',
              cursor: isZoomed ? 'move' : 'zoom-in',
            }}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
          >
            <img
              ref={imageRef}
              src={(selectedFile as ImageFile).url}
              alt={(selectedFile as ImageFile).alt}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                transition: isZoomed ? 'none' : 'transform 0.1s ease-in-out',
                transform: `scale(${zoomLevel}) translate(${-panPosition.x / zoomLevel}%, ${-panPosition.y / zoomLevel}%)`,
                transformOrigin: '0 0',
                pointerEvents: 'none',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              padding: '1em',
            }}
          >
            <Button onClick={handleZoomOut}>
              <ZoomOutIcon color="white" />
            </Button>

            <Slider
              value={zoomLevel}
              onChange={handleZoomChange}
              min={1}
              max={5}
              step={0.1}
              aria-labelledby="zoom-slider"
              sx={{
                flexGrow: 1,
                color: 'white',
                '& .MuiSlider-thumb': {
                  color: 'white',
                },
                '& .MuiSlider-track': {
                  color: 'white',
                },
                '& .MuiSlider-rail': {
                  color: 'white',
                },
              }}
            />
            <Button onClick={handleZoomIn}>
              <ZoomInIcon color="white" />
            </Button>
          </Box>
        </>
      )}
    </>
  )

  if (allFilesLoading) {
    return (
      <Box sx={{ backgroundColor: theme.palette.black.main, height: '100vh' }}>
        <Box
          p={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            gap: 2,
          }}
        >
          <CircularProgress size={20} sx={{ color: 'white' }} />
          <Typography variant="body1">Loading Files...</Typography>
        </Box>
      </Box>
    )
  }

  return allFiles && allFiles.length ? (
    <Box sx={{ backgroundColor: theme.palette.black.main }}>
      <Box px={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2} color="white">
          <Typography variant="body2">{selectedFile?.user_file_name}</Typography>
          <Stack direction="row" py={2} spacing={1} alignItems="center">
            <IconButton
              disabled={fileViewerFileIndex === 0}
              onClick={() => handleFileIndex('prev')}
              sx={{
                color: 'white',
                backgroundColor: 'grey.800',
                '&:hover': { backgroundColor: 'grey.900' },
                '&.Mui-disabled': {
                  color: 'grey.700',
                  backgroundColor: 'grey.800',
                  opacity: 0.5,
                },
              }}
            >
              <NavigateBefore />
            </IconButton>
            <Typography>{`${fileViewerFileIndex + 1} of ${allFiles.length}`}</Typography>
            <IconButton
              disabled={fileViewerFileIndex === allFiles.length - 1}
              onClick={() => handleFileIndex('next')}
              sx={{
                color: 'white',
                backgroundColor: 'grey.800',
                '&:hover': { backgroundColor: 'grey.900' },
                '&.Mui-disabled': {
                  color: 'grey.700',
                  backgroundColor: 'grey.800',
                  opacity: 0.5,
                },
              }}
            >
              <NavigateNext />
            </IconButton>
          </Stack>
        </Stack>
        {/* PDF rendering component */}
        {selectedFileView}
      </Box>

      <Box p={2} sx={{ borderTop: 1, borderColor: 'divider' }}>
        {allFiles.map((file, index) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={0.4}
            key={file.id}
            sx={{
              pl: 1,
              color: 'white',
              border: 1,
              borderColor: selectedFile?.id === file.id ? 'white' : 'grey.900',
              borderRadius: 2,
            }}
          >
            <Typography sx={{ cursor: 'pointer' }} onClick={() => handleFileClick(index, file.id)}>
              {file.user_file_name}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button
                onClick={() => handleDownload(file)}
                sx={{
                  color: 'white',
                  textDecoration: 'underline',
                  '&:hover': {
                    textDecoration: 'underline',
                    color: 'primary.main',
                  },
                }}
                disabled={!fileId}
                startIcon={<Download size={16} />}
              >
                Download
              </Button>
              <Button
                onClick={() => handleFileDelete(file.id)}
                sx={{
                  color: 'white',
                  textDecoration: 'underline',
                  '&:hover': { textDecoration: 'underline', color: 'red' },
                }}
                disabled={!fileId || deleteMutation.isPending}
                startIcon={deleteMutation.isPending ? <CircularProgress size={16} /> : <Trash2 size={16} />}
              >
                Delete File
              </Button>
            </Stack>
          </Stack>
        ))}
      </Box>
    </Box>
  ) : (
    <Box m={2}>
      <Alert variant="outlined" severity="info">
        No files available for this plantlist
      </Alert>
    </Box>
  )
}
