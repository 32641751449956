import { Organization } from '@/types.ts'
import React, { createContext, useState, type ReactNode, useEffect } from 'react'
import { QueryObserverResult, RefetchOptions, useQuery, useQueryClient } from '@tanstack/react-query'
import { getOrganizationById } from '@/api/organization_management.ts'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'

type OrganizationContextType = {
  selectedOrganizationId: string | undefined | null
  setSelectedOrganizationId: (id: string) => void
  selectedOrganization: Organization | null | undefined
  organizationModalOpen: boolean
  toggleOrganizationModal: (value: boolean | null) => void
  refetchSelectedOrganization: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<Organization | null, Error>>
}

export const OrganizationContext = createContext<OrganizationContextType | null>(null)

type OrganizationProviderProps = {
  children: ReactNode
}
export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({ children }) => {
  const { userOrganizations } = useUserInfo()
  const queryClient = useQueryClient()
  const [organizationModalOpen, setOrganizationModalOpen] = useState(false)

  // Get initial organization ID from localStorage or fall back to first org
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(() => {
    const savedOrgId = localStorage.getItem('selectedOrganizationId')
    return savedOrgId || userOrganizations?.[0]?.id || null
  })

  const { data: selectedOrganization, refetch: refetchSelectedOrganization } = useQuery({
    queryKey: ['selected-organization', selectedOrganizationId],
    queryFn: () => {
      if (!selectedOrganizationId) {
        return null
      }
      try {
        return getOrganizationById(selectedOrganizationId)
      } catch (error) {
        console.error('Error fetching organization:', error)
        return null
      }
    },
    initialData: () => {
      return queryClient.getQueryData(['selected-organization', selectedOrganizationId])
    },

    staleTime: 7 * 24 * 60 * 60 * 1000,
    enabled: !!selectedOrganizationId,
  })

  // Save selected org ID to localStorage whenever it changes
  useEffect(() => {
    if (selectedOrganizationId) {
      localStorage.setItem('selectedOrganizationId', selectedOrganizationId)
    } else {
      setSelectedOrganizationId(userOrganizations?.[0]?.id || null)
    }
  }, [selectedOrganizationId, userOrganizations])

  useEffect(() => {
    setSelectedOrganizationId(selectedOrganizationId)
  }, [selectedOrganizationId])

  const toggleOrganizationModal = (value: boolean | null) => {
    value === null ? setOrganizationModalOpen((prev) => !prev) : setOrganizationModalOpen(value)
  }

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganization,
        selectedOrganizationId,
        setSelectedOrganizationId,
        organizationModalOpen,
        toggleOrganizationModal,
        refetchSelectedOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
