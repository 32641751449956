import { PlantList } from '@/types.ts'
import { Box } from '@mui/material'
import PlantListDetailTable from '@/components/plant-list-details/plant-list-table.tsx'

type PlantListTableContainerProps = {
  plantList: PlantList
  selectedFiles: string[]
  searchFilteredPlants: Set<string>
  selectedPlants: Set<string>
  setSelectedPlants: (plants: Set<string>) => void
  setTargetFileId: (fileId: string) => void
  onFileProcessed?: () => void
}

export function PlantListTableContainer({
  plantList,
  selectedFiles,
  searchFilteredPlants,
  selectedPlants,
  setSelectedPlants,
  setTargetFileId,
  onFileProcessed,
}: PlantListTableContainerProps) {
  return (
    <Box
      mt={4}
      mx={0}
      flex={1}
      sx={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PlantListDetailTable
        plantList={plantList}
        selectedFiles={selectedFiles}
        searchFilteredPlants={searchFilteredPlants}
        selectedPlants={selectedPlants}
        setSelectedPlants={setSelectedPlants}
        setTargetFileId={setTargetFileId}
        onFileProcessed={onFileProcessed}
      />
    </Box>
  )
}
