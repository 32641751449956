import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ConfirmationModalProps } from '@/types.ts'
import { Stack } from '@mui/material'

export function ConfirmationModal({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  danger = false,
  loading = false,
}: ConfirmationModalProps) {
  const handleClose = () => {
    onCancel()
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Stack
        direction="column"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          gap: 2,
        }}
      >
        <Typography variant="h3" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {message}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2, mt: 2 }}>
          <Button variant="outlined" color="mediumGrey" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button variant="contained" color={danger ? 'error' : 'primary'} onClick={onConfirm} disabled={loading}>
            Confirm
          </Button>
        </Box>
      </Stack>
    </Modal>
  )
}
