import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { createSentRfp, getSentRfpsByRfpId, updateSentRfp } from '@/api/rfps'
import { RFPPageContent } from '@/components/rfps/draft/steps/build-rfp-step-content.tsx'
import VendorPanel from '@/components/rfps/draft/steps/vendor-panel.tsx'
import AddVendorContactToRfpModal from '@/components/ui/modals/add-vendor-contact-to-rfp-modal.tsx'
import { RFPAddVendorHelperModal } from '@/components/ui/modals/add_vendors_to_rfp_helper_modal.tsx'
import { OrganizationContact, UserContact } from '@/types.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { RFPStepProps } from '@/components/rfps/types.ts'
import { useDebouncedCallback } from 'use-debounce'
import * as Sentry from '@sentry/react'

export default function AddVendorsStep({ rfpData }: RFPStepProps) {
  const { selectedOrganization } = useOrganization()
  const queryClient = useQueryClient()
  const addVendorRFPContentParentId = 'add-vendor-rfp-content-parent'
  const [vendorContactModalOpen, setVendorContactModalOpen] = useState(false)
  const [selectedVendorContact, setSelectedVendorContact] = useState<OrganizationContact | null>(null)
  const [selectedSentRfp, setSelectedSentRfp] = useState<any>()

  const { data: sentRfps } = useQuery({
    queryKey: ['sent-rfps', selectedOrganization?.id],
    queryFn: async () => {
      if (!rfpData.id || !selectedOrganization?.id) return []

      return await getSentRfpsByRfpId(rfpData.id, selectedOrganization?.id)
    },
    enabled: !!selectedOrganization && !!rfpData?.id,
  })

  const updateSentRfpMutation = useMutation({
    mutationFn: updateSentRfp,
    onSuccess: async () => {
      // Invalidate the sent-rfps query to force a refresh
      await queryClient.invalidateQueries({ queryKey: ['sent-rfps', selectedOrganization?.id] })
    },
    onError: (error) => {
      console.error('Error updating sent RFP:', error)
    },
  })

  const createSentRfpMutation = useMutation({
    mutationFn: createSentRfp,
    onSuccess: async () => {
      // Invalidate the sent-rfps query to force a refresh
      await queryClient.invalidateQueries({ queryKey: ['sent-rfps', selectedOrganization?.id] })
    },
    onError: (error) => {
      console.error('Error creating sent RFP:', error)
    },
  })

  const handleUpdateRfpData = useDebouncedCallback(async (updates: Partial<any>) => {
    // TODO: show saving status on RFP draft page by calling up to parent
    try {
      await updateSentRfpMutation.mutateAsync({
        sentRfpId: selectedSentRfp?.id,
        data: updates,
      })
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'auto_save_sent_rfp',
          rfpId: selectedSentRfp?.id,
        },
      })
    }
  }, 1000)

  const handleContactSelect = (contact: OrganizationContact) => {
    setSelectedVendorContact(contact)
    if (contact) {
      setVendorContactModalOpen(true)
    }
  }

  const handleAddVendorContacts = (contacts: UserContact[]) => {
    createSentRfpMutation.mutate({
      rfpId: rfpData.id,
      organizationId: rfpData.organization.id,
      directed_organizations: [
        {
          directed_organization_contact: selectedVendorContact?.id as string,
          directed_organization_user_contacts: contacts.map((contact) => contact.id),
          send: false,
        },
      ],
      plants: rfpData.plants,
    })
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
      <Stack sx={{ width: 350, backgroundColor: 'white', p: 4 }}>
        <VendorPanel
          sentRfps={sentRfps}
          onContactSelect={handleContactSelect}
          setSentRfp={setSelectedSentRfp}
          sentRfpId={selectedSentRfp?.id}
        />
      </Stack>
      <Stack
        direction="column"
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          id={addVendorRFPContentParentId}
          sx={{
            p: 3,
            flexGrow: 1,
            overflow: 'auto',
            scrollbarWidth: 'none',
            height: 0,
            position: 'relative',
          }}
        >
          <RFPAddVendorHelperModal containerId={addVendorRFPContentParentId} open={!selectedSentRfp} />

          {!selectedSentRfp ? (
            <RFPPageContent
              rfpData={rfpData}
              onUpdateRfpData={handleUpdateRfpData}
              showHeader={true}
              allowPlantTableQuantityChange={true}
            />
          ) : (
            <RFPPageContent
              rfpData={selectedSentRfp}
              onUpdateRfpData={handleUpdateRfpData}
              showHeader={false}
              sentRfp={selectedSentRfp}
              allowPlantTableQuantityChange={true}
            />
          )}
        </Box>
      </Stack>
      <AddVendorContactToRfpModal
        open={vendorContactModalOpen}
        onClose={() => setVendorContactModalOpen(false)}
        vendorContact={selectedVendorContact}
        onAddVendorContacts={handleAddVendorContacts}
      />
    </Box>
  )
}
