import React from 'react'
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material'
import { GCSFile } from '../../types'

interface FilterBarProps {
  files: GCSFile[]
  rfpIds: string[]
  onFileFilterChange: (fileId: string) => void
  onRfpFilterChange: (rfpId: string) => void
  onCommonNameFilterChange: (name: string) => void
  onScientificNameFilterChange: (name: string) => void
}

const FilterBar: React.FC<FilterBarProps> = ({
  files,
  rfpIds,
  onFileFilterChange,
  onRfpFilterChange,
  onCommonNameFilterChange,
  onScientificNameFilterChange,
}) => {
  return (
    <Box display="flex" gap={2} mb={2}>
      <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
        <InputLabel>File</InputLabel>
        <Select label="File" onChange={(e) => onFileFilterChange(e.target.value as string)} defaultValue="">
          <MenuItem value="">All</MenuItem>
          {files
            .filter((file) => !file.deleted_at)
            .map((file) => (
              <MenuItem key={file.id} value={file.id}>
                {file.user_file_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
        <InputLabel>RFP</InputLabel>
        <Select label="RFP" onChange={(e) => onRfpFilterChange(e.target.value as string)} defaultValue="">
          <MenuItem value="">All</MenuItem>
          {rfpIds.map((id) => (
            <MenuItem key={id} value={id}>
              {id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Common Name"
        variant="outlined"
        size="small"
        onChange={(e) => onCommonNameFilterChange(e.target.value)}
      />

      <TextField
        label="Scientific Name"
        variant="outlined"
        size="small"
        onChange={(e) => onScientificNameFilterChange(e.target.value)}
      />
    </Box>
  )
}

export default FilterBar
