import { User } from '@/types.ts'
import { ContactEmailTypes, ContactPhoneTypes, US_STATES } from '@/constants.ts'
import { countryFormattedPhoneNumber, trimValues } from '@/lib/utils.ts'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import { initialFormAddress, initialFormEmail, initialFormPhone } from '@/seed_form_data.ts'
import { PlusIcon } from 'lucide-react'
import { StyledRemoveIcon } from '../ui/base/buttons/icon-buttons'
import { type FieldNamesMarkedBoolean, useFieldArray, useForm } from 'react-hook-form'
import { useState, ChangeEvent, Fragment, useEffect, useRef, SetStateAction, Dispatch } from 'react'
import { USStatesMenuProps } from '@/components/organization-settings-and-members/organization-location-form.tsx'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Avatar, Box, Button, Grid, MenuItem, Select, Typography } from '@mui/material'
import TextField from '@/components/ui/base/text-field.tsx'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateUser } from '@/api/user'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'
import { useUpdateProfilePicture } from '@/hooks/user-profile/useUpdateProfilePicture'
import {
  BaseAddressSchema,
  ContactAddressSchema,
  ContactEmailSchema,
  ContactPhoneSchema,
  UserProfileSchema,
} from '@/lib/validation-schemas'
import { USER_QUERY_KEYS } from '@/lib/query-keys'
import { parsePhoneNumberFromString, parsePhoneNumberWithError } from 'libphonenumber-js/max'
import { PlantBidAPIValidationError } from '@/api/api'

const formSchema = (dirtyFields: FieldNamesMarkedBoolean<z.infer<typeof UserProfileSchema>> = {}) =>
  UserProfileSchema.extend({
    phone: z.array(
      ContactPhoneSchema.extend({
        number: z.string().nullable(),
      })
    ),
    other_email: z.array(
      ContactEmailSchema.extend({
        address: z.string().nullable(),
      })
    ),
    address: z
      .array(
        ContactAddressSchema.extend({
          address: BaseAddressSchema.extend({ state: z.string().nullable(), zip: z.string().nullable() }),
        })
      )
      .nullable()
      .optional(),
  }).superRefine((data, ctx) => {
    let errored = false
    const isAddressEdited = (dirtyFields?.address && Object.values(dirtyFields.address).some(Boolean)) || false
    const isInfoEdited = ['first_name', 'last_name', 'title', 'biography'].some(
      (field) => dirtyFields[field as keyof typeof dirtyFields]
    )
    const isPhonesEdited = (dirtyFields?.phone && Object.values(dirtyFields.phone).some(Boolean)) || false
    const isEmailsEdited = (dirtyFields?.other_email && Object.values(dirtyFields.other_email).some(Boolean)) || false
    const isProfilePictureEdited =
      (dirtyFields?.profile_picture && Object.values(dirtyFields.profile_picture).some(Boolean)) || false

    // console.log({ data })
    // console.log({ isAddressEdited, isInfoEdited, isPhonesEdited, isEmailsEdited, isProfilePictureEdited })

    if (isAddressEdited) {
      const { address } = data
      const firstAddress = address?.[0].address

      const addressCleared =
        firstAddress?.city === '' &&
        firstAddress?.state === '' &&
        firstAddress?.zip === '' &&
        firstAddress?.street === '' &&
        firstAddress?.suite === ''

      if (!addressCleared) {
        data.address?.forEach((addr, index) => {
          const parsed = BaseAddressSchema.safeParse(addr.address)
          if (!parsed.success) {
            parsed.error.errors.forEach((err) =>
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: err.message,
                path: ['address', index, 'address', ...err.path],
              })
            )
            errored = true
          }
        })
      }
    }

    if (isPhonesEdited) {
      data.phone.forEach((phone, index) => {
        const parsed = ContactPhoneSchema.extend({
          number: z
            .string()
            .nullable()
            .refine(
              (value) => {
                if (!value) {
                  // .nullable()
                  return true
                }
                try {
                  const parsedNumber = parsePhoneNumberFromString(value)
                  return parsedNumber?.isValid()
                } catch {
                  return false
                }
              },
              {
                message: 'Invalid phone number',
              }
            ),
        }).safeParse(phone)
        if (!parsed.success) {
          parsed.error.errors.forEach((err) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: err.message,
              path: ['phone', index, ...err.path],
            })
          })
          errored = true
        }
      })
    }

    if (isEmailsEdited) {
      data.other_email.forEach((email, index) => {
        const parsed = ContactEmailSchema.safeParse(email)
        if (!parsed.success) {
          parsed.error.errors.forEach((err) =>
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: err.message,
              path: ['other_email', index, ...err.path],
            })
          )
          errored = true
        }
      })
    }

    if (isProfilePictureEdited) {
      const parsed = profilePictureSchema.safeParse(data.profile_picture)
      if (!parsed.success) {
        parsed.error.errors.forEach((err) =>
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: err.message, path: ['profile_picture', ...err.path] })
        )
        errored = true
      }
    }

    if (isInfoEdited) {
      const parsed = nameSchema.safeParse({
        first_name: data.first_name,
        last_name: data.last_name,
        title: data.title,
        biography: data.biography,
      })
      if (!parsed.success) {
        parsed.error.errors.forEach((err) => {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: err.message, path: [err.path[0]] })
        })
        errored = true
      }
    }

    return !errored
  })

const profilePictureSchema = z.object({
  user_file_name: z.string(),
  file_type: z.string(),
  encoding: z.string(),
  domain: z.string(),
})

const nameSchema = z.object({
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  title: z.string().optional(),
  biography: z.string().optional(),
})

export default function MyProfileForm({
  user,
  profilePictureUrl,
  setIsEditing,
}: {
  user: User
  profilePictureUrl?: string
  setIsEditing: Dispatch<SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const [dynamicSchema, setDynamicSchema] = useState<z.ZodSchema>(formSchema())
  const [profilePicture, setProfilePicture] = useState(profilePictureUrl)
  const profilePictureInputRef = useRef<HTMLInputElement>(null)
  const updateUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: USER_QUERY_KEYS.userInfo })
    },
    onError: (error: PlantBidAPIValidationError) => {
      const details = error.response?.data?.detail
      // This might be prone to not visually show all potential errors
      // Main reason for this is phone number validation isn't 1-1 with the backend
      if (details && details.length > 0) {
        details.forEach((detail) => {
          const path = detail.loc.slice(1).join('.')
          setError(path as unknown as keyof typeof errors, { message: detail.msg, type: 'validate', types: {} })
        })
      }
    },
  })
  const updateProfilePictureMutation = useUpdateProfilePicture()
  const toast = useToastNotifications()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { dirtyFields, errors },
  } = useForm({
    resolver: zodResolver(dynamicSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      title: user.title || '',
      biography: user.biography || '',
      profile_picture: user.profile_picture,
      // user.address is an array, however the UI will only show one address, always use [0]
      address: user.address.length > 0 ? [user.address[0]] : [initialFormAddress],
      phone:
        user.phone.length > 0
          ? user.phone.map((phone) => ({ ...phone, number: countryFormattedPhoneNumber(phone.number) }))
          : [initialFormPhone],
      other_email: user.other_email.length > 0 ? user.other_email : [initialFormEmail],
    },
  })
  const {
    fields: phones,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phone',
  })

  const {
    fields: emails,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'other_email',
  })

  const handlePictureUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]
    if (file) {
      setProfilePicture(URL.createObjectURL(file) as string)
      setValue(
        'profile_picture',
        {
          user_file_name: file.name,
          file_type: file.type,
          encoding: file.type,
          domain: 'profile_picture',
        },
        {
          shouldDirty: true,
          shouldTouch: true,
        }
      )
    }
  }

  const submit = handleSubmit(async (data) => {
    const trimmedData = trimValues(data)
    const updatedFields = Object.keys(dirtyFields).filter((key) => dirtyFields[key as keyof typeof dirtyFields])
    const submissionData = updatedFields.reduce(
      (acc, key) => {
        switch (key) {
          case 'first_name':
            return { ...acc, first_name: trimmedData.first_name }
          case 'last_name':
            return { ...acc, last_name: trimmedData.last_name }
          case 'title':
            return { ...acc, title: trimmedData.title }
          case 'biography':
            return { ...acc, biography: trimmedData.biography }
          case 'profile_picture':
            return { ...acc, profile_picture: trimmedData.profile_picture }
          case 'phone':
            if (trimmedData.phone.every((phone) => phone.number === '')) {
              return { ...acc, phone: [] }
            }
            return {
              ...acc,
              phone: trimmedData.phone.map((phone) => ({
                ...phone,
                number: parsePhoneNumberWithError(phone.number).format('E.164'),
              })),
            }
          case 'other_email':
            return { ...acc, other_email: trimmedData.other_email }
          case 'address':
            if (
              trimmedData.address?.[0].address.city === '' &&
              trimmedData.address?.[0].address.state === '' &&
              trimmedData.address?.[0].address.zip === '' &&
              trimmedData.address?.[0].address.street === '' &&
              trimmedData.address?.[0].address.suite === ''
            ) {
              return { ...acc, address: [] }
            }
            return { ...acc, address: trimmedData.address }
          default:
            return acc
        }
      },
      {
        id: user.id,
      } as Partial<typeof trimmedData>
    )

    if (Object.keys(submissionData).length > 1) {
      const file = profilePictureInputRef.current?.files?.[0]
      const updatedUser = await updateUserMutation.mutateAsync(submissionData)

      if (updatedUser.profile_picture?.put_url && file) {
        await updateProfilePictureMutation
          .mutateAsync({
            url: updatedUser.profile_picture?.put_url,
            file,
          })
          .catch(() => {
            console.error('Profile picture update failed')
            toast.addToastNotification({ message: 'Profile picture update failed', severity: 'error' })
            updateUserMutation.mutateAsync({ profile_picture: null })
          })
      }
    }

    setIsEditing(false)
  })

  useEffect(() => {
    setDynamicSchema(formSchema(dirtyFields))
  }, [user, dirtyFields])

  return (
    <form onSubmit={submit}>
      <input type="hidden" name="id" value={user.id} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="tabSection">Name & Title</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                placeholder="First Name"
                fullWidth
                {...register('first_name')}
                error={!!errors?.first_name}
                helperText={errors?.first_name?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                placeholder="Last Name"
                fullWidth
                {...register('last_name')}
                error={!!errors?.last_name}
                helperText={errors?.last_name?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField variant="outlined" label="Title" placeholder="Title" fullWidth {...register('title')} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Bio"
                placeholder="Bio"
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                error={!!errors?.biography}
                helperText={errors?.biography?.message}
                {...register('biography')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <Avatar
                alt="PB"
                src={profilePicture || ''}
                sx={{
                  width: 120,
                  height: 120,
                  mb: 2,
                  border: 1,
                  backgroundColor: '#F0F0F0',
                  borderRadius: 1,
                }}
              />
              <Box display="flex" mt={2}>
                <Typography
                  component="label"
                  variant="body1"
                  sx={{
                    cursor: 'pointer',
                    mr: 2,
                    color: 'primary.main',
                    textDecoration: 'underline',
                  }}
                >
                  Upload New
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    {...register('profile_picture')}
                    ref={profilePictureInputRef}
                    onChange={handlePictureUpload}
                  />
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    cursor: 'pointer',
                    color: 'primary.main',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    setProfilePicture('')
                    setValue('profile_picture', null)
                  }}
                >
                  Remove
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="tabSection">Contact Details</Typography>
          </Grid>

          {phones.map((phone, index) => {
            const isFirst = index === 0
            return (
              <Fragment key={phone.id}>
                {!isFirst && (
                  <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <StyledRemoveIcon onClick={() => removePhone(index)} sx={{ height: '100%', width: '100%' }} />
                  </Grid>
                )}
                <Grid item xs={!isFirst ? 5 : 6}>
                  <TextField
                    variant="outlined"
                    label="Phone"
                    placeholder="+ (---) --- ----"
                    fullWidth
                    {...register(`phone.${index}.number`)}
                    onChange={(e) => {
                      const { value } = e.target
                      const isEmpty = value === ''

                      setValue(`phone.${index}.number`, isEmpty ? '' : countryFormattedPhoneNumber(value), {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                      })
                    }}
                    error={!!errors?.phone?.[index]?.number}
                    helperText={errors?.phone?.[index]?.number?.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label="Extension"
                    placeholder="-"
                    fullWidth
                    {...register(`phone.${index}.extension`)}
                    error={!!errors?.phone?.[index]?.extension}
                    helperText={errors?.phone?.[index]?.extension?.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    value={phone.contact_type || ContactPhoneTypes.WORK}
                    {...register(`phone.${index}.contact_type`)}
                    error={!!errors?.phone?.[index]?.contact_type}
                  >
                    {Object.values(ContactPhoneTypes).map((value, phone_index) => (
                      <MenuItem key={`phone-type-${phone_index}`} value={value}>
                        {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Fragment>
            )
          })}
          <Grid item xs={12} sx={{ display: 'flex', paddingTop: '3px !important' }}>
            <Button onClick={() => appendPhone(initialFormPhone)} color="inherit">
              <Box display="flex" alignItems="center" gap={1}>
                <PlusIcon size={16} />
                <Typography variant="button">Add another phone number</Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography marginY={2} variant="tabSection">
              Primary Email: {user.email}
            </Typography>
          </Grid>
          {emails.map((email, index) => {
            const isFirst = index === 0
            return (
              <Fragment key={email.id}>
                {!isFirst && (
                  <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <StyledRemoveIcon onClick={() => removeEmail(index)} sx={{ height: '100%', width: '100%' }} />
                  </Grid>
                )}
                <Grid item xs={isFirst ? 9 : 8}>
                  <TextField
                    variant="outlined"
                    label="Email"
                    placeholder="info@acme.co"
                    fullWidth
                    error={!!errors?.other_email?.[index]?.address}
                    helperText={errors?.other_email?.[index]?.address?.message}
                    {...register(`other_email.${index}.address`)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    value={email.contact_type || ContactEmailTypes.WORK}
                    {...register(`other_email.${index}.contact_type`)}
                    error={!!errors?.other_email?.[index]?.contact_type}
                  >
                    {Object.values(ContactEmailTypes).map((value, email_index) => (
                      <MenuItem key={`email-type-${email_index}`} value={value}>
                        {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Fragment>
            )
          })}
          <Grid item xs={12} sx={{ display: 'flex', paddingTop: '3px !important' }}>
            <Button onClick={() => appendEmail(initialFormEmail)} color="inherit">
              <Box display="flex" alignItems="center" gap={1}>
                <PlusIcon size={16} />
                <Typography variant="button">Add another email address</Typography>
              </Box>
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              label="Street Address"
              placeholder="Address"
              fullWidth
              {...register('address.0.address.street')}
              error={!!errors?.address?.[0]?.address?.street}
              helperText={errors?.address?.[0]?.address?.street?.message}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label="Suite"
              placeholder="Suite #"
              fullWidth
              {...register('address.0.address.suite')}
              error={!!errors?.address?.[0]?.address?.suite}
              helperText={errors?.address?.[0]?.address?.suite?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="City"
              placeholder="City Name"
              fullWidth
              {...register('address.0.address.city')}
              error={!!errors?.address?.[0]?.address?.city}
              helperText={errors?.address?.[0]?.address?.city?.message}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label="State"
              fullWidth
              select
              // Gotcha: Controlled component, so we need to set the default value manually
              defaultValue={user?.address[0]?.address?.state || ''}
              {...register('address.0.address.state')}
              slotProps={{ select: { MenuProps: USStatesMenuProps } }}
              error={!!errors?.address?.[0]?.address?.state}
              helperText={errors?.address?.[0]?.address?.state?.message}
            >
              <MenuItem value="">Select State</MenuItem>
              {US_STATES.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label="Zip Code"
              fullWidth
              {...register('address.0.address.zip')}
              error={!!errors?.address?.[0]?.address?.zip}
              helperText={errors?.address?.[0]?.address?.zip?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: 'flex', gap: '8px', paddingTop: '16px' }}>
              <DarkPrimaryButton variant="outlined" color="inherit" type="submit">
                Save Changes
              </DarkPrimaryButton>
              <PrimaryCancelButton variant="outlined" color="inherit" type="reset" onClick={() => setIsEditing(false)}>
                Cancel
              </PrimaryCancelButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}
