import { DetailsSection } from '@/components/ui/details-section.tsx'
import { DetailsSectionField, DetailsSectionProps, Job } from '@/types.ts'
import Typography from '@mui/material/Typography'
import { JobDetailsProps } from '@/components/rfps/types.ts'
import theme from '@/theme.ts'
import { formatStringToShortMonthDayYear } from '@/lib/utils.ts'

export const JobDetails = ({ rfpData }: JobDetailsProps) => {
  const jobDetailsData = () => {
    const activeJobIds: Set<string> = new Set(
      rfpData.plants.filter((plant: any) => plant.deleted_at === null && plant.job_id).map((plant: any) => plant.job_id)
    )
    const jobData = rfpData.jobs.filter((job: Job) => job.deleted_at === null && activeJobIds.has(job.id))

    if (!jobData) return []

    return jobData.map((job: Job) => {
      const expectedStartDate = job ? formatStringToShortMonthDayYear(job.job_date || '') : null
      return {
        label: `#${job?.id}`,
        value: `Expected Start Date: ${expectedStartDate || 'TBD'}`,
      }
    })
  }

  const detailsDataTitle = (): DetailsSectionField => {
    const content = (
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        Associated Job(s)
      </Typography>
    )
    return { label: 'Job Details', node: content }
  }

  const defaultNode = () => {
    return (
      <Typography variant="body2" sx={{ color: theme.palette.mediumGrey2.main }}>
        No jobs in this RFP yet
      </Typography>
    )
  }

  const detailsData = {
    title: detailsDataTitle(),
    fields: jobDetailsData(),
    defaultNode: defaultNode(),
  } as DetailsSectionProps

  return DetailsSection(detailsData)
}
