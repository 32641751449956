import { useState } from 'react'

interface UseControlPanelProps {
  defaultTab: string
  defaultFilters?: Record<string, string | string[]>
}
export function useControlPanel({ defaultTab, defaultFilters }: UseControlPanelProps) {
  const [activeTab, setActiveTab] = useState(defaultTab)
  const [filterValues, setFilterValues] = useState(defaultFilters)

  const handleFilterChange = (filter: string, value: string | string[]) => {
    setFilterValues((prev) => ({ ...prev, [filter]: value }))
  }

  return {
    activeTab,
    setActiveTab,
    filterValues,
    handleFilterChange,
  }
}
