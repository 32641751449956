import { DetailsSectionField, DetailsSectionProps } from '@/types.ts'
import { Box, Stack, Table, TableContainer, Typography } from '@mui/material'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import { DetailsSectionText } from '@/components/ui/details-section-text.tsx'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

export const DetailsSection = (details: DetailsSectionProps) => {
  return (
    <Stack sx={{ width: '100%' }} key={details.title.label}>
      <Box sx={{ p: 2 }}>
        {details.title.node ? (
          details.title.node
        ) : (
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            {details?.title.label || 'n/a'}
          </Typography>
        )}
      </Box>
      <CustomDivider />
      <Box sx={{ p: 2 }}>
        <TableContainer>
          <Table>
            <TableBody>
              {details.fields.length ? (
                details.fields.map((detail: DetailsSectionField, index: number) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{ width: '1%', whiteSpace: 'nowrap', borderBottom: 'none', lineHeight: 0, py: 0.5, px: 0 }}
                    >
                      <DetailsSectionText text={`${detail.label}:`} bold={true} />
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 'none', lineHeight: 0, py: 0.5, px: 1 }}>
                      {detail?.node ? detail.node : <DetailsSectionText text={detail?.value} />}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell sx={{ border: 'none', p: 0 }}>{details.defaultNode}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  )
}
