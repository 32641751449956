import api from './api.ts'
import type { AIProcessingData } from '../types.ts'

export async function processFileWithAI({
  organizationId,
  fileId,
  plantListId,
}: {
  organizationId: string
  fileId: string
  plantListId: string
}) {
  const url = '/v1/mdb/ai_processed_plant_list_file/'
  const body = JSON.stringify({
    organization: organizationId,
    uploaded_file: fileId,
    parent_plant_list: plantListId,
  })

  const response = await api.post(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export async function getFileProcessingForPlantLists(
  organization_id: string | undefined,
  parent_plant_list_ids: string[]
) {
  if (!organization_id) {
    throw new Error('Organization ID is required to get AI processed plant list')
  }
  const parent_plant_list_queries = parent_plant_list_ids.map((id) => `parent_plant_list=${id}`).join('&')

  const response = await api.get(
    `/v1/mdb/ai_processed_plant_list_file/?limit=0&organization_id=${organization_id}&${parent_plant_list_queries}`
  )
  return response.data as AIProcessingData[]
}
