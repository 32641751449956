import * as XLSX from 'xlsx'

import { IMAGE_FILE_TYPES, SPREADSHEET_FILE_TYPES } from '@/constants.ts'
import type { GCSFile, ImageFile, SpreadsheetFile } from '@/types.ts'

type FileWithData = (SpreadsheetFile | ImageFile) & {
  rawData: ArrayBuffer
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  const bytes = new Uint8Array(buffer)
  let binary = ''
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

function spreadsheetToHtml(data: ArrayBuffer): string {
  const workbook = XLSX.read(data, { type: 'array' })
  const worksheet = workbook.Sheets[workbook.SheetNames[0]]
  return XLSX.utils.sheet_to_html(worksheet)
}

export async function fetchGCSFile(file: GCSFile): Promise<FileWithData> {
  const response = await fetch(file.get_url, {
    method: 'GET',
    headers: {
      'Content-Type': file.file_type,
    },
  })

  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.status}, ${response.statusText}`)
  }
  const arrayBuffer = await response.arrayBuffer()

  if (SPREADSHEET_FILE_TYPES.includes(file.file_type)) {
    const tableHtml = spreadsheetToHtml(arrayBuffer)
    return {
      ...file,
      tables: [tableHtml],
      rawData: arrayBuffer,
    } as FileWithData
  } else if (IMAGE_FILE_TYPES.includes(file.file_type)) {
    const base64Image = arrayBufferToBase64(arrayBuffer)
    const url = `data:${file.file_type};base64,${base64Image}`
    return {
      ...file,
      url,
      alt: file.user_file_name,
      rawData: arrayBuffer,
    } as FileWithData
  }
  throw new Error('Unsupported file type')
}
