import { useContext } from 'react'
import { ToastNotificationsContext } from '@/contexts/ToastNotificationContext.tsx'

export const useToastNotifications = () => {
  const context = useContext(ToastNotificationsContext)
  if (!context) {
    throw new Error('useNotifications must be used within NotificationsProvider')
  }
  return context
}
