import { z } from 'zod'

import {
  ACCEPTED_FILE_TYPES,
  BusinessTypes,
  ContactAddressTypes,
  ContactEmailTypes,
  ContactPhoneTypes,
  MAX_IMAGE_DIMENSION,
  US_STATES,
} from '../constants'
import { isImageFile, checkImageDimensions, validatePhoneNumber } from './utils.ts'

/** Plant Lists */
export const CreatePlantListFormSchema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
  files: z
    .any()
    .refine((files: FileList) => files && files.length > 0, 'Provide at least one file')
    .refine((files: FileList) => {
      const filesArray = Array.from(files)
      return filesArray.every((file: File) => ACCEPTED_FILE_TYPES.includes(file.type))
    }, 'Only files of the following format are allowed: .jpeg, .jpg, .png, .gif, .webp, .csv, .xlsx, and .pdf.')
    .refine(async (files: FileList) => {
      const filesArray = Array.from(files)
      const imageDimensionChecks = await Promise.all(
        filesArray.map(async (file: File) => {
          if (isImageFile(file)) {
            return await checkImageDimensions(file)
          }
          return true
        })
      )
      return imageDimensionChecks.every(Boolean)
    }, `Image files must not exceed ${MAX_IMAGE_DIMENSION} pixels in width or height.`),
})

export type PlantListForm = z.infer<typeof CreatePlantListFormSchema>

export const FilterPlantListsFormSchema = z.object({
  listNameFilter: z.string().optional(),
  rfpFilter: z.string().optional(),
  dateCreatedRangeFrom: z.date().optional(),
  dateCreatedRangeTo: z.date().optional(),
  emailFilter: z.string().optional(),
})

export const RFPFormSchema = z.object({
  entries: z.array(
    z.object({
      plant_id: z
        .string()
        .nullish()
        .refine((val) => val && val.length > 0, {
          message: 'V1 match is required',
        }),
    })
  ),
})

/**
 * Schema for contact details
 */
const zipRegex = /^\d{5}(-\d{4})?$/

export const BaseAddressSchema = z.object({
  id: z.string().nullable().optional(),
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.enum(US_STATES).or(z.literal('')),
  zip: z.string().regex(zipRegex, 'Invalid zip code'),
  suite: z.string().optional(),
  country: z.string().optional(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

export const ContactAddressSchema = z.object({
  id: z.string().nullable().optional(),
  address: BaseAddressSchema,
  address_type: z.enum(Object.values(ContactAddressTypes) as [string, ...string[]]),
  primary: z.boolean(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

export const ContactPhoneSchema = z.object({
  id: z.string().nullable().optional(),
  number: z.string().refine((value) => validatePhoneNumber(value), {
    message: 'Invalid phone number',
  }),
  extension: z.string().optional(),
  contact_type: z.enum(Object.values(ContactPhoneTypes) as [string, ...string[]]),
  primary: z.boolean(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

export const ContactEmailSchema = z.object({
  id: z.string().nullable().optional(),
  address: z.string().email('Invalid email address'),
  contact_type: z.enum(Object.values(ContactEmailTypes) as [string, ...string[]]),
  primary: z.boolean(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

const contactMethodSchema = z.object({
  phone: z.array(ContactPhoneSchema).optional(),
  email: z.array(ContactEmailSchema).optional(),
})

export const OrganizationContactSchema = contactMethodSchema.extend({
  type: z.literal('organization'),
  address: z.array(ContactAddressSchema),
  organization_name: z.string().min(1, 'Organization name is required'),
  id: z.string().nullable().optional(),
})

export const PersonOrganizationContactSchema = OrganizationContactSchema.extend({
  address: z.array(ContactAddressSchema).optional(),
})

export const PersonContactSchema = contactMethodSchema
  .extend({
    type: z.literal('user'),
    organization_contact: z.array(PersonOrganizationContactSchema).optional(),
    first_name: z.string().min(1, 'First name is required'),
    last_name: z.string().min(1, 'Last name is required'),
  })
  .refine((contact) => (contact.phone && contact.phone.length > 0) || (contact.email && contact.email.length > 0), {
    message: 'At least one phone number or email address is required',
    path: ['contactMethod'],
  })

export const OrganizationLocationSchema = z.object({
  id: z.string().nullable().optional(),
  organization: z.string().optional(),
  name: z.string().optional(),
  address: ContactAddressSchema,
  phone: z.array(ContactPhoneSchema).optional(),
  email: z.array(ContactEmailSchema).optional(),
  primary_location: z.boolean(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

/** Organization Creation */
export const CreateOrganizationSchema = z.object({
  name: z.string().trim().min(1, 'Organization name is required.'),
})

/** Organization Basic Details */
export const OrganizationBasicDetailsSchema = z.object({
  name: z.string().trim().min(1, 'Organization name is required.'),
  primary_business_type: z.enum(Object.values(BusinessTypes) as [string, ...string[]], {
    errorMap: (_issue, _ctx) => ({
      message: 'Primary business type is required.',
    }),
  }),
})

/** Organization Verification Details */
export const OrganizationVerificationDetailsSchema = z.object({
  legal_name: z
    .string()
    .nullish()
    .transform((val) => val ?? '')
    .refine((val) => val.trim().length > 0, 'Legal business name is required.'),
  tax_id: z
    .string()
    .nullish()
    .transform((val) => val ?? '')
    .refine((val) => val.trim().length > 0, 'Employer Identification Number is required.'),
})

/** Organization Invites */
export const InviteUserOrganizationSchema = z.object({
  email: z.string().email('Invalid email address'),
  role: z.string().min(1, 'Role is required.'),
})

/** User Updates **/
export const UserProfileSchema = contactMethodSchema.extend({
  id: z.string(),
  first_name: z.string().min(1, 'First name is required.'),
  last_name: z.string().min(1, 'Last name is required.'),
  email: z.string().email('Invalid email address.'),
  address: z.array(ContactAddressSchema).optional(),
  profile_picture: z.any().nullable().optional(),
  timezone: z.string().nullable().optional(),
  other_email: z.array(ContactEmailSchema).optional(),
  title: z.string().nullable().optional(),
  biography: z.string().nullable().optional(),
})

export const UpdateUserTimezoneSchema = z.object({
  id: z.string(),
  timezone: z.string().nullable().optional(),
})

export const NotificationScheduleSchema = z.object({
  start_time: z.string().nullable(),
  end_time: z.string().nullable(),
  days_of_week: z.array(z.string()),
})

export const OrganizationMemberNotificationSettingsSchema = z.object({
  schedule: NotificationScheduleSchema,
  default_reminder_time: z.string().nullable(),
  channels: z.array(z.enum(['email', 'text_message'])),
  subscribed_categories: z.array(z.enum(['all', 'rfp', 'quote', 'order', 'job', 'invoice', 'delivery'])),
})

export const CreateJobForRFPSchema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
  start_date: z.date(),
})

export const CreateNewRfpSchema = z.object({
  name: z.string().optional(),
  collaborators: z.array(z.string()).optional(),
  quote_needed_by: z.date(),
  organization: z.string(),
})
