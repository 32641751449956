import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import PlantListEntryForm from '@/components/plant-list-importer/plant-list-entry-form'
import type { PlantListEntry } from '@/types'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { ChevronLeftIcon, ChevronRightIcon, X } from 'lucide-react'
import theme from '@/theme.ts'
import { useEffect, useState } from 'react'

type EditPlantListEntryModalProps = {
  open: boolean
  onClose: () => void
  entry: PlantListEntry
  plantListEntries: PlantListEntry[]
  onUpdate: (entry: PlantListEntry) => void
  onSwitchToPlant: (entry: PlantListEntry) => void
  optionsEnums: Record<string, any> | undefined
  modalPosition?: { top: string; left: string; transformX: string; transformY: string }
  hideBackdrop?: boolean
}
export default function EditPlantListEntryModal({
  open,
  onClose,
  entry,
  plantListEntries,
  onUpdate,
  onSwitchToPlant,
  optionsEnums,
  modalPosition = { top: '50%', left: '50%', transformX: '-50%', transformY: '-50%' },
  hideBackdrop = false,
}: EditPlantListEntryModalProps) {
  const [currentEntryIndex, setCurrentEntryIndex] = useState(plantListEntries.findIndex((e) => e.id === entry.id))
  const [currentEntry, setCurrentEntry] = useState(entry)

  useEffect(() => {
    setCurrentEntry(entry)
  }, [entry])

  const handleUpdate = (updatedEntry: PlantListEntry) => {
    setCurrentEntry(updatedEntry)
    onUpdate(updatedEntry)
  }

  useEffect(() => {
    const newIndex = plantListEntries.findIndex((e) => e.id === entry.id)
    if (newIndex !== currentEntryIndex) {
      setCurrentEntryIndex(newIndex)
      if (open && newIndex !== -1) {
        onSwitchToPlant(plantListEntries[newIndex])
      }
    }
  }, [entry.id, plantListEntries, currentEntryIndex, open, onSwitchToPlant])

  const handlePrevious = () => {
    if (currentEntryIndex > 0) {
      const newIndex = currentEntryIndex - 1
      setCurrentEntryIndex(newIndex)
      onSwitchToPlant(plantListEntries[newIndex])
    }
  }

  const handleNext = () => {
    if (currentEntryIndex < plantListEntries.length - 1) {
      const newIndex = currentEntryIndex + 1
      setCurrentEntryIndex(newIndex)
      onSwitchToPlant(plantListEntries[newIndex])
    }
  }
  return (
    <Modal
      open={open}
      onClose={hideBackdrop ? undefined : onClose}
      closeAfterTransition
      hideBackdrop={hideBackdrop}
      sx={{
        pointerEvents: hideBackdrop ? 'none' : 'auto',
        '& .MuiModal-backdrop': {
          pointerEvents: hideBackdrop ? 'none' : 'auto',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: modalPosition?.top,
          left: modalPosition?.left,
          transform: `translate(${modalPosition?.transformX}, ${modalPosition?.transformY})`,
          width: '50%',
          maxWidth: 1200,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          pr: 0,
          borderRadius: theme.borderRadius.xl,
          pointerEvents: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="modalHeader2">Edit Plant Details</Typography>
            <Typography variant="body2" color="textSecondary">
              Changes are saved automatically
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pr: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handlePrevious} disabled={currentEntryIndex <= 0} size="small">
                <ChevronLeftIcon />
              </IconButton>
              <Typography sx={{ mx: 2 }}>
                {currentEntryIndex + 1} of {plantListEntries.length}
              </Typography>
              <IconButton onClick={handleNext} disabled={currentEntryIndex >= plantListEntries.length - 1} size="small">
                <ChevronRightIcon />
              </IconButton>
            </Box>
            <IconButton onClick={onClose} size="small">
              <X />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ maxHeight: 600, overflow: 'auto', pr: 4 }}>
          <PlantListEntryForm
            entry={currentEntry}
            onUpdate={handleUpdate}
            optionsEnums={optionsEnums}
            id={`plant-modal-${entry.id}`}
          />
        </Box>
      </Box>
    </Modal>
  )
}
