import { HTMLAttributes, ReactNode, useState } from 'react'
import { ElasticsearchResponse, Plant } from '@/types.ts'
import { useQuery } from '@tanstack/react-query'
import { basicSearchV1Plant } from '@/api/elasticsearch.ts'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import { Plus } from 'lucide-react'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

type RenderOptionProps = HTMLAttributes<HTMLLIElement> & {
  key?: string
  'data-option-index'?: number
}

type V2PlantAutocompleteSearchProps = {
  onChange: (value: Plant | null) => void
  placeHolder?: string
  startAdornment?: ReactNode
  decorationsOnFocusOnly?: boolean
}

export function V2PlantAutocompleteSearch({
  onChange,
  startAdornment,
  placeHolder = 'Search for a plant...',
  decorationsOnFocusOnly = false,
}: V2PlantAutocompleteSearchProps) {
  const [inputValue, setInputValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [open, setOpen] = useState(false)

  const { data: searchResults, isFetching: isSearching } = useQuery({
    queryKey: ['v2-plant-search', searchTerm],
    queryFn: () => basicSearchV1Plant(searchTerm) as Promise<ElasticsearchResponse<Plant>>,
    enabled: searchTerm.length > 2,
  })

  const handleClose = () => {
    setOpen(false)
    setInputValue('')
    setSearchTerm('')
  }

  return (
    <Autocomplete
      open={searchTerm.length > 2 && open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      options={searchResults?.results || []}
      getOptionLabel={(option) => `${option.common_names[0] || 'N/A'} (${option.scientific_name})`}
      sx={{
        minWidth: 300,
        '& .MuiOutlinedInput-root': decorationsOnFocusOnly
          ? {
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'inherit',
              },
            }
          : {},
      }}
      filterOptions={(options) => options}
      renderOption={(props: RenderOptionProps, option) => {
        const { key, ...optionsProps } = props
        return (
          <Box key={key} component="li" {...optionsProps}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, py: 2 }}>
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: theme.palette.black.main,
                  borderRadius: theme.borderRadius.circle,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Plus size={18} color="white" />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography variant="body1" textTransform="capitalize">
                  {option.common_names[0] || 'N/A'}
                </Typography>
                <Typography variant="body1" textTransform="capitalize">
                  ({option.scientific_name})
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeHolder}
          slotProps={{
            input: {
              ...params.InputProps,
              startAdornment,
              endAdornment: (
                <>
                  {isSearching && <CircularProgress color="inherit" size={20} />}
                  {!decorationsOnFocusOnly && params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
      loading={isSearching}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'input') {
          setInputValue(newInputValue)
          setSearchTerm(newInputValue)
        } else {
          // clear input
          setInputValue('')
        }
      }}
      onChange={(_event, newValue) => {
        onChange(newValue)
        // Close the dropdown after selecting a plant
        handleClose()
      }}
      value={null}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option._id === value?._id}
      clearOnBlur={false}
      selectOnFocus={true}
      disableCloseOnSelect
    />
  )
}
