import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createPlantList, PlantListRequest } from '@/api/plants'
import { Box, Modal, Typography, CircularProgress, Stack } from '@mui/material'
import FileUploader from '@/components/ui/base/file-uploader'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons'
import ModalHeader from '@/components/ui/base/modal-header'
import theme from '@/theme'
import { PlantList, PlantListEntry } from '@/types'
import { PlantListTableContainer } from '@/components/plant-list-details/plant-list-table-container'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import FileViewer from '@/components/plant-list-details/file-viewer.tsx'
import { getPlantListById } from '@/api/plant-list.ts'

interface CreatePlantListForRFPModalProps {
  open: boolean
  onClose: () => void
  onAddPlants: (plants: PlantListEntry[]) => Promise<void>
}
export default function CreatePlantListForRFPModal({ open, onClose, onAddPlants }: CreatePlantListForRFPModalProps) {
  const { selectedOrganization } = useOrganization()
  const [files, setFiles] = useState<File[]>([])
  const [fileViewerFileId, setFileViewerFileId] = useState<string | null>(null)
  const [createdPlantList, setCreatedPlantList] = useState<PlantList | null>(null)
  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())
  const [selectedFiles, setSelectedFiles] = useState<string[]>([])

  // Query for fetching plant list details
  const { data: updatedPlantList, refetch: refetchPlantList } = useQuery({
    queryKey: ['plant-list', createdPlantList?.id],
    queryFn: () => {
      if (!selectedOrganization?.id || !createdPlantList?.id) return null
      return getPlantListById(createdPlantList.id)
    },
    enabled: !!createdPlantList?.id,
  })

  const createPlantListMutation = useMutation({
    mutationFn: createPlantList,
    onSuccess: async (newPlantList) => {
      setCreatedPlantList(newPlantList)
      // Set all files as selected by default
      setSelectedFiles(newPlantList.files.map((f) => f.id))
    },
  })

  const handleFilesAdded = (newFiles: File[]) => {
    setFiles((prev) => [...prev, ...newFiles])
  }

  const handleFileDeleted = (file: File) => {
    setFiles((prev) => prev.filter((f) => f !== file))
  }

  const handleSubmit = async () => {
    if (files.length === 0) return

    const plantListRequest: PlantListRequest = {
      name: files[0].name,
      files: files,
      organization: selectedOrganization?.id,
    }
    await createPlantListMutation.mutateAsync(plantListRequest)
  }

  const handleAddSelectedToRFP = async () => {
    if (!createdPlantList || !updatedPlantList) {
      return
    }

    const selectedPlantEntries = updatedPlantList.entries.filter((entry) => selectedPlants.has(entry.id))

    await onAddPlants(selectedPlantEntries)
    handleClose()
  }

  const handleCropComplete = (croppedFile: File, selectedFileIndex: number) => {
    setFiles((prev) => prev.map((file, index) => (index === selectedFileIndex ? croppedFile : file)))
  }

  const handleClose = () => {
    setFiles([])
    setCreatedPlantList(null)
    setSelectedPlants(new Set())
    setSelectedFiles([])
    onClose()
  }

  const handleFileProcessed = async () => {
    await refetchPlantList()
  }

  const availableFiles = createdPlantList?.files.filter((file) => !file.deleted_at) || []

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: createdPlantList ? '90vw' : '850px',
          maxHeight: createdPlantList ? '1000px' : '650px',
          bgcolor: 'white',
          boxShadow: 24,
          p: 5,
          borderRadius: theme.borderRadius.md,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <ModalHeader title="Upload Plant List" />
          {createdPlantList && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 3 }}>
              <PrimaryCancelButton onClick={handleClose}>Cancel &amp; Close</PrimaryCancelButton>
              <DarkPrimaryButton onClick={handleAddSelectedToRFP} disabled={selectedPlants.size === 0}>
                Add Selected Plants to RFP
              </DarkPrimaryButton>
            </Box>
          )}
        </Stack>
        {!createdPlantList ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Upload a Plant List to add plants to your RFP
            </Typography>

            <FileUploader
              files={files}
              onFilesAdded={handleFilesAdded}
              onFileDeleted={handleFileDeleted}
              onCropComplete={handleCropComplete}
              validationError={null}
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
              <PrimaryCancelButton onClick={handleClose}>Cancel</PrimaryCancelButton>
              <DarkPrimaryButton
                onClick={handleSubmit}
                disabled={files.length === 0 || createPlantListMutation.isPending}
              >
                {createPlantListMutation.isPending ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
              </DarkPrimaryButton>
            </Box>
          </Box>
        ) : (
          <Stack direction="row" spacing={2} sx={{ maxHeight: '850px' }}>
            <Box sx={{ overflow: 'auto', width: '65%' }}>
              <PlantListTableContainer
                plantList={updatedPlantList || createdPlantList}
                selectedFiles={selectedFiles}
                searchFilteredPlants={new Set(['all'])}
                selectedPlants={selectedPlants}
                setSelectedPlants={setSelectedPlants}
                setTargetFileId={setFileViewerFileId}
                onFileProcessed={handleFileProcessed}
              />
            </Box>
            <Box sx={{ overflow: 'auto', width: '35%' }}>
              <FileViewer
                files={availableFiles}
                onFileDelete={refetchPlantList}
                fileId={fileViewerFileId}
                onFileIdChange={setFileViewerFileId}
              />
            </Box>
          </Stack>
        )}
      </Box>
    </Modal>
  )
}
