import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import Typography from '@mui/material/Typography'
import { OrganizationDetails } from '@/components/ui/organization-details.tsx'
import { Stack } from '@mui/material'
import { RFPDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-rfp-details.tsx'
import { JobDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-job-details.tsx'
import type { RFPStepProps } from '@/components/rfps/types.ts'
import { MouseEvent, useCallback, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { Add, AddCircle, KeyboardArrowDownOutlined } from '@mui/icons-material'
import { ConfirmationModalProps, Job, PlantListEntry, PlantWithQuantity } from '@/types.ts'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { DEFAULT_PLANT_LIST_ENTRY } from '@/constants.ts'
import AddPlantsModal from '@/components/ui/modals/add-plants-modal.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import RFPComments from '@/components/rfps/rfp-comments.tsx'
import AddPlantsFromListModal from '@/components/ui/modals/add-plants-from-list-modal.tsx'
import EditRfpDetailsModal from '@/components/ui/modals/edit-rfp-details-modal'
import RfpPlantTable from '@/components/rfps/rfp-plant-table.tsx'
import IconButton from '@mui/material/IconButton'
import {
  ArrowUpToLine,
  CirclePlus,
  EllipsisVertical,
  LucideBan,
  LucideLeaf,
  LucideMountainSnow,
  Pencil,
} from 'lucide-react'
import { generateObjectId, excludeDeletedItems, formatStringToShortMonthDayYear } from '@/lib/utils.ts'
import AddPlantsFromJobsModal from '@/components/ui/modals/add-plants-from-jobs-modal'
import { ConfirmationModal } from '@/components/ui/modals/confirmation-modal.tsx'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import CreatePlantListForRFPModal from '@/components/rfps/modals/create-plant-list-for-rfp-modal.tsx'
import AddJobsToRFPModal from '@/components/ui/modals/add-jobs-to-rfp-modal.tsx'
import { useNavigate } from '@tanstack/react-router'

export const RFPPageContent = ({
  rfpData,
  onUpdateRfpData,
  editMode = false,
  showHeader = true,
  sentRfp,
  displayEditButton = false,
  allowPlantTableReorder = false,
  allowPlantTableQuantityChange = false,
  showPlantTableMenu = false,
}: RFPStepProps) => {
  const navigate = useNavigate()
  const rfpOrganization = rfpData?.organization || {}
  const plants = rfpData?.plants || []
  const [jobPlantsMenuAnchorEl, setJobPlantsMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [jobMenuAnchorEl, setJobMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [activeJobId, setActiveJobId] = useState('')
  const [selectedPlantIds, setSelectedPlantIds] = useState<Set<string>>(new Set())
  const [addPlantsFromJobModalOpen, setAddPlantsFromJobModalOpen] = useState(false)
  const [addPlantsFromListModalOpen, setAddPlantsFromListModalOpen] = useState(false)
  const [createPlantListModalOpen, setCreatePlantListModalOpen] = useState(false)
  const [addPlantsModalOpen, setAddPlantsModalOpen] = useState(false)
  const [editRfpDetailsModalOpen, setEditRfpDetailsModalOpen] = useState(false)
  const [confirmationModalContent, setConfirmationModalContent] = useState<ConfirmationModalProps | null>(null)
  const [addJobsToRFPModalOpen, setAddJobsToRFPModalOpen] = useState('')

  const handleSelectionChange = useCallback(
    (selectedIds: Set<string>) => {
      setSelectedPlantIds(selectedIds)

      if (sentRfp) {
        const updatedPlants = sentRfp.plants.map((plant: PlantListEntry) =>
          selectedIds.has(plant.id)
            ? { ...plant, deleted_at: null }
            : { ...plant, deleted_at: new Date().toISOString() }
        )
        onUpdateRfpData({ plants: updatedPlants })
      }
    },
    [onUpdateRfpData, sentRfp]
  )

  useEffect(() => {
    if (sentRfp) {
      const sentRfpPlantIds = sentRfp.plants.map((plant: PlantListEntry) => plant.id)
      setSelectedPlantIds(new Set(sentRfpPlantIds))
    }
  }, [sentRfp])

  if (!rfpData && !sentRfp) {
    return null
  }

  const jobGroups = excludeDeletedItems(rfpData?.jobs).reduce(
    (acc: Record<string, Job>, job: Job) => {
      acc[job.id] = job
      return acc
    },
    {} as Record<string, any>
  ) || {}

  const plantsByJobGroup = excludeDeletedItems(rfpData?.plants).reduce(
    (acc: Record<string, PlantListEntry[]>, plant: PlantListEntry) => {
      const jobId = plant.job_id || 'null'
      if (!acc[jobId]) {
        acc[jobId] = []
      }
      acc[jobId].push(plant)
      return acc
    },
    {} as Record<string, PlantListEntry[]>
  )

  const handlePlantMenuClick = (event: MouseEvent<HTMLElement>, jobId: string) => {
    setJobPlantsMenuAnchorEl(event.currentTarget)
    setActiveJobId(jobId)
  }

  const handleJobMenuClick = (event: MouseEvent<HTMLElement>, jobId: string) => {
    setJobMenuAnchorEl(event.currentTarget)
    setActiveJobId(jobId)
  }

  const handlePlantsUpdate = (updatedPlants: PlantListEntry[]) => {
    onUpdateRfpData({ plants: updatedPlants })
    setActiveJobId('')
  }

  const handleAddJobButtonClick = () => {
    setActiveJobId('')
    setAddJobsToRFPModalOpen('newJob')
  }

  const handleRemovePlants = () => {
    const updatedPlantEntries = [...(rfpData?.plants || [])].map((entry) => {
      if (selectedPlantIds.has(entry.id)) {
        return {
          ...entry,
          deleted_at: new Date().toISOString(),
        }
      }
      return entry
    })
    handlePlantsUpdate(updatedPlantEntries)
  }

  const isPlantWithQuantity = (plant: PlantWithQuantity | PlantListEntry): plant is PlantWithQuantity => {
    return (plant as PlantWithQuantity).plant !== undefined
  }

  const handleAddJobsToRFP = async (jobIds: Set<string>) => {
    if (jobIds.size > 0) {
      const existingJobIds = excludeDeletedItems(rfpData.jobs).map((job: Job) => job.id)
      const updatedJobIds = new Set([...existingJobIds, ...jobIds])
      const updatedRfpData = { ...rfpData, jobs: Array.from(updatedJobIds) }
      onUpdateRfpData(updatedRfpData)
    }
  }

  const handleAddPlants = async (plants: PlantWithQuantity[] | PlantListEntry[]) => {
    const updatedPlantEntries = [...(rfpData?.plants || [])]

    for (const plant of plants) {
      const newId = generateObjectId()
      const jobId = activeJobId ? activeJobId : plant?.job_id || null
      let newPlant: PlantListEntry

      if (isPlantWithQuantity(plant)) {
        // Handle PlantWithQuantity type
        newPlant = {
          ...DEFAULT_PLANT_LIST_ENTRY,
          id: newId,
          job_id: jobId,
          quantity_count: { min: plant.quantity, max: null },
          scientific_name: plant.plant.scientific_name,
          common_name: plant.plant.common_names[0],
          parent_of_order: null,
        }
      } else {
        // Handle PlantListEntry type
        newPlant = {
          ...plant,
          job_id: jobId,
          parent_of_order: null,
        }
      }

      const currentLastEntry = updatedPlantEntries.find((entry) => entry.parent_of_order === null)
      if (currentLastEntry) {
        currentLastEntry.parent_of_order = isPlantWithQuantity(plant) ? newId : plant.id
      }
      updatedPlantEntries.push(newPlant)
    }

    setActiveJobId('')
    handlePlantsUpdate(updatedPlantEntries)
  }

  const AddMissingPlantsFromJobButton = ({ jobId }: { jobId: string }) => {
    const jobData = jobGroups[jobId]
    const jobPlantTotalCount = excludeDeletedItems(jobData?.plants).length
    const jobPlantInRfpCount = excludeDeletedItems(plantsByJobGroup[jobId]).length
    const missingPlantsCount = jobPlantTotalCount - jobPlantInRfpCount

    if (missingPlantsCount < 1) return null

    const handleMissingPlantClick = () => {
      setActiveJobId(jobId)
      setAddPlantsFromJobModalOpen(true)
    }

    return (
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          py: 2,
          pl: 7.5,
          borderBottom: '1px solid',
          borderColor: theme.palette.lightGrey2.main,
        }}
      >
        <Stack direction="row" sx={{ cursor: 'pointer' }} onClick={handleMissingPlantClick}>
          <AddCircle sx={{ mr: 2, color: theme.palette.primary.main }} />
          <Typography color="textSecondary">
            {pluralizedLabel(missingPlantsCount, 'Plant', 'Plants')} not included in this RFP
          </Typography>
        </Stack>
      </Stack>
    )
  }

  const RequestPricingHeader = () => {
    let title = ''
    const defaultTitle = 'Request Pricing for Plants'

    const validPlants = excludeDeletedItems(plants)

    if (validPlants.length > 0) {
      // should total quantity should be made based on min/max count ?
      // for now, priority is given to min quantity when available
      const totalItemCount = validPlants.reduce(
        (acc: number, plant: any) => acc + (plant.quantity_count?.min || plant.quantity_count?.max || 0),
        0
      )
      title = `Request Pricing for ${validPlants.length} Plants (${totalItemCount} Items)`
    }

    return (
      <Typography variant="body1" sx={{ fontWeight: 700, pl: 2 }}>
        {title || defaultTitle}
      </Typography>
    )
  }

  const JobPlantsMenu = () => {
    const jobData = jobGroups[activeJobId]
    const jobPlantTotalCount = excludeDeletedItems(jobData?.plants).length

    const handleClose = () => {
      setJobPlantsMenuAnchorEl(null)
    }

    const handleAddJobPlants = () => {
      handleClose()
      setAddPlantsFromJobModalOpen(true)
    }

    const handleAddPlantsFromList = () => {
      handleClose()
      setAddPlantsFromListModalOpen(true)
    }

    const handleAddPlantsManually = () => {
      handleClose()
      setAddPlantsModalOpen(true)
    }

    const handleAddPlantsFromFileUpload = () => {
      handleClose()
      setCreatePlantListModalOpen(true)
    }

    return (
      <Box>
        <Menu
          anchorEl={jobPlantsMenuAnchorEl}
          open={Boolean(jobPlantsMenuAnchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 200,
            },
          }}
        >
          <Typography variant="strong" sx={{ px: 2, py: 1 }}>
            {jobPlantTotalCount} Plants in this Job
          </Typography>
          <MenuItem onClick={handleAddJobPlants} disabled={jobPlantTotalCount === 0}>
            Select Plants to include in RFP ...
          </MenuItem>
          <Divider />
          <Typography variant="strong" sx={{ px: 2, py: 1 }}>
            Add New Plants to Job
          </Typography>
          <MenuItem onClick={handleAddPlantsFromList}>Add from Existing Plant List ...</MenuItem>
          <MenuItem onClick={handleAddPlantsFromFileUpload}>Add from File Upload ...</MenuItem>
          <MenuItem onClick={handleAddPlantsManually}>Add Manually ...</MenuItem>
        </Menu>
      </Box>
    )
  }

  const JobMenu = () => {
    const jobData = jobGroups[activeJobId]
    const plantsInJobCount = excludeDeletedItems(plantsByJobGroup[activeJobId]).length

    const handleClose = () => {
      setJobMenuAnchorEl(null)
    }

    const removeJobFromRfp = () => {
      const updatedPlantEntries = (rfpData?.plants || []).map((entry: PlantListEntry) =>
        entry.job_id === activeJobId && entry.deleted_at === null
          ? { ...entry, deleted_at: new Date().toISOString() }
          : entry
      )
      const updatedJobs = (rfpData?.jobs || []).filter((job: Job) => job.id !== activeJobId)
      onUpdateRfpData({ plants: updatedPlantEntries, jobs: updatedJobs })
      setConfirmationModalContent(null)
      setActiveJobId('')
    }

    const handleRemoveJob = () => {
      handleClose()
      setConfirmationModalContent({
        open: true,
        title: 'Remove Job from RFP',
        message: `Are you sure you want to remove ${
          activeJobId === 'null'
            ? `the ${plantsInJobCount} unassigned plants from this RFP ?`
            : `${jobData.name} (Job #${activeJobId}) and its plants from this RFP ?`
        }`,
        onConfirm: removeJobFromRfp,
        onCancel: () => {
          setConfirmationModalContent(null)
        },
      })
    }

    const handleJobPhase = () => {
      console.log('Add a New Phase to Job')
      handleClose()
    }

    const handleHidePhases = () => {
      console.log("Hide This Job's Phases from Vendor")
      handleClose()
    }

    const handleRequestImages = () => {
      console.log('Request Images of All Items')
      handleClose()
    }

    return (
      <Box>
        <Menu
          anchorEl={jobMenuAnchorEl}
          open={Boolean(jobMenuAnchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 200,
            },
          }}
        >
          <MenuItem onClick={handleRemoveJob}>Remove Job from RFP</MenuItem>
          <MenuItem onClick={handleJobPhase}>Add a New Phase to Job</MenuItem>
          <MenuItem onClick={handleHidePhases}>Hide This Job's Phases from Vendor</MenuItem>
          <MenuItem onClick={handleRequestImages}>Request Images of All Items</MenuItem>
        </Menu>
      </Box>
    )
  }

  const NewRFPDefaultContent = () => {
    const buttons = [
      {
        onClick: () => setAddJobsToRFPModalOpen('newJob'),
        startIcon: <CirclePlus fontSize="small" color={theme.palette.darkGrey.main} size={16} />,
        text: 'From a New Job',
      },
      {
        onClick: () => setAddJobsToRFPModalOpen('existingJob'),
        startIcon: <LucideMountainSnow fontSize="small" color={theme.palette.darkGrey.main} size={16} />,
        text: 'From an existing Job',
      },
      {
        onClick: () => setAddPlantsFromListModalOpen(true),
        startIcon: <LucideLeaf fontSize="small" color={theme.palette.darkGrey.main} size={16} />,
        text: 'From an existing Plant List ...',
      },
      {
        onClick: () => setCreatePlantListModalOpen(true),
        startIcon: <ArrowUpToLine fontSize="small" color={theme.palette.darkGrey.main} size={16} />,
        text: 'From File Upload ...',
      },
    ]
    return (
      <Stack sx={{ gap: 2 }}>
        {/* Header with title and summary of plants in table */}
        <RequestPricingHeader />

        <CustomDivider />

        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.lightGrey3.main,
            border: '1px solid',
            borderColor: theme.palette.lightGrey2.main,
            borderRadius: theme.borderRadius.lg,
          }}
        >
          <Stack sx={{ py: 6, gap: 1, alignItems: 'center' }}>
            <Typography variant="h3" sx={{ mb: 1 }}>
              Add plants to this RFP:
            </Typography>
            {buttons.map((button, index) => (
              <TextIconButton
                key={index}
                onClick={button.onClick}
                startIcon={button.startIcon}
                text={button.text}
                variant="contained"
                bgColor="white"
                sx={{
                  width: 300,
                  borderRadius: theme.borderRadius.sm,
                  color: theme.palette.darkGrey.main,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  fontWeight: 400,
                }}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    )
  }

  const RfpJobDefaultContentMenu = ({
    anchorEl,
    handleClose,
  }: {
    anchorEl: HTMLElement | null
    handleClose: () => void
  }) => {
    const handleAddPlantsFromList = () => {
      handleClose()
      setAddPlantsFromListModalOpen(true)
    }

    const handleAddPlantsManually = () => {
      handleClose()
      setAddPlantsModalOpen(true)
    }

    const handleAddPlantsFromFileUpload = () => {
      handleClose()
      setCreatePlantListModalOpen(true)
    }

    return (
      <Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 200,
            },
          }}
        >
          <MenuItem onClick={handleAddPlantsFromList}>From Existing Plant List ...</MenuItem>
          <MenuItem onClick={handleAddPlantsFromFileUpload}>From File Upload ...</MenuItem>
          <MenuItem onClick={handleAddPlantsManually}>Add Manually ...</MenuItem>
        </Menu>
      </Box>
    )
  }

  const RfpJobDefaultContent = ({ jobId }: { jobId: string }) => {
    const jobData = jobGroups[jobId]
    const jobPlantCount = excludeDeletedItems(jobData?.plants).length
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

    const handleDropdownMenuClick = (event: MouseEvent<HTMLElement>) => {
      setActiveJobId(jobId)
      setMenuAnchorEl(event.currentTarget)
    }

    const handleAddPlantsFromJobClick = () => {
      setActiveJobId(jobId)
      setAddPlantsFromJobModalOpen(true)
    }

    const handleMenuClose = () => {
      setMenuAnchorEl(null)
    }

    return (
      <Stack
        key={`rfp-job-default-content-${jobId}`}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ py: 6, gap: 1, alignItems: 'center' }}>
          {jobPlantCount ? (
            <Stack sx={{ gap: 1, alignItems: 'center' }}>
              <LucideLeaf color={theme.palette.darkGrey.main} size={18} />
              <Typography variant="body1" sx={{ fontWeight: 400, mb: 1 }}>
                {pluralizedLabel(jobPlantCount, 'Plant', 'Plants')} found in this Job
              </Typography>
              <TextIconButton
                text="Select plants to include in RFP ..."
                onClick={handleAddPlantsFromJobClick}
                startIcon={<AddCircle sx={{ color: theme.palette.primary.main }} />}
                variant="outlined"
                borderColor={theme.palette.lightGrey2.main}
                bgColor="white"
                sx={{
                  px: 2,
                  borderRadius: theme.borderRadius.sm,
                  color: theme.palette.darkGrey.main,
                  boxShadow: '0px 3px 8px 0px #1B1B1B1A',
                  fontWeight: 400,
                }}
              />
            </Stack>
          ) : (
            <Stack sx={{ gap: 1, alignItems: 'center' }}>
              <LucideBan color={theme.palette.darkGrey.main} size={18} />
              <Typography variant="body1">No plants found in this Job</Typography>
            </Stack>
          )}
          <TextIconButton
            text="Add new plants to this Job"
            onClick={handleDropdownMenuClick}
            endIcon={<KeyboardArrowDownOutlined />}
            variant="outlined"
            bgColor="white"
            borderColor={theme.palette.lightGrey2.main}
            sx={{
              px: 2,
              borderRadius: theme.borderRadius.sm,
              color: theme.palette.black.main,
              boxShadow: 'none',
              fontWeight: 400,
            }}
          />
          <RfpJobDefaultContentMenu anchorEl={menuAnchorEl} handleClose={handleMenuClose} />
        </Stack>
      </Stack>
    )
  }

  const handleEditPlantList = () => {
    // Telling TypeScript to ignore the type error for now since I'm not sure how to fix it yet.
    navigate({
      search: {
        // @ts-ignore
        step: 'add-vendors',
      },
    })
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        backgroundColor: 'white',
        borderRadius: theme.borderRadius.lg,
      }}
    >
      <Stack
        sx={{
          backgroundColor: 'white',
          borderRadius: theme.borderRadius.lg,
          overflow: 'hidden',
          p: 5.5,
          gap: 3,
        }}
      >
        {showHeader && (
          <>
            {/* Organization details & RFP# */}
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <OrganizationDetails organization={rfpOrganization} />
              <Typography variant="h3" fontWeight={400} sx={{ color: theme.palette.mediumGrey2.main }}>
                RFP# {rfpData.id}
              </Typography>
            </Stack>

            {/* RFP Details & Job Details */}
            <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2 }}>
              <RFPDetails
                openEditRfpModal={() => {
                  setEditRfpDetailsModalOpen(true)
                }}
                rfpData={rfpData}
                editMode={editMode}
              />
              <JobDetails rfpData={rfpData} />
            </Stack>
          </>
        )}

        {/* Jobs/Phases & Group */}
        {Object.keys(jobGroups).length ? (
          <Stack key="rfp-job-groups">
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                mb: 1,
                alignItems: 'center',
              }}
            >
              <RequestPricingHeader />

              {/* Bulk Actions & Add Plants - visible only in edit mode */}
              {editMode && (
                <Stack direction="row" sx={{ gap: 1 }}>
                  <Button
                    variant="outlined"
                    aria-label="remove-plants-rfp"
                    onClick={handleRemovePlants}
                    disabled={selectedPlantIds.size === 0}
                    sx={{
                      color: theme.palette.darkGrey.main,
                      borderRadius: theme.borderRadius.sm,
                      borderColor: theme.palette.lightGrey2.main,
                    }}
                  >
                    <Typography variant="button">Remove Selected from RFP</Typography>
                  </Button>

                  {/* Add Job */}
                  <Button
                    variant="outlined"
                    aria-label="add-job-rfp"
                    onClick={handleAddJobButtonClick}
                    sx={{
                      borderRadius: theme.borderRadius.sm,
                      borderColor: theme.palette.lightGrey2.main,
                      color: theme.palette.darkGrey.main,
                    }}
                  >
                    <Add fontSize="small" />
                    <Typography variant="button">Add Job</Typography>
                  </Button>
                </Stack>
              )}

              {displayEditButton && (
                <Button variant="text" onClick={handleEditPlantList} sx={{ gap: 1 }}>
                  <Pencil size={18} color={theme.palette.text.secondary} />
                  <Typography variant="button" color={'textSecondary'} sx={{ textDecoration: 'underline' }}>
                    Edit Plant List
                  </Typography>
                </Button>
              )}
            </Stack>

            <CustomDivider />

            {/* Plant Table */}
            {Object.entries(jobGroups).map(([jobId, data]: [string, unknown], index: number) => {
              const jobData = data as Job
              const plants: PlantListEntry[] = plantsByJobGroup[jobId] || []
              const expectedStartDate = formatStringToShortMonthDayYear(jobData.job_date as string) || 'TBD'
              return (
                <Stack key={`rfp-job-${jobId}`}>
                  <Stack direction="row" sx={{ pt: 4, pb: 1, pl: 2, pr: 0, justifyContent: 'space-between' }}>
                    <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                      <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                        <Typography fontWeight={700} variant="body1">
                          Job #{jobId}
                        </Typography>
                        <Typography fontWeight={400} variant="body2">
                          -
                        </Typography>
                        <Typography fontWeight={400} variant="body2">
                          {jobData.name}
                        </Typography>
                      </Stack>
                      <Typography fontWeight={400} variant="body2" color="textSecondary">
                        (Name not visible to vendors)
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                      {/* Expected Start Date */}
                      <Typography sx={{ mr: 2 }}>Expected Start Date: {expectedStartDate}</Typography>

                      {/* Plants Menu */}
                      {editMode && plants.length > 0 && (
                        <>
                          <Button variant="contained" onClick={(e) => handlePlantMenuClick(e, jobId)}>
                            <Add fontSize="small" />
                            <Typography variant="button">Plants</Typography>
                          </Button>
                          <JobPlantsMenu />
                        </>
                      )}

                      {/* Job Hamburger Menu */}
                      {editMode && (
                        <>
                          <IconButton
                            sx={{
                              px: 0,
                              py: 1,
                              ml: 1,
                              borderRadius: theme.borderRadius.sm,
                              border: '1px solid #ccc',
                              backgroundColor: theme.palette.lightGrey3.main,
                            }}
                            onClick={(e) => handleJobMenuClick(e, jobId)}
                          >
                            <EllipsisVertical />
                          </IconButton>
                          <JobMenu />
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <CustomDivider />
                  {plants.length ? (
                    <Stack>
                      <RfpPlantTable
                        key={index}
                        plants={plants as PlantListEntry[]}
                        onUpdate={handlePlantsUpdate}
                        onSelectionChange={handleSelectionChange}
                        preSelectedPlants={selectedPlantIds}
                        allowReorder={allowPlantTableReorder}
                        allowQuantityChange={allowPlantTableQuantityChange}
                        showMenu={showPlantTableMenu}
                        showDeleted={!!sentRfp}
                      />
                      {/* Add Plants missing from job */}
                      {editMode && <AddMissingPlantsFromJobButton jobId={jobId} />}
                    </Stack>
                  ) : (
                    <RfpJobDefaultContent jobId={jobId} />
                  )}
                </Stack>
              )
            })}
          </Stack>
        ) : (
          <NewRFPDefaultContent />
        )}
        {/* Vendor Comments & Internal Notes */}
        <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2, mt: 3 }}>
          <RFPComments
            title="General Comments (for Vendors)"
            name="vendorComments"
            value={rfpData?.comments_for_vendor || ''}
            placeholder="Type comments here ..."
            onChange={(value) => onUpdateRfpData({ comments_for_vendor: value })}
            editMode={editMode}
          />
          {/* Internal Notes is visible only in edit mode (used in build rfp step) */}
          {editMode && (
            <RFPComments
              title="General Notes"
              name="generalNotes"
              value={rfpData?.notes || ''}
              placeholder="Type notes here ..."
              isInternal
              onChange={(value) => onUpdateRfpData({ notes: value })}
              editMode={editMode}
            />
          )}
        </Stack>
      </Stack>

      {/* RFP modals */}
      <EditRfpDetailsModal
        rfpData={rfpData}
        open={editRfpDetailsModalOpen}
        onClose={() => {
          setEditRfpDetailsModalOpen(false)
        }}
        onSubmit={onUpdateRfpData}
      />
      <AddPlantsFromJobsModal
        open={addPlantsFromJobModalOpen}
        onClose={() => {
          setAddPlantsFromJobModalOpen(false)
          setActiveJobId('')
        }}
        jobId={activeJobId}
        onAddPlants={handleAddPlants}
      />
      <AddPlantsFromListModal
        open={addPlantsFromListModalOpen}
        onClose={() => setAddPlantsFromListModalOpen(false)}
        onAddPlants={handleAddPlants}
      />
      <CreatePlantListForRFPModal
        open={createPlantListModalOpen}
        onClose={() => setCreatePlantListModalOpen(false)}
        onAddPlants={handleAddPlants}
      />
      <AddPlantsModal
        open={addPlantsModalOpen}
        onClose={() => setAddPlantsModalOpen(false)}
        onAddPlants={handleAddPlants}
      />
      <AddJobsToRFPModal
        open={!!addJobsToRFPModalOpen}
        onClose={() => setAddJobsToRFPModalOpen('')}
        newJob={addJobsToRFPModalOpen === 'newJob'}
        onAddJobs={handleAddJobsToRFP}
      />
      {confirmationModalContent && <ConfirmationModal {...confirmationModalContent} />}
    </Box>
  )
}
