import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useCallback, useState } from 'react'

import { Typography, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'

import { ScrollText } from 'lucide-react'

import { getPlantListById, updatePlantList } from '@/api/plant-list.ts'
import FileViewerAlternative from '@/components/plant-list-details/file-viewer.tsx'
import PlantListVerificationModal from '@/components/plant-list-details/plant-list-verification-modal.tsx'
import { PlantListTableContainer } from '@/components/plant-list-details/plant-list-table-container.tsx'
import { PlantActionsHeader } from '@/components/plant-list-details/plant-list-header-section.tsx'
import { PlantActionsFooter } from '@/components/plant-list-details/plant-list-footer-section.tsx'
import ReviewAccuracyModal from '@/components/plant-list-details/plant-list-review-accuracy-modal.tsx'
import ArchivePlantList from '@/components/plant-list-details/plant-list-archive.tsx'
import FlexPageLayout from '@/components/ui/layouts/page-layout-flex'
import { IMAGE_FILE_TYPES } from '@/constants.ts'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { PlantList } from '@/types.ts'

type PlantListDetailContentProps = {
  plantList: PlantList
  refetchPlantList: () => void
}

const PlantListDetailContent = ({ plantList, refetchPlantList }: PlantListDetailContentProps) => {
  const queryClient = useQueryClient()
  const { addToastNotification } = useToastNotifications()
  const [fileViewerFileId, setFileViewerFileId] = useState<string | null>(null)
  const [selectedFiles, setSelectedFiles] = useState(() => {
    const temp = plantList.files
      .filter((file) => IMAGE_FILE_TYPES.includes(file.file_type) && !file.deleted_at)
      .map((file) => file.id)
    if (plantList.entries.some((entry) => entry.file_id === null && entry.deleted_at === null)) {
      temp.push('unassociated')
    }
    return temp
  })

  const updatePlantListMutation = useMutation({
    mutationFn: updatePlantList,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['plant-list', plantList.id],
      })
    },
  })

  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())
  const [searchFilteredPlants, setSearchFilteredPlants] = useState<Set<string>>(new Set(['all']))
  const [verifyPlantsForTarget, setVerifyPlantsForTarget] = useState('')

  // Memoize the setSelectedPlants callback to prevent unnecessary re-renders
  const handleSetSelectedPlants = useCallback((newSelectedPlants: Set<string>) => {
    setSelectedPlants(newSelectedPlants)
  }, [])

  const proceedWithSendingSelectedToTarget = () => {
    addToastNotification({
      severity: 'info',
      title: 'Action not Implemented',
      message: `Sent ${selectedPlants.size} plants to ${verifyPlantsForTarget}`,
    })
  }

  const confirmSendSelectedToTarget = async () => {
    if (!plantList.verified) {
      await updatePlantListMutation.mutateAsync({
        ...plantList,
        verified: true,
      })
    }
    proceedWithSendingSelectedToTarget()
  }

  const handleSendSelectedToTarget = (type: string) => {
    if (!plantList.verified) {
      setVerifyPlantsForTarget(type)
    } else {
      proceedWithSendingSelectedToTarget()
    }
  }

  const availableFiles = plantList.files.filter((file) => !file.deleted_at)

  const PanelResizeHandleVertical = () => {
    return (
      <PanelResizeHandle>
        <Box
          sx={{
            width: '100px',
            height: '5px',
            backgroundColor: 'gray',
            cursor: 'col-resize',
            marginY: 1,
            borderRadius: '1em',
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
      </PanelResizeHandle>
    )
  }

  const PanelResizeHandleHorizontal = () => {
    return (
      <PanelResizeHandle>
        <Box
          sx={{
            width: '5px',
            height: '100px',
            backgroundColor: 'gray',
            cursor: 'col-resize',
            marginX: 0.5,
            position: 'relative',
            borderRadius: '1em',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </PanelResizeHandle>
    )
  }

  return (
    <>
      <PlantListVerificationModal
        open={!!verifyPlantsForTarget}
        onClose={() => setVerifyPlantsForTarget('')}
        onConfirm={confirmSendSelectedToTarget}
      />
      <PanelGroup autoSaveId="plant-list-and-file-viewer" direction="horizontal">
        <Panel maxSize={75}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              display: 'flex',
            }}
          >
            <ReviewAccuracyModal />
            <PanelGroup autoSaveId="plant-list" direction="vertical">
              <Panel maxSize={75}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    pt: 2,
                  }}
                >
                  <PlantActionsHeader
                    plantList={plantList}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    selectedPlants={selectedPlants}
                    setSearchFilteredPlants={setSearchFilteredPlants}
                    refetchPlantList={refetchPlantList}
                  />
                  <PlantListTableContainer
                    plantList={plantList}
                    selectedFiles={selectedFiles}
                    searchFilteredPlants={searchFilteredPlants}
                    selectedPlants={selectedPlants}
                    setSelectedPlants={handleSetSelectedPlants}
                    setTargetFileId={setFileViewerFileId}
                  />
                </Box>
              </Panel>
              <PanelResizeHandleVertical />
              <Panel maxSize={50}>
                <Box
                  sx={{
                    overflow: 'auto',
                    height: '100%',
                    scrollbarWidth: 'none',
                  }}
                >
                  <PlantActionsFooter
                    selectedPlants={selectedPlants}
                    handleSendSelectedToTarget={handleSendSelectedToTarget}
                  />
                </Box>
              </Panel>
            </PanelGroup>
          </Box>
        </Panel>
        <PanelResizeHandleHorizontal />
        <Panel>
          <Box>
            <FileViewerAlternative
              files={availableFiles}
              onFileDelete={refetchPlantList}
              fileId={fileViewerFileId}
              onFileIdChange={setFileViewerFileId}
            />
          </Box>
        </Panel>
      </PanelGroup>
    </>
  )
}

export const Route = createFileRoute('/_authenticated/plants/$plantListId')({
  component: PlantListDetail,
})

function PlantListDetail() {
  const { plantListId } = Route.useParams()
  const {
    error,
    data: plantList,
    isLoading,
    isFetching,
    refetch: refetchPlantList,
  } = useQuery({
    queryKey: ['plant-list', plantListId] as const,
    queryFn: ({ queryKey }) => {
      const [_, plantListId] = queryKey
      return getPlantListById(plantListId as string)
    },
    refetchOnWindowFocus: false,
    retry: false,
  })

  // show a loading state when the plant list is not yet loaded
  if (!plantList && (isFetching || isLoading)) {
    return (
      <FlexPageLayout
        title="Loading..."
        icon={<CircularProgress size={18} />}
        backRoute={{ to: '/plants', label: 'All Plant Lists' }}
      >
        {''}
      </FlexPageLayout>
    )
  }

  // show an error state when no plant list data is returned
  if (!plantList || error) {
    return (
      <FlexPageLayout title="Error" backRoute={{ to: '/plants', label: 'All Plant Lists' }}>
        <Typography variant="strongLg">Plant List not found</Typography>
        <Typography variant="body1">{error?.message}</Typography>
      </FlexPageLayout>
    )
  }

  // show the plant list detail content when the plant list is loaded
  return (
    <FlexPageLayout
      title={plantList.name}
      titleBarElement={<ArchivePlantList plantList={plantList} />}
      icon={<ScrollText size={36} />}
      backRoute={{ to: '/plants', label: 'All Plant Lists' }}
    >
      <PlantListDetailContent plantList={plantList} refetchPlantList={refetchPlantList} />
    </FlexPageLayout>
  )
}
