import Typography from '@mui/material/Typography'
import { NotVisibleToVendors } from '@/components/ui/base/labels/not-visible-to-vendors.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import TextField from '@mui/material/TextField'
import { Stack } from '@mui/material'
import { ChangeEvent, useState } from 'react'

interface RFPCommentProps {
  title: string
  name: string
  value: string
  placeholder?: string
  isInternal?: boolean
  editMode?: boolean
  onChange: (value: string) => void
}

const RFPComments = ({
  title,
  name,
  value,
  placeholder = 'Type here ...',
  isInternal = false,
  onChange,
  editMode = false,
}: RFPCommentProps) => {
  const [comment, setComment] = useState(value || '')

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = e.target.value
    setComment(newValue)
    onChange(newValue)
  }

  return (
    <Stack sx={{ width: '100%', gap: 2 }}>
      <Stack
        direction="row"
        sx={{
          justifyContent: isInternal ? 'space-between' : 'flex-start',
          alignItems: 'center',
        }}
      >
        <>
          <Typography variant="body1" sx={{ fontWeight: 700, pl: 2 }}>
            {title}
          </Typography>
          {isInternal && <NotVisibleToVendors size="small" />}
        </>
      </Stack>
      <CustomDivider />
      {editMode ? (
        <TextField
          variant="outlined"
          name={name}
          placeholder={placeholder}
          fullWidth
          multiline
          rows={5}
          value={comment}
          onChange={handleInputChange}
        />
      ) : (
        <Typography px={3}>{comment || 'No comments'}</Typography>
      )}
    </Stack>
  )
}

export default RFPComments
