import api from './api'
import { NewRfpFormData, PlantListEntry, RequestForProposal } from '@/types.ts'
import { RFPContactIndexSchema, type RFPIndexTypes } from './types/rfps'

export async function createRfp(data: NewRfpFormData) {
  const response = await api.post('/v1/core/rfp/', data)
  return response.data as RequestForProposal
}

export async function getRfps(organizationId: string, type: RFPIndexTypes = 'sent') {
  const params = new URLSearchParams({ organization_id: organizationId })

  if (type === 'received') {
    params.set('vendor', 'false')
    params.set('customer', 'true')
    params.set('draft', 'false')
  }

  if (type === 'sent') {
    params.set('customer', 'false')
  }

  const { data, status, statusText } = await api.get(`/v1/core/rfp/contact_view/?${params.toString()}`)
  if (status !== 200) {
    console.error('Error in /api/rfps.ts:getRfps: ', statusText)
    return []
  }

  const parsedData = RFPContactIndexSchema.parse(data)

  return parsedData
}

export async function getRfp(rfpId: string) {
  const response = await api.get(`/v1/core/rfp/${rfpId}`)

  // TODO: type this
  return response.data as any
}

export async function updateRfp(rfpId: string, data: any) {
  const response = await api.put(`/v1/core/rfp/${rfpId}`, data)

  // TODO: type this
  return response.data as any
}

export type CreateSentRfpRequest = {
  rfpId: string
  organizationId: string
  directed_organizations: {
    directed_organization_contact: string
    directed_organization_user_contacts: string[]
    notification_subject?: string
    notification_body?: string
    send?: boolean
  }[]
  plants: PlantListEntry[]
}
export async function createSentRfp({ rfpId, organizationId, directed_organizations, plants }: CreateSentRfpRequest) {
  const response = await api.post('/v1/core/sent_rfp/', {
    organization: organizationId,
    rfp_id: rfpId,
    directed_organizations,
    plants,
  })

  return response.data as any
}

export async function getSentRfpsByRfpId(rfpId: string, organizationId: string) {
  const response = await api.get(`/v1/core/sent_rfp/?rfp_id=${rfpId}&organization=${organizationId}`)

  return response.data as any
}

export type UpdateSentRfpRequest = {
  sentRfpId: string
  data: any
}
export async function updateSentRfp({ sentRfpId, data }: UpdateSentRfpRequest) {
  const response = await api.patch(`/v1/core/sent_rfp/${sentRfpId}`, data)

  return response.data as any
}
