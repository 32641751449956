import { GCSFile, PlantList, PlantListEntry, PlantWithQuantity } from '@/types.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import React, { ChangeEvent, useRef, useState } from 'react'
import {
  ACCEPTED_FILE_EXTENSIONS,
  ACCEPTED_FILE_TYPES,
  DEFAULT_PLANT_LIST_ENTRY,
  IMAGE_FILE_TYPES,
  MAX_IMAGE_DIMENSION,
} from '@/constants.ts'
import { addFileToPlantList, updatePlantList } from '@/api/plant-list.ts'
import { createFile } from '@/api/files.ts'
import { uploadFile } from '@/api/gcs.ts'
import { processFileWithAI } from '@/api/ai-processed-plant-list-file.ts'
import { checkImageDimensions, generateObjectId, isImageFile } from '@/lib/utils.ts'
import { Box, MenuItem, Select, Stack, TextField } from '@mui/material'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import { DarkPrimaryButton, TextPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import CircularProgress from '@mui/material/CircularProgress'
import { Search } from 'lucide-react'
import { CustomMenu } from '@/components/ui/base/dropdowns/generic-dropdown.tsx'
import AddPlantsModal from '@/components/ui/modals/add-plants-modal.tsx'

type PlantActionsHeaderProps = {
  plantList: PlantList
  selectedFiles: string[]
  selectedPlants: Set<string>
  setSelectedFiles: (files: string[]) => void
  setSearchFilteredPlants: (plants: Set<string>) => void
  refetchPlantList: () => void
}

export function PlantActionsHeader({
  plantList,
  selectedFiles,
  setSelectedFiles,
  selectedPlants,
  setSearchFilteredPlants,
  refetchPlantList,
}: PlantActionsHeaderProps) {
  const plantListId = plantList.id
  const queryClient = useQueryClient()
  const { selectedOrganization } = useOrganization()
  const [_, setError] = useState('')
  const [addPlantsModalOpen, setAddPlantsModalOpen] = useState(false)
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const plantListFiles =
    plantList.files.filter((file) => IMAGE_FILE_TYPES.includes(file.file_type) && file.deleted_at === null) || []

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const updatePlantListMutation = useMutation({
    mutationFn: updatePlantList,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['plant-list', plantList.id],
      })
    },
  })

  const handleUpdatePlantListEntries = async (entries: PlantListEntry[]) => {
    if (!plantList || !entries) {
      return
    }
    await updatePlantListMutation.mutateAsync({
      ...plantList,
      entries,
    })
  }

  const handleDeletePlantListEntries = async () => {
    if (!plantList || !selectedPlants) {
      return
    }
    const newEntries = plantList.entries.map((entry) => {
      if (selectedPlants.has(entry.id)) {
        return { ...entry, deleted_at: new Date().toISOString() }
      } else {
        return entry
      }
    })
    await handleUpdatePlantListEntries(newEntries)
  }

  const handlePlantListFileSelection = (event: any) => {
    setSelectedFiles(event.target.value)
  }

  const generateSelectedFilesSummaryText = (selected: string[]) => {
    if (selected.length === 0) {
      return 'Select files to view plants'
    }
    const hasUnassociatedEntries = Array.from(selected).includes('unassociated')
    const selectedFileCount = hasUnassociatedEntries ? selectedFiles.length - 1 : selectedFiles.length
    const totalFileCount = plantListFiles.length

    let summaryText = ''
    if (totalFileCount && selectedFileCount === totalFileCount) {
      summaryText = `Viewing Plants from: All Files (${totalFileCount})`
    } else if (selectedFileCount > 1) {
      summaryText = `Viewing Plants from: ${selectedFileCount} of ${totalFileCount} files`
    } else {
      const matchedFile = plantListFiles.find((file) => Array.from(selected).includes(file.id))
      if (matchedFile) {
        summaryText = `aViewing Plants from: ${matchedFile?.user_file_name}`
      }
    }

    if (hasUnassociatedEntries) {
      summaryText = summaryText ? summaryText + ' & Unassociated' : 'Unassociated'
    }
    return summaryText
  }

  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const newFile: Partial<GCSFile> = {
        user_file_name: file.name,
        file_type: file.type,
        encoding: file.type,
        domain: 'plant_list',
      }
      const createdFile = await createFile(newFile)
      await uploadFile({
        file,
        contentType: file.type,
        putUrl: createdFile.put_url,
      })
      await addFileToPlantList(plantListId, createdFile)
      await processFileWithAI({
        organizationId: selectedOrganization?.id as string,
        fileId: createdFile.id,
        plantListId: plantListId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['plantList', plantListId],
      })
      await refetchPlantList()
    },
  })

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setError('Only files of the following format are allowed: .jpeg, .jpg, .png, .gif, .webp, .csv, .xlsx, and .pdf.')
      return
    }

    if (isImageFile(file)) {
      const isValidDimension = await checkImageDimensions(file)
      if (!isValidDimension) {
        setError(`Image files must not exceed ${MAX_IMAGE_DIMENSION} pixels in width or height.`)
        return
      }
    }

    // ensure error is cleared
    setError('')
    uploadMutation.mutate(file)
  }

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  const handleSearchPlants = (e: any) => {
    const value = e.target.value.trim()
    const targetEntries = plantList.entries.filter((entry) => {
      const entry_is_part_of_selected_files =
        entry.deleted_at === null && selectedFiles.includes(entry.file_id as string)
      const entry_is_not_part_of_any_files = entry.deleted_at === null && entry.file_id === null
      const show_entry_not_part_of_any_files = selectedFiles.includes('unassociated')
      if (entry_is_part_of_selected_files || (entry_is_not_part_of_any_files && show_entry_not_part_of_any_files)) {
        return entry
      }
    })

    if (value) {
      const filteredEntries = targetEntries
        .filter((entry) => {
          if (/^[+-]?\d+(\.\d+)?$/.test(value)) {
            // implement search against all numeric fields
            return null
          } else {
            const lowerCaseValue = value.toLowerCase()

            // search against string fields
            const stringFields = [
              entry.scientific_name,
              entry.common_name,
              entry.shipping_container,
              entry.plant_container,
              entry.root_packaging,
              entry.notes,
              entry.trunk_form?.plurality,
            ].some((field) => field?.toLowerCase().includes(lowerCaseValue))

            // search against string array fields
            const stringArrayFields = [entry.shape, entry.palm_trunk, entry.plant_stage, entry.characteristics].some(
              (arr) => arr?.some((val) => val.toLowerCase().includes(lowerCaseValue))
            )

            return stringFields || stringArrayFields
          }
        })
        .map((entry) => entry.id)
      setSearchFilteredPlants(new Set(filteredEntries))
    } else {
      setSearchFilteredPlants(new Set(targetEntries.map((entry) => entry.id)))
    }
  }

  const openAddPlantsModal = (file_id: string | null) => {
    setAddPlantsModalOpen(true)
    setSelectedFileId(file_id)
  }

  const handleAddPlants = async (plants: PlantWithQuantity[]) => {
    if (!plantList) {
      return
    }

    let updatedEntries: PlantListEntry[] = [...plantList.entries]
    let lastEntryAdded: PlantListEntry | null = null

    for (const plant of plants) {
      const newId = generateObjectId()
      const newPlant: PlantListEntry = {
        ...DEFAULT_PLANT_LIST_ENTRY,
        id: newId,
        is_new: true,
        file_id: selectedFileId,
        quantity_count: { min: plant.quantity, max: plant.quantity },
        scientific_name: plant.plant.scientific_name,
        common_name: plant.plant.common_names[0],
      }

      let target_entry: PlantListEntry | null = null
      let next_entry: PlantListEntry | null = null

      // Only search for target/next entries for the first plant
      if (lastEntryAdded === null) {
        if (selectedFileId) {
          // Find the last entry in the current file
          for (const entry of plantList.entries) {
            if (!target_entry && entry.file_id === selectedFileId) {
              target_entry = entry
            } else if (target_entry && target_entry.parent_of_order === entry.id) {
              if (entry.file_id === selectedFileId) {
                target_entry = entry
              } else {
                next_entry = entry
              }
            }
          }
        }

        if (!target_entry) {
          target_entry = plantList.entries.find((entry: PlantListEntry) => entry?.parent_of_order === null) || null
        }
      }

      // For subsequent plants, link to the previous plant
      if (lastEntryAdded !== null) {
        newPlant.parent_of_order = lastEntryAdded.parent_of_order
        lastEntryAdded.parent_of_order = newPlant.id
      } else if (next_entry && target_entry?.parent_of_order === (next_entry as PlantListEntry)?.id) {
        // First plant - handle linked items
        updatedEntries = updatedEntries.map((entry) => {
          if (entry.id === target_entry?.id) {
            return { ...entry, parent_of_order: newId }
          }
          return entry
        })
        newPlant.parent_of_order = next_entry.id
      } else if ((!next_entry && target_entry?.parent_of_order === null) || !selectedFileId) {
        // First plant - handle non-linked items
        if (target_entry) {
          updatedEntries = updatedEntries.map((entry) => {
            if (entry.id === target_entry?.id) {
              return { ...entry, parent_of_order: newId }
            }
            return entry
          })
        }
      } else {
        console.error('could not find a suitable entry to link to')
        continue // Skip this plant and try the next one
      }

      updatedEntries.push(newPlant)
      lastEntryAdded = newPlant
    }

    await handleUpdatePlantListEntries(updatedEntries)

    // After the update is complete, scroll to the last added plant
    if (lastEntryAdded) {
      setTimeout(() => {
        const element = document.getElementById(lastEntryAdded.id)
        console.log('element:', element)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          element.style.backgroundColor = '#fff7e6'
          setTimeout(() => {
            element.style.backgroundColor = ''
          }, 3000)
        }
      }, 100)
    }
  }

  const addPlantsMenuItems = (): {
    category?: string
    label: string
    onClick: () => void
    divider?: boolean
  }[] => {
    const items: {
      category?: string
      label: string
      onClick: () => void
      divider?: boolean
    }[] = []

    if (Array.from(plantListFiles).length) {
      plantListFiles.forEach((file) => {
        items.push({
          category: 'Add plants to:',
          label: file.user_file_name,
          onClick: () => openAddPlantsModal(file.id),
        })
      })
    }
    if (Array.from(items).length) {
      items[items.length - 1].divider = true
    }
    items.push({
      label: 'Not associated with file',
      onClick: () => openAddPlantsModal(null),
    })
    return items
  }

  return (
    <>
      <Stack direction="column" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Select
            multiple={true}
            size="small"
            value={selectedFiles}
            sx={{ width: '50%' }}
            onChange={(e) => handlePlantListFileSelection(e)}
            renderValue={(selected) => generateSelectedFilesSummaryText(selected as string[])}
            displayEmpty
          >
            {plantListFiles.map((file, index) => (
              <MenuItem
                sx={{ margin: '0.5em', padding: 2, borderRadius: 2 }}
                key={`plant-list-file-selector-item-${index}`}
                value={file.id}
              >
                {file.user_file_name || 'n/a'}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              sx={{ margin: '0.5em', padding: 2, borderRadius: 2 }}
              key={`plant-list-file-selector-item-unassociated`}
              value={'unassociated'}
            >
              Unassociated
            </MenuItem>
          </Select>
          <Tooltip title={`Supported file types are: ${ACCEPTED_FILE_EXTENSIONS.join(', ')}`} arrow placement="top">
            <TextPrimaryButton
              variant="outlined"
              color="primary"
              onClick={triggerFileInput}
              disabled={uploadMutation.isPending}
              startIcon={uploadMutation.isPending ? <CircularProgress size={16} /> : null}
            >
              Upload File
            </TextPrimaryButton>
          </Tooltip>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            accept={ACCEPTED_FILE_TYPES.join(',')}
          />
        </Stack>
        <Divider sx={{ marginY: '1em' }} />
        <Stack direction="row" justifyContent="space-between" py={1}>
          <Box
            component={TextField}
            size="small"
            placeholder="Search Plants ..."
            variant="outlined"
            onKeyUp={handleSearchPlants}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid',
                },
              },
            }}
            InputProps={{
              startAdornment: <Search style={{ color: 'gray', marginRight: '8px' }} />,
            }}
          />
          <Stack direction="row" spacing={2}>
            {selectedPlants.size ? (
              <TextPrimaryButton variant="outlined" onClick={handleDeletePlantListEntries}>
                Delete Selected
              </TextPrimaryButton>
            ) : null}
            <DarkPrimaryButton onClick={handleClick}>Add Plants</DarkPrimaryButton>
            <CustomMenu
              width="max-content"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              menuItems={addPlantsMenuItems()}
            />
          </Stack>
        </Stack>
      </Stack>
      <AddPlantsModal
        open={addPlantsModalOpen}
        onClose={() => setAddPlantsModalOpen(false)}
        onAddPlants={handleAddPlants}
      />
    </>
  )
}
