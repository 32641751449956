import { RFPDetailsProps } from '@/components/rfps/types.ts'
import { formatStringToFullMonthDayYear } from '@/lib/utils.ts'
import { DetailsSection } from '@/components/ui/details-section.tsx'
import { DetailsSectionField, DetailsSectionProps } from '@/types.ts'
import { DetailsSectionText } from '@/components/ui/details-section-text.tsx'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { ClearTextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import EditOutlined from '@mui/icons-material/EditOutlined'

export const RFPDetails = ({ rfpData, editMode, openEditRfpModal }: RFPDetailsProps) => {
  const quoteData = (): DetailsSectionField => {
    const defaultText = 'TBD'
    const formattedDate = formatStringToFullMonthDayYear(rfpData.quote_needed_by) || defaultText
    const content = <DetailsSectionText text={formattedDate} />
    return { label: 'Quote Needed By', node: content }
  }

  const detailsDataTitle = (): DetailsSectionField => {
    const content = (
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          RFP Details
        </Typography>
        {editMode && (
          <Stack direction="row">
            <ClearTextIconButton
              text="Edit"
              onClick={openEditRfpModal}
              startIcon={<EditOutlined />}
              sx={{ p: 0, color: '#ccc' }}
            />
          </Stack>
        )}
      </Stack>
    )
    return { label: 'RFP Details', node: content }
  }

  const phaseData = (): DetailsSectionField => {
    // waiting on backed API to implement phase information on RFP. For now, use TBD
    const defaultText = 'TBD'
    const content = <DetailsSectionText text={rfpData.phase || defaultText} />
    return { label: 'RFP Phase', node: content }
  }

  const detailsData = {
    title: detailsDataTitle(),
    fields: [quoteData(), phaseData()],
    defaultNode: null,
  } as DetailsSectionProps

  return DetailsSection(detailsData)
}
