import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { useState } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(option: string, selectedOptions: readonly string[], theme: Theme) {
  return {
    fontWeight: selectedOptions.includes(option)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  }
}

// modify when use case arises for other types of values
type MultipleSelectChipDropdownOption = {
  value: string
  name: string
}
interface MultipleSelectChipDropdownProp {
  options: MultipleSelectChipDropdownOption[]
  selectedOptions: string[]
  onChange: (selection: string[]) => void
  label: string
}

export default function MultipleSelectChipDropdown({
  options,
  onChange,
  selectedOptions,
  label,
}: MultipleSelectChipDropdownProp) {
  const theme = useTheme()
  const [selectedDropdownOptions, setSelectedDropdownOptions] = useState<string[]>(selectedOptions || [])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[]
    setSelectedDropdownOptions(value)
    onChange(value)
  }

  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id={`${label}-multiple-chip-input-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-multiple-chip-select-id`}
          id={`${label}-multiple-chip-label-id`}
          multiple
          fullWidth
          value={selectedDropdownOptions}
          onChange={handleChange}
          input={<OutlinedInput id={`${label}-multiple-chip-label-input`} label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {options
                .filter((option) => selected.includes(option.value))
                .map((option: any) => (
                  <Chip key={option.value} label={option.name} />
                ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem
              key={`multiple-chip-label-menu-item-${option.name}`}
              value={option.value}
              style={getStyles(option.value, selectedDropdownOptions, theme)}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
