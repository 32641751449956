import { formatInTimeZone } from 'date-fns-tz'

export interface TimezoneInfo {
  value: string // Full timezone identifier (e.g., "America/New_York")
  label: string // Display name with offset (e.g., "New York (UTC-05:00)")
  offset: string // UTC offset (e.g., "UTC-05:00")
  region: string // Geographic region (e.g., "America")
  city: string // Primary city name (e.g., "New York")
  searchTerms: string // Additional searchable terms
}

// Major cities representing different timezone regions with common names and alternative cities
const MAJOR_TIMEZONES = [
  // Americas (UTC-10 to UTC-3)
  {
    value: 'Pacific/Honolulu',
    region: 'Americas',
    searchTerms: 'Hawaii HST Hawaiian Honolulu',
  },
  {
    value: 'America/Anchorage',
    region: 'Americas',
    searchTerms: 'Alaska AKST Anchorage Juneau',
  },
  {
    value: 'America/Los_Angeles',
    region: 'Americas',
    searchTerms: 'Pacific PST PDT Los Angeles LA San Francisco Seattle Vancouver',
  },
  {
    value: 'America/Phoenix',
    region: 'Americas',
    searchTerms: 'Mountain MST Arizona Phoenix Tucson',
  },
  {
    value: 'America/Denver',
    region: 'Americas',
    searchTerms: 'Mountain MST MDT Denver Boulder Salt Lake City',
  },
  {
    value: 'America/Chicago',
    region: 'Americas',
    searchTerms: 'Central CST CDT Chicago Houston Dallas Kansas City Mexico City',
  },
  {
    value: 'America/New_York',
    region: 'Americas',
    searchTerms: 'Eastern EST EDT New York NYC Boston Philadelphia Miami Atlanta Toronto Indianapolis',
  },
  {
    value: 'America/Halifax',
    region: 'Americas',
    searchTerms: 'Atlantic AST ADT Halifax Nova Scotia',
  },
  {
    value: 'America/Sao_Paulo',
    region: 'Americas',
    searchTerms: 'Brazil BRT Sao Paulo Rio de Janeiro Brasilia',
  },

  // Europe & Africa (UTC-1 to UTC+3)
  {
    value: 'Atlantic/Azores',
    region: 'Europe',
    searchTerms: 'Azores AZOT Ponta Delgada',
  },
  {
    value: 'Europe/London',
    region: 'Europe',
    searchTerms: 'GMT BST London UK Britain England Dublin Edinburgh',
  },
  {
    value: 'Europe/Paris',
    region: 'Europe',
    searchTerms: 'Central European CET CEST Paris Berlin Rome Madrid Amsterdam Brussels',
  },
  {
    value: 'Europe/Helsinki',
    region: 'Europe',
    searchTerms: 'Eastern European EET EEST Helsinki Stockholm Copenhagen Oslo',
  },
  {
    value: 'Europe/Moscow',
    region: 'Europe',
    searchTerms: 'Moscow MSK Russia St Petersburg',
  },

  // Asia & Oceania (UTC+4 to UTC+12)
  {
    value: 'Asia/Dubai',
    region: 'Asia',
    searchTerms: 'Gulf GST Dubai Abu Dhabi UAE Muscat',
  },
  {
    value: 'Asia/Kolkata',
    region: 'Asia',
    searchTerms:
      'India IST Indian Mumbai Delhi Bangalore Hyderabad Chennai Pune Ahmedabad Kolkata New Delhi Bengal Karnataka Maharashtra Tamil Nadu Gujarat',
  },
  {
    value: 'Asia/Karachi',
    region: 'Asia',
    searchTerms: 'Pakistan PKT Karachi Islamabad Lahore',
  },
  {
    value: 'Asia/Dhaka',
    region: 'Asia',
    searchTerms: 'Bangladesh BST Dhaka Chittagong',
  },
  {
    value: 'Asia/Bangkok',
    region: 'Asia',
    searchTerms: 'Indochina ICT Bangkok Jakarta Hanoi',
  },
  {
    value: 'Asia/Singapore',
    region: 'Asia',
    searchTerms: 'Singapore SGT Malaysia Kuala Lumpur',
  },
  {
    value: 'Asia/Tokyo',
    region: 'Asia',
    searchTerms: 'Japan JST Tokyo Osaka Seoul',
  },
  {
    value: 'Australia/Sydney',
    region: 'Oceania',
    searchTerms: 'Australian Eastern AEST AEDT Sydney Melbourne Brisbane',
  },
  {
    value: 'Pacific/Noumea',
    region: 'Oceania',
    searchTerms: 'New Caledonia NCT Noumea',
  },
  {
    value: 'Pacific/Auckland',
    region: 'Oceania',
    searchTerms: 'New Zealand NZST NZDT Auckland Wellington',
  },
]

/**
 * Get formatted current time for a timezone
 */
export function getCurrentTime(timezone: string): string {
  return formatInTimeZone(new Date(), timezone, 'h:mm a')
}

/**
 * Get UTC offset for a timezone
 */
export function getUTCOffset(timezone: string): string {
  const offset = formatInTimeZone(new Date(), timezone, 'xxx')
  return `UTC${offset}`
}

/**
 * Format city name from timezone identifier
 */
function formatCityName(timezone: string): string {
  const city = timezone.split('/').pop() || ''
  return city.replace(/_/g, ' ')
}

/**
 * Format timezone for display
 */
function formatTimezone(timezone: string, offset: string): string {
  /// Common names for all timezones
  const commonNames: { [key: string]: string } = {
    // Americas
    'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time',
    'America/Anchorage': 'Alaska Time',
    'America/Los_Angeles': 'Pacific Time',
    'America/Phoenix': 'Mountain Time (no DST)',
    'America/Denver': 'Mountain Time',
    'America/Chicago': 'Central Time',
    'America/New_York': 'Eastern Time',
    'America/Halifax': 'Atlantic Time',
    'America/Sao_Paulo': 'Brasilia Time',

    // Europe & Africa
    'Atlantic/Azores': 'Azores Time',
    'Europe/London': 'British Time',
    'Europe/Paris': 'Central European Time',
    'Europe/Helsinki': 'Eastern European Time',
    'Europe/Moscow': 'Moscow Time',

    // Asia & Oceania
    'Asia/Dubai': 'Gulf Standard Time',
    'Asia/Kolkata': 'India Standard Time',
    'Asia/Karachi': 'Pakistan Standard Time',
    'Asia/Dhaka': 'Bangladesh Standard Time',
    'Asia/Bangkok': 'Indochina Time',
    'Asia/Singapore': 'Singapore Time',
    'Asia/Tokyo': 'Japan Standard Time',
    'Australia/Sydney': 'Australian Eastern Time',
    'Pacific/Noumea': 'New Caledonia Time',
    'Pacific/Auckland': 'New Zealand Time',
  }

  const commonName = commonNames[timezone]

  if (!commonName) {
    return ''
  }

  return `(${offset}) ${commonName}`
}

/**
 * Get list of major timezones with current times and offsets
 */
export function getMajorTimezones(): TimezoneInfo[] {
  return MAJOR_TIMEZONES.map(({ value, region, searchTerms }) => {
    const offset = getUTCOffset(value)
    const city = formatCityName(value)
    return {
      value,
      label: formatTimezone(value, offset),
      offset,
      region,
      city,
      searchTerms,
    }
  }).sort((a, b) => {
    // Sort by UTC offset
    const offsetA = parseInt(a.offset.replace('UTC', '').replace(':', ''))
    const offsetB = parseInt(b.offset.replace('UTC', '').replace(':', ''))
    return offsetA - offsetB
  })
}

/**
 * Get timezone label for a timezone identifier
 */
export function getTimezoneLabel(timezone: string): string {
  const offset = getUTCOffset(timezone)
  return formatTimezone(timezone, offset)
}
